import axios from "axios";

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: "json",
  ContentType: "application/json",
  timeout: (1000 * 60 * 3),
  headers: {
    Authorization: `bearer noToken`
  }
})

export default API