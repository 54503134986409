import React, { Component } from 'react'
import Content from '../template/jsx/Content'

import SetHeader from './SetHeader'
import SetCover from './SetCover'
import SetApresentation from './setApresentation'

export default class setReport extends Component {

    render() {
        return (
            <Content>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <a className="nav-link active" id="home-tab" data-toggle="tab" href="#setCover" role="tab" aria-controls="setCover" aria-selected="true">Capa</a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link" id="home-tab" data-toggle="tab" href="#setHeader" role="tab" aria-controls="setHeader" aria-selected="true">Cabeçalho</a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link disabled" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">
                            Apresentação
                            <span className="badge badge-pill badge-danger float-right small">Breve</span>
                        </a>
                    </li>
                </ul>

                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="setCover" role="tabpanel" aria-labelledby="setCover">
                        <SetCover {...this.props} />
                    </div>
                    <div className="tab-pane fade" id="setHeader" role="tabpanel" aria-labelledby="setHeader">
                        <SetHeader {...this.props} />
                    </div>
                    <div className="tab-pane fade" id="setApresentation" role="tabpanel" aria-labelledby="profile-tab">
                        <SetApresentation />
                    </div>
                    <div className="tab-pane fade" id="setOthers" role="tabpanel" aria-labelledby="contact-tab">
                        Outros
                    </div>
                </div>
            </Content>
        )
    }
}