import React from 'react'

export default props =>

    <div className="modal fade" id={props.modal.id || "modal"} tabIndex="-1" role="dialog" aria-labelledby={props.modal.id || "modalComponent"} >
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header py-2 bg-Cuboh-color-purple1 align-content-middle">
                    <h5 className="modal-title font-weight-bold" id={props.modal.id || "modalComponent"}>{props.modal.title || ''}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <i id="buttonCloseModal" className="fa-md text-white fas fa-times" aria-hidden="true" />
                    </button>
                </div>
                <div className="modal-body">
                    {props.children}
                </div>
                {
                    props.modal.footer ?
                        <div className="modal-footer bg-Cuboh-color-purple1">
                            <button type="button" className="btn btn-sm btn-outline-light" data-dismiss="modal">Cancelar</button>
                            <button type="button" className="btn btn-sm btn-outline-light" data-dismiss="modal"
                                onClick={e => props.modal.funccb1()}>Confirmar</button>
                        </div>
                        :
                        ''
                }
            </div>
        </div>
    </div>
