import React, { Component } from 'react'
import Content from '../template/jsx/Content'
import API from '../../utils/API'
import Pagination from "react-js-pagination"
import { getStatus, formatDate } from '../tools/utils'
import Alert from '../tools/Alert'
import LoadingIconList from '../tools/LoadingIconList'
import './TestsList.css'
import ModalForms from '../tools/ModalForms'
import TestsMode from '../tests/TestsMode'
import userMasculino from '../../assets/imgs/avatar/userMan.png'
import userFeminino from '../../assets/imgs/avatar/userWoman.png'
import userOutros from '../../assets/imgs/avatar/userOutros.png'
import Loading from '../tools/Loading'
import Toastify, { notify } from '../tools/Toastify'


const InitialTest = {
    user: {
        id: '',
        name: '',
        email: '',
        SendDate: '',
    },

    pagination: {
        activePage: 1,
        limit: 10,
        count: '',
        search: '',
        data: [],
        page: [],
    },
    loadingIconList: false,
    alert: {
        class: 'alert-warning',
        msg: null,
        onClick: null,
        visible: alert.msg ? '' : 'd-none',
    },
    modalCreate: {
        id: 'modalCreateTests',
        title: 'Criar Teste',
        footer: false,
        funccb1: null
    },
    modalView: {
        id: 'modalViewTests',
        title: 'Visualizar Teste',
        footer: false,
        funccb1: null
    },
    showFilter: false,
    test: {
        id: '',
        name: '',
        email: '',
        note: '',
        genre: '',
        groupId: '',
        positionId: '',
    },
    loading: {
        class: 'd-none',
        msg: 'loading...'
    },
    showCanceledTests: false,
}

export default class TestList extends Component {

    state = { ...InitialTest }

    toggleFilter = () => this.setState({ showFilter: !this.state.showFilter })
    totalOfItemsTable = () => this.state.pagination.search ? this.state.pagination.filter.length : this.state.pagination.count
    changeLimit = (e) => {
        const { pagination } = this.state
        pagination.limit = parseInt(e.target.value)
        this.setState({ pagination })
        this.pagination(1)
    }
    setTestSelected = (test) => this.setState({ test })

    clearTestSelected = () => this.setState({ test: InitialTest.test })

    selectAvatarUser = (genre) => genre ? genre.toLowerCase() === 'masculino' ? userMasculino : genre.toLowerCase() === 'feminino' ? userFeminino : userOutros : userOutros

    linkGroupVsTests = (idTest) => this.props.history.push({ pathname: `/groupsVsTests`, paramIdTest: idTest })

    insertTestCb = async (insert, tests) => {
        const { pagination } = this.state
        let indexSelected = 0

        if (!insert) { // insert = false or editing
            indexSelected = await pagination.data.findIndex(test => test.id === tests.id)
            if (indexSelected === -1) return notify('warning', 'Ocorreu algo inesperado, recarregue ou contacte o adm.')
        }

        pagination.data.splice(indexSelected, insert ? 0 : 1, { ...tests })
        this.setState({ pagination })
        this.pagination(pagination.activePage)
    }

    getTestsCb = async () => this.getTests()

    sendEmailTestCb = async (test, e) => await this.sendEmailTest(test, e)

    clickButtonCreateDisc = () => document.getElementById("ButtonCreateDiscModal").click()

    async componentDidMount() {
        if (this.props.location.msg) this.showAlert(true, this.props.location.msg || 'Salvo com Sucesso', this.props.location.alert || 'alert-warning')
        this.getTests()

        if (this.props.location.senddisc) this.clickButtonCreateDisc()
    }

    async getTests() {
        const pagination = { ...this.state.pagination }
        await API.get('/tests')
            .then(resp => {
                if (resp.data.data.length) {
                    this.setState({
                        pagination: {
                            ...pagination,
                            data: resp.data.data,
                            count: resp.data.count
                        }
                    })
                } else {
                    this.setState({ loadingIconList: true })
                }
            })
            .catch(err => {
                console.log(err)
                this.showAlert(true, 'Ocorreu algo inesperado ao tentar listar os testes, contacte o administrador.', 'alert-warning')
                this.setState({ loadingIconList: false })
            })
        //console.log('Atualizou o List')
        this.pagination(this.state.pagination.activePage)
    }

    pagination(activePage) {
        const stateData = this.state.pagination.search ? 'filter' : 'data'
        const pagination = { ...this.state.pagination }
        const arrayTest = pagination[stateData]

        const minListTest = activePage * this.state.pagination.limit - this.state.pagination.limit
        let rowsData = []

        if (!this.state.showCanceledTests) { //Mostrar testes cancelados?
            const validTest = arrayTest.filter(test => test.status !== '02')
            rowsData = validTest.slice(minListTest, minListTest + this.state.pagination.limit)
            pagination.count = validTest.length
        } else { //mostrar
            rowsData = arrayTest.slice(minListTest, minListTest + this.state.pagination.limit)
            pagination.count = arrayTest.length
        }

        pagination.activePage = activePage
        pagination.page = rowsData

        this.setState({ pagination })
    }

    showAlert(show, msg, color) {
        const alert = this.state.alert
        if (show === true) {
            alert.msg = msg
            alert.class = color
            this.setState({ alert })
        } else {
            alert.msg = null
            this.setState({ alert })
        }
    }

    loading(show, msg) {
        const loading = this.state.loading
        if (show === true) {
            loading.msg = msg
            loading.class = ''
            this.setState({ loading })
        } else {
            loading.class = 'd-none'
            this.setState({ loading })
        }
    }

    async updateFilter(e, nameList) {
        const list = { ...this.state[nameList] }
        list.search = e.target.value
        if (e.target.value !== '') {
            list.filter = list.data.filter(total =>
                total.name.toUpperCase().indexOf(list.search.toUpperCase()) > -1
                || total.id.toString().toUpperCase().indexOf(list.search.toUpperCase()) > -1
                || total.token.toUpperCase().indexOf(list.search.toUpperCase()) > -1
                || getStatus(total.status).toUpperCase().indexOf(list.search.toUpperCase()) > -1
                || formatDate(total.createdAt).toUpperCase().indexOf(list.search.toUpperCase()) > -1
                || formatDate(total.sendDate).toUpperCase().indexOf(list.search.toUpperCase()) > -1
            )
        } else {
            list.filter = []
        }
        await this.setState({ [nameList]: { ...list } })
        this.pagination(this.state.pagination.activePage)
    }

    renderHeaderTable() {
        return (
            <React.Fragment>
                <div className="container-fluid my-2">

                    <div className="row">
                        <div className="col-6 px-0">
                            <h4 className="text-CubohColor1"><b>Listas de testes</b></h4>
                        </div>
                        <div className="col-6 px-0">
                            <div className="d-flex justify-content-end">

                                <button className={`btn ${this.state.showFilter ? 'btn-outline-primary' : 'btn-login'} mr-2 btn-sm`} onClick={e => this.toggleFilter(e)}>
                                    <i className="fas fa-filter fa-1x" />
                                </button>

                                <button id="ButtonCreateDiscModal" className="btn btn-sm btn-login fas fa-plus fa-1x mr-2"
                                    data-toggle="modal" data-target="#modalCreateTests"
                                    onClick={e => this.clearTestSelected(e)}
                                />
                            </div>
                        </div>
                    </div>

                    {
                        this.state.showFilter ?
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 px-0">
                                    <div className="input-group input-group-sm mt-2 mt-md-0">
                                        <input type="text" className="form-control" id="search"
                                            placeholder="Pesquisar..." autoComplete="off"
                                            value={this.state.pagination.search || ''}
                                            onChange={(e) => this.updateFilter(e, 'pagination')} />
                                        <div className="input-group-append">
                                            <button className="btn btn-login" type="button"><i className="fas fa-search" /></button>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 px-0">
                                    <div className="custom-control custom-switch text-sm-left text-md-center mt-2 mt-md-0">
                                        <input type="checkbox" className="custom-control-input fa-lg" value={!this.state.showCanceledTests || ''} id="showCanceledTests" onChange={e => this.showCanceledTests(e)} checked={this.state.showCanceledTests ? true : false} />
                                        <label className="custom-control-label " htmlFor="showCanceledTests">Mostrar Cancelados</label>
                                    </div>
                                </div>
                            </div>
                            :
                            ''
                    }
                </div>
            </React.Fragment>
        )
    }

    async showCanceledTests(e) {
        await this.setState({ showCanceledTests: !this.state.showCanceledTests })
        this.pagination(1)
    }

    renderTable() {
        return (
            <div className="content" >
                <div className="table-responsive">
                    <table className="table table-hover table-sm table-striped">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Nome</th>
                                <th className="HiddenColumnOnMobile" scope="col">Token</th>
                                <th scope="col">Status</th>
                                <th scope="col">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderRows()}
                        </tbody>
                        <tfoot className="align-middle">
                            <tr className="align-middle no-wrap py-2">
                                <th className="align-middle text-center" colSpan="2">
                                    <select className="custom-select custom-select-sm inputListFooter"
                                        value={this.state.pagination.limit || ''} id="state" required=""
                                        onChange={e => this.changeLimit(e)}>
                                        <option value="10" defaultValue>10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                    <label className="ml-1"> {`${this.state.pagination.page.length}`} </label>
                                    <label className="ml-1"> {` de ${this.totalOfItemsTable()}`} </label>
                                </th>
                                <th className="align-middle text-center" colSpan="4">
                                    <Pagination
                                        activePage={this.state.pagination.activePage}
                                        itemsCountPerPage={this.state.pagination.limit}
                                        totalItemsCount={parseInt(this.totalOfItemsTable())}
                                        innerClass={"pagination pagination-sm justify-content-center m-1"}
                                        prevPageText={<i className="fa fa-chevron-left" />}
                                        firstPageText={<i className="fa fa-angle-double-left" />}
                                        nextPageText={<i className="fa fa-chevron-right" />}
                                        lastPageText={<i className="fa fa-angle-double-right" />}
                                        itemClass={"page-item"}
                                        linkClass={"page-link"}
                                        disabledClass={""}
                                        pageRangeDisplayed={3}
                                        onChange={e => this.pagination(e)}
                                    />
                                </th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        )
    }

    renderRows() {

        if (this.state.pagination.page.length) {

            return this.state.pagination.page.map(tests => {
                return (
                    <tr className="align-middle" key={tests.id}>
                        <td>{tests.id || "-"}</td>
                        <td className="ColumnTextShort text-capitalize" >{tests.name.toLowerCase() || "-"}</td>
                        <td className="HiddenColumnOnMobile">{tests.token}</td>
                        <td>{getStatus(tests.status) || "-"}</td>
                        <td>
                            <div className=" btn-login btn-sm d-flex justify-content-center" onClick={e => this.setTestSelected(tests)}
                                data-toggle="modal" data-target="#modalViewTests">
                                <i className="fas fa-eye fa-lg" />
                            </div>
                        </td>
                    </tr>
                )
            })

        } else {
            return (
                this.state.pagination.search && !this.state.pagination.page.length ?
                    <tr className="align-middle">
                        <td className="align-middle text-CubohColor2" colSpan="5" >Sem dados para exibir.</td>
                    </tr>
                    :
                    <LoadingIconList loadingIconList={this.state.loadingIconList} />
            )
        }
    }

    renderViewTest() {
        const test = this.state.test

        return (
            <div className="contaner-fluid">
                <div className="card-header card-title-info">
                    <div className="row">
                        <div className="col-3">
                            <img className="profile-pic" src={this.selectAvatarUser(test.genre)} alt="foto do perfil"></img>
                        </div>
                        <div className="col-9">
                            <h5 className="p-0 m-0 text-capitalize">{test.name.toLowerCase()}</h5>
                            <span className="p-0 m-0">{test.email}</span>
                        </div>
                    </div>
                </div>

                <div className="card-body card-title-body">
                    <div className="content">
                        <div className="row">
                            <div className="col-8 border-cubohColor">
                                <h6><b>Token: </b> {test.token} </h6>
                                <h6><b>Status: </b> {getStatus(test.status)} </h6>
                                <h6><b>Cargo: </b>  {test.positionName || '...'}</h6>
                                <h6><b>Criado: </b> {formatDate(test.createdAt)}</h6>
                                <h6><b>Enviado: </b> {formatDate(test.sendDate) || '...'}</h6>
                                <h6><b>Finalizado: </b> {formatDate(test.finishedDate) || '...'}</h6>
                                <h6 className="note"><b>Observação: </b> {test.note || '...'}</h6>
                            </div>
                            <div className="col-4 d-flex align-items-between flex-column bd-highlight">

                                <button className="my-1 btn btn-sm buttonCuboh"
                                    aria-hidden="false" type="button" data-dismiss="modal" aria-label="Close"
                                    onClick={e => this.linkGroupVsTests(test.id)}>
                                    Grupos
                                </button>

                                <button className="my-1 btn btn-sm buttonCuboh"
                                    aria-hidden="false" type="button" data-dismiss="modal" aria-label="Close"
                                    onClick={e => this.sendEmailTest(test, e)}
                                    disabled={test.status === '02' || test.status === '05' ? true : false}>
                                    Reenviar Email
                                </button>

                                <button className="my-1 btn btn-sm buttonCuboh buttonCubohCancel"
                                    aria-hidden="true" type="button" data-dismiss="modal" aria-label="Close"
                                    onClick={e => this.cancelTest(e, test)}
                                    disabled={test.status === '02' || test.status === '05' ? true : false}>
                                    Cancelar
                                </button>

                                <button className="my-1 btn btn-sm buttonCuboh buttonCubohEdit" aria-hidden="true"
                                    type="button" aria-label="Close" data-dismiss="modal"
                                    data-toggle="modal" data-target="#modalCreateTests"
                                    onClick={e => this.setTestSelected(test)}
                                    disabled={test.status === '02' || test.status === '05' ? true : false}>
                                    Editar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }

    async sendEmailTest(test, e) {
        if (e !== undefined) e.preventDefault()

        let ret = false

        const userLoged = JSON.parse(localStorage.getItem('@disc-app/signin'))
        if (!userLoged) window.location.replace('/login')

        const objEmail = {
            evaluatorEmail: userLoged.email,
            evaluatorName: userLoged.name,
            id: test.id,
            token: test.token,
            ratedName: test.name,
            ratedEmail: test.email
        }

        this.loading(true, 'enviando email...')

        await API.post('/sendEmailTest', objEmail)
            .then(suc => {
                notify('success', `E-mail enviado para ${test.email}.`)
                ret = true
            })
            .catch(err => {
                notify('warning', `Falha ao enviar para ${test.email}.`)
                ret = false
            })

        this.loading(false)
        return ret
    }

    async cancelTest(e, tests) {
        e.preventDefault()

        this.loading(true, 'Cancelando teste...')
        await API.put(`cancelTest/${tests.id}`)
            .then(async test => {
                tests.status = '02'
                await this.insertTestCb(false, tests)
                this.loading(false)
                notify('success', 'Teste cancelado.')
            })
            .catch(err => {
                console.log(err)
                this.loading(false)
                notify('warning', 'Ocorreu algo inesperado, contacte o adm.')
            })
        this.loading(false)
    }

    render() {

        return (
            //<Content {...headerProsps}>
            <Content>
                <div id="TestList">
                    <Loading {...this.state.loading} />
                    <Toastify />
                    <Alert
                        msg={this.state.alert.msg}
                        className={this.state.alert.class}
                        onClick={e => this.showAlert(false)}
                        visible={this.state.alert.msg ? '' : 'd-none'}
                    />
                    <ModalForms modal={this.state.modalCreate} test={this.state.test}>
                        <TestsMode test={this.state.test} insert={this.insertTestCb} sendEmail={this.sendEmailTestCb} refreshListTests={this.getTestsCb} />
                    </ModalForms>
                    <ModalForms modal={this.state.modalView} test={this.state.test}>
                        {this.renderViewTest()}
                    </ModalForms>
                    {this.renderHeaderTable()}
                    {this.renderTable()}
                </div>

            </Content>
        )
    }
}