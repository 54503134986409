import '../css/Header.css'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'

const singOut = (e) => {
    e.preventDefault()
    localStorage.removeItem("@disc-app/signin")
    window.location.reload()
}

export default class Header extends Component {

    renderContent() {
        return (
            <header className="header">
                <div className="itens">
                    <div className="d-flex flex-row-reverse ">
                        <div className="item">
                            <Link to="/Login">
                                <i className="fas fa-power-off" onClick={e => singOut(e)} />
                            </Link>
                        </div>

                        <div className="item">
                            <Link to="/dashboard">
                                <i className="fas fa-chart-line fa-fw">
                                    {/*<span className="badge badge-danger badge-counter">3</span>*/}
                                </i>
                            </Link>
                        </div>
                    </div>
                </div>
            </header>
        )
    }

    render() {
        return (
            this.renderContent()
        )
    }
}