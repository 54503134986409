import React from 'react'

export default props =>
    <div id="alertReturn" className={`alert .fade ${props.class} alert-dismissible ${props.visible}`} role="alert">
        <strong><i className="fa fa-check-circle" /> </strong> {props.msg}
        {
            props.hidden === false ?
                ''
                :
                <button type="button" className="close" onClick={props.onClick} aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
        }
    </div>