import React, { Component } from 'react'
import './GroupsCrud.css'
import Alert from '../tools/Alert'
import API from '../../utils/API'
import { ifError } from '../tools/utils'
import Loading from '../tools/Loading'
import Tree from '@naisutech/react-tree'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const initialState = {

    groups: {
        id: '',
        name: '',
        idParent: null,
        selectedName: '',
        status: true
    },
    groupsList: {
        data: [],
    },
    loading: {
        class: 'd-none',
        msg: 'loading...'
    },
    alert: {
        class: 'alert-warning',
        msg: null,
        onClick: null,
        visible: alert.msg ? '' : 'd-none',
    },
    viewGroups: false,
    toogleGroup: false,
    checkLockModal: true,
}

const themeCuboh = {
    'cuboh': {
        text: '#212529',
        bg: '#eee',
        highlight: '#dee2e6 ', // the colours used for selected and hover items
        decal: '#00a2f2', // the colours used  for open folder indicators and icons
        accent: '#212529' // the colour used for row borders and empty file indicators
    }
}

const Icons = iconSet => {
    return (
        <i className={`fas fa-${iconSet || 'user'} fa-1x`} />
    )
}

export default class GroupsCrud extends Component {

    state = { ...initialState }

    selectedGroup = groups => {
        this.setState({
            groups: {
                ...this.state.groups,
                idParent: groups.id,
                selectedName: groups.label,
            },
            viewGroups: this.state.groupsList.data.some(group => group.parentId === groups.id)
        })
    }

    toogleGroups = () => this.setState({ toogleGroup: !this.state.toogleGroup })

    notify = (type, msg) => toast[type](msg);

    updateCheckLockModal = () => this.setState({ checkLockModal: !this.state.checkLockModal })

    async componentDidMount() {
        this.showAlert(false)
        await this.getGroupsList()

        //if (this.props.match.params.id) this.getGroups(this.props.match.params.id)
    }

    componentDidUpdate() {

        if (this.props.groups.id !== this.state.groups.id) {

            if (this.props.groups.id) {

                const groups = this.state.groups
                const thisGroups = this.props.groups

                this.setState({
                    groups: {
                        ...groups,
                        ...thisGroups,
                        selectedName: thisGroups.idParent ? this.state.groupsList.data.filter(groups => groups.id === thisGroups.idParent)[0].label : ''
                    },
                    toogleGroup: thisGroups.idParent ? false : true,
                    viewGroups: thisGroups.idParent ? false : true
                })
            } else {
                if (this.state.groups.id) this.setState({ groups: initialState.groups })
            }
        }
    }

    async getGroups(id) {
        const groups = this.state.groups

        await API.get(`/groups/${id}`)
            .then(resp => {
                if (resp.data) {
                    this.setState({
                        groups: {
                            ...groups,
                            ...resp.data,
                            selectedName: resp.data.idParent ? this.state.groupsList.data.filter(groups => groups.id === resp.data.idParent)[0].label : ''
                        },
                        toogleGroup: resp.data.idParent ? false : true,
                        viewGroups: resp.data.idParent ? false : true
                    })
                } else {
                    this.setState({ loadingIconList: true })
                }
            })
            .catch(err => {
                console.log(err)
                this.notify('warning', 'Ocorreu algo inesperado ao tentar listar os grupos, contacte o administrador.')
                this.setState({ loadingIconList: true })
            })

    }

    async getGroupsList() {
        const groupsList = this.state.groupsList
        await API.get('/getGroupsTree')
            .then(resp => {
                if (resp.data.length) {
                    this.setState({
                        groupsList: {
                            ...groupsList,
                            data: resp.data
                        }
                    })
                } else {
                    this.setState({ loadingIconList: true })
                }
            })
            .catch(err => {
                console.log(err)
                this.notify('warning', 'Ocorreu algo inesperado ao tentar listar os créditos, contacte o administrador.')
                this.setState({ loadingIconList: true })
            })

    }

    showAlert(show, msg, color) {
        const alert = this.state.alert
        if (show === true) {
            alert.msg = msg
            alert.class = color
            this.setState({ alert })
        } else {
            alert.msg = null
            this.setState({ alert })
        }
    }

    loading(show, msg) {
        const loading = this.state.loading
        if (show === true) {
            loading.msg = msg
            loading.class = ''
            this.setState({ loading })
        } else {
            loading.class = 'd-none'
            this.setState({ loading })
        }
    }

    validForm(e) {
        const { groups } = this.state
        let ret = false

        if (!groups.name) {
            this.notify('warning', 'Informar ao menos o nome do grupo.')
        } else if (groups.id === groups.idParent) {
            this.notify('warning', 'Você não pode atrelar um grupo ao mesmo grupo.')
        } else {
            ret = true
        }
        return ret
    }

    save(e) {
        e.preventDefault()
        this.showAlert(false)
        const groups = this.state.groups

        if (this.validForm()) {

            delete groups.selectedName
            if (!groups.status) groups.status = false

            const method = groups.id ? 'put' : 'post'
            const url = groups.id ? `/groups/${groups.id}` : '/groups'

            API[method](url, groups)
                .then(resp => {
                    this.loading(false)
                    this.getGroups(resp.data.id)
                    this.getGroupsList()
                    this.props.refreshGroupsCb()
                    this.notify('success', `${groups.name} salvo.`)
                    if (this.state.checkLockModal) document.getElementById('buttonCloseModalGroups').click()
                })
                .catch(err => {
                    this.loading(false)
                    console.log('err =', err)
                    const backWs = ifError(err)
                    this.notify('error', backWs)
                })
        }
    }

    renderForms() {
        return (
            <div id="SetCover">
                <div className="row mt-3">
                    <div className="col-xs-12 col-sm-12 col-md-12">
                        <div className="col-xs-12 col-sm-12 col-md-12">
                            <div className="form">
                                <div className="row">

                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <div className="form-group">
                                            <small className=" d-flex form-text text-CubohColor2 m-0">1 - Informe o nome do grupo <span className="badge text-danger">*</span></small>
                                            <div className="input-group">
                                                <input type="text" id="name" autoComplete="off" value={this.state.groups.name || ''} onChange={e => this.updateField(e)} placeholder="Financeiro, Compras, Tecnologia ...." className="form-control" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <small className="d-flex form-text text-CubohColor2 m-0">2 - Deseja atrelar um grupo pai? <span className="badge text-muted"> (Opcional)</span></small>

                                        <div className="input-group flex-nowrap">
                                            <input className="form-control" type="text" id="idParent" value={this.state.groups.idParent ? `${this.state.groups.idParent || ''} - ${this.state.groups.selectedName || ''}` : ''} autoComplete="off" placeholder="Nenhum grupo atrelado" readOnly />
                                            <div className="input-group-prepend">
                                                <button className="input-group-text bg-primary" onClick={e => this.unselectedGroup(e)}>
                                                    <small className="text-white">{this.state.groups.idParent ? 'Desvincular' : 'Vincular'}</small>{/* <i className="fas fa-trash-alt text-white" /> */}
                                                </button>
                                            </div>
                                        </div>

                                        <div className={`accordion mt-1 ${this.state.viewGroups ? "" : "d-none"}`} id="accordionExample">

                                            <div className="card">
                                                <div className="card-header p-0" id="headingOne">
                                                    <h2 className="mb-0">
                                                        <button className="btn btn-link btn-block text-left" type="button" onClick={e => this.toogleGroups()} data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                            <div className="d-flex justify-content-between align-middle">
                                                                Escolher grupo
                                                            <i className={`fas fa-chevron-${this.state.toogleGroup ? "down" : "up"} fa-lg mt-1`}></i>
                                                            </div>
                                                        </button>
                                                    </h2>
                                                </div>

                                                <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                                    <div className="card-body p-0">
                                                        <div style={{ width: "100%", height: "230px", display: "flex", flexGrow: 1 }}>
                                                            <Tree nodes={this.state.groupsList.data || ''} onSelect={e => this.selectedGroup(e)}
                                                                customTheme={themeCuboh} theme={'cuboh'}
                                                                iconSet={{
                                                                    file: <Icons iconSet='users' /> // the  file item indicator (default 'paperclip')
                                                                }} />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                                        <div className="custom-control custom-switch text-right">
                                            <input type="checkbox" className="custom-control-input fa-lg" value={!!!this.state.groups.status || ''} id="status" onChange={e => this.updateField(e)} checked={this.state.groups.status ? true : false} />
                                            <label className="custom-control-label " htmlFor="status">Grupo ativo</label>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <hr />
                        <div className="row">
                            <div className="col-12 d-flex justify-content-between">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value={this.state.checkLockModal || ''} id="flexCheckChecked" onChange={e => this.updateCheckLockModal()} defaultChecked />
                                    <label className="form-check-label" htmlFor="flexCheckChecked">
                                        <small className="text-secondary">Fechar ao salvar</small>
                                    </label>
                                </div>
                                <button className="btn btn-sm btn-success ml-2" type="submit"
                                    onClick={e => this.save(e)}>
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        )
    }

    unselectedGroup = event => this.setState({
        groups: {
            ...this.state.groups,
            idParent: null,
            selectedName: '',
        },
        toogleGroup: true,
        viewGroups: true
    })


    renderGroupsList() {

        return this.state.groupsList.data.map(groups => {
            return (
                <option value={groups.id} label={groups.name} key={groups.id}> ae {groups.name} </option>
            )
        })

    }

    async updateField(event) {
        const groups = { ...this.state.groups }
        groups[event.target.id] = event.target.value
        await this.setState({ groups })
    }

    async updateFilter(event) {
        const groupsList = this.state.groupsList
        groupsList.search = event.target.value
        if (event.target.value !== '') {
            groupsList.filter = groupsList.list.filter(total => !total.name.toUpperCase().indexOf(event.target.value.toUpperCase()))
        } else {
            groupsList.filter = ''
        }
        await this.setState({ groupsList })
    }

    render() {
        return (
            <React.Fragment>
                <div className="modal show" id="modalCreateGroups" tabIndex="-1" role="dialog" aria-labelledby="ModalFormCreateGroups" >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header py-2 btn-login">
                                <h5 className="modal-title text-white" id="ModalFormCreateGroups">{`${this.state.groups.id ? 'Editar grupo' : 'Criar grupo'}`}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <i id="buttonCloseModalGroups" className="fa-md text-white fas fa-times" aria-hidden="true" />
                                </button>
                            </div>
                            <div className="modal-body">

                                <Loading {...this.state.loading} />
                                <Alert
                                    msg={this.state.alert.msg}
                                    class={this.state.alert.class}
                                    onClick={e => this.showAlert(false)}
                                    visible={this.state.alert.msg ? '' : 'd-none'}
                                />
                                {this.renderForms()}

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}