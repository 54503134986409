import React, { PureComponent } from 'react'
import API from '../../utils/API'
import {
    ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

const inititalState = {
    data: [],
}

const CustomBar = (props) => {
    let { x, y, width, height, fill, name } = props;
    if (name !== null) {

        const path = `M ${x},${y} 
                    h ${width} 
                    v ${height} 
                    h ${-width} 
                    Z`;

        if (name === 'D') {
            fill = '#DC3545'
        } else if (name === 'I') {
            fill = '#007BFF'
        } else if (name === 'S') {
            fill = '#FFC107'
        } else if (name === 'C') {
            fill = '#28A745'
        }

        return <path {...props} d={path} stroke="none" fill={fill} />;
    }

    return null;
};

const customLabel = ({ x, y, width, value }) => {
    return <text x={x + width / 2} y={y + width} fontWeight={"bold"} fill="#fff" textAnchor="middle">{value}</text>
}

export default class DiscResult extends PureComponent {
    //static jsfiddleUrl = 'https://jsfiddle.net/alidingling/30763kr7/';

    state = { ...inititalState }

    async componentDidMount() {
        await API.get(`/reportDiscResult/${this.props.id}/${this.props.type}`)
            .then(resp => {
                this.setState({ data: resp.data })
                //this.state.data.push({...resp.data})
            })
            .catch(err => console.log(err))
    }

    render() {
        return (
            <div style={{ width: '100%', height: 300 }}>
                <ResponsiveContainer>
                    <BarChart

                        id={'resultDisc'}
                        className="img-fluid"

                        data={this.state.data}
                        padding={{ top: 0, right: 0, left: 0, bottom: 0, }}
                        margin={{ top: 0, right: 50, left: 0, bottom: 0, }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="total" legendType="none" label={customLabel} shape={CustomBar} fill="#DC3545" fillOpacity="0.7" />
                    </BarChart >
                </ResponsiveContainer>
            </div>
        );

    }
}
