import React, { Component } from 'react'
import './ResultList.css'
import API from '../../utils/API'
import Pagination from "react-js-pagination"
import { Link } from 'react-router-dom'
import { formatDate } from '../tools/utils'
import Content from '../template/jsx/Content'
import Alert from '../tools/Alert'
import Loading from '../tools/Loading'
import LoadingIconList from '../tools/LoadingIconList'

const InitialState = {
    user: {
        id: '',
        name: '',
        company: '',
        email: '',
    },
    pagination: {
        activePage: 1,
        limit: 10,
        count: '',
        search: '',
        filter: [],
        list: [],
        testPage: [],
    },
    paramsEmail: {
        idTest: '',
        ratedName: '',
        ratedEmail: '',
        msg: 'Prezado(a), segue seu relatório DISC, espero que ele possa agregar e auxiliar no seu desenvolvimento, precisando disponha.'
    },
    alert: {
        class: 'alert-warning',
        msg: null,
        onClick: null,
        visible: alert.msg ? '' : 'd-none',
    },
    loading: {
        class: 'd-none',
        msg: '...'
    },
    loadingIconList: false,
    downloadPdf: null
}

export default class ResultList extends Component {

    state = { ...InitialState }

    componentDidMount() {
        this.getTest()
    }

    async getTest() {
        const pagination = this.state.pagination
        await API.get('/getTestsFinished')
            .then(resp => {

                if (resp.data.data.length) {
                    this.setState({
                        pagination: {
                            ...pagination,
                            list: resp.data.data,
                            filter: resp.data.data,
                            count: resp.data.count
                        }
                    })
                } else {
                    this.setState({ loadingIconList: true })
                }
            })
            .catch(err => {
                console.log(err)
                this.showAlert(true, 'Ocorreu algo inesperado ao tentar listar os créditos, contacte o administrador.', 'alert-warning')
                this.setState({ loadingIconList: true })
            })

        this.pagination(this.state.pagination.activePage)
    }

    async createPdf(e) {
        e.preventDefault()
        this.loading(true, 'Analisando Dados...')

        await API.get(`createPdf/${e.target.value}`, { 'responseType': 'blob' })
            .then(resp => {
                this.loading(false)

                //let pdf = window.URL.createObjectURL(resp.data)

                this.showAlert(true, `Gerado com Sucesso! Você pode liberar o navegador para abrir automaticamente ou clicar no botão abaixo.`, 'alert-success')
                this.setState({ downloadPdf: resp.data })

                //window.open(pdf, '_blank', '', true);
                this.openPdf(e)
            })
            .catch(err => {
                this.loading(false)
                console.log(err.response)
                this.showAlert(true, 'Ocorreu algo inesperado, contacte o administrador.', 'alert-danger')
            })
    }

    async sendPdfToEmail(e) {
        e.preventDefault()
        const paramsEmail = this.state.paramsEmail

        if (!paramsEmail) { //validando se o ID do teste foi passado
            this.showAlert(true, 'Ocorreu algo inesperado ao enviar e-mail, atualize a página e tente novamente ou contacte o adm.', 'alert-warning')
        }

        this.loading(true, 'Gerando e enviando via E-mail...')
        await API.post('/sendPdfToEmail', paramsEmail)
            .then(suc => {
                this.loading(false)
                this.showAlert(true, 'O relatório foi enviado com sucesso.', 'alert-success')
            })
            .catch(err => {
                this.loading(false)
                console.warn(err.response)
                this.showAlert(true, 'Ocorreu algo inesperado, tente novamente ou contacte o adm.', 'alert-danger')
            })
    }

    async renderBoxPdfToEmail(e) {
        e.preventDefault()
        const testPage = this.state.pagination.testPage

        if (!e.target.value) { //validation
            this.showAlert(true, 'Não foi possivel identificar o teste, atualiza página e tente novamente ou contacte o adm.', 'alert-warning')
            return
        } else {
            const test = testPage.find(test => test.id === parseInt(e.target.value))
            if (!test || localStorage.getItem("@disc-app/signin") === null) {
                this.showAlert(true, 'Ocorreu algo inespeado ao selecionar o teste, atualiza página e tente novamente ou contacte o adm.', 'alert-warning')
                return
            } else {
                const paramsEmail = await {
                    idTest: test.id,
                    ratedName: test.name,
                    ratedEmail: `${test.email};${JSON.parse(localStorage.getItem('@disc-app/signin')).email}`,
                    msg: `Estou te enviando este relatório DISC. Conhecer as pessoas é o primeiro passo para um processo de desenvolvimento contínuo e ficamos super felizes em ajudar nesse processo! 😀 Conta com a gente! 😉`
                }

                this.setState({ paramsEmail })
            }
        }
    }

    renderFormsPdfToEmail() {
        return (
            <div className="modal fade" id="modalSendToEmailPdf" tabIndex="-1" role="dialog" aria-labelledby="ModalFormSendEmailToPdf" >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header btn-login">
                            <h5 className="modal-title text-white" id="ModalFormSendEmailToPdf">Enviar relatório PDF via E-mail</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group">
                                    <label htmlFor="recipient-name" className="col-form-label">Enviar para o E-mail :</label>
                                    <input type="text" className="form-control"
                                        placeholder="erika@exemplo.com;maria@exemplo.com.br"
                                        id="ratedEmail"
                                        value={this.state.paramsEmail.ratedEmail || ''}
                                        onChange={e => this.updateField(e)}
                                    />
                                    <small className="form-text text-muted">- Separar com ponto e virgula (email01@ex.com ; email02@ex.com )</small>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="message-text" className="col-form-label">Alterar mensagem padrão :</label>
                                    <textarea className="form-control" placeholder="Escreva uma mensagem customizada."
                                        rows="6" maxLength="250"
                                        id="msg"
                                        value={this.state.paramsEmail.msg || ''}
                                        onChange={e => this.updateField(e)}
                                    />
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer btn-login">
                            <button type="button" className="btn btn btn-outline-light" data-dismiss="modal">Cancelar</button>
                            <button type="button" className="btn btn btn-outline-light" data-dismiss="modal"
                                onClick={e => this.sendPdfToEmail(e)}>Enviar PDF</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    updateField(event) {
        const paramsEmail = { ...this.state.paramsEmail }
        paramsEmail[event.target.id] = event.target.value
        this.setState({ paramsEmail })
    }

    openPdf(e) {
        e.preventDefault()
        const pdf = window.URL.createObjectURL(this.state.downloadPdf)
        window.open(pdf, '_blank', '', true);
    }

    showAlert(show, msg, color) {
        const alert = this.state.alert
        if (show === true) {
            alert.msg = msg
            alert.class = color
            this.setState({ alert })
        } else {
            alert.msg = null
            this.setState({ alert })
        }
    }

    loading(show, msg) {
        const loading = this.state.loading
        if (show === true) {
            loading.msg = msg
            loading.class = ''
            this.setState({ loading })
        } else {
            loading.class = 'd-none'
            this.setState({ loading })
        }
    }

    renderHeaderTable() {
        return (
            <React.Fragment>
                <div className="container-fluid mb-2">
                    <div className="row mb-2 mt-2">
                        <div className="col-10 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                            <div className="input-group">
                                <input type="text" className="form-control" id="search"
                                    placeholder="Pesquisar por nome"
                                    value={this.state.pagination.search || ''}
                                    onChange={(e) => this.updateFilter(e)} />
                                <div className="input-group-append">
                                    <button className="btn btn-login" type="button"><i className="fas fa-search" /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    pagination(activePage) {

        const pagination = { ...this.state.pagination }
        const minListTest = activePage * this.state.pagination.limit - this.state.pagination.limit
        const tests = this.state.pagination.filter.slice(minListTest, minListTest + this.state.pagination.limit)
        pagination.activePage = activePage
        pagination.testPage = tests
        this.setState({ pagination })
    }

    async updateFilter(event) {
        const pagination = { ...this.state.pagination }
        pagination.search = event.target.value
        if (event.target.value !== '') {
            pagination.filter = pagination.list.filter(total => !total.name.toUpperCase().indexOf(event.target.value.toUpperCase()))
        } else {
            pagination.filter = pagination.list
        }
        await this.setState({ pagination })
        this.pagination(this.state.pagination.activePage)
    }

    renderTable() {
        return (
            <div className="content p-2">
                <div className="table-responsive" style={{ overflowX: 'initial' }}>
                    <table className="table table-hover table-sm">
                        <caption>{`${this.state.pagination.testPage.length} de ${this.state.pagination.filter.length}`}</caption>
                        <thead>
                            <tr>
                                <th scope="col">Disc</th>
                                <th scope="col">Nome</th>
                                <th scope="col">Finalizado</th>
                                <th scope="col">Opções</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderRows()}
                        </tbody>
                    </table>
                    <div>
                        <Pagination
                            activePage={this.state.pagination.activePage}
                            itemsCountPerPage={this.state.pagination.limit}
                            totalItemsCount={parseInt(this.state.pagination.count)}
                            innerClass={"pagination mr-5 d-flex justify-content-end"}
                            prevPageText={<i className="fa fa-chevron-left" />}
                            firstPageText={<i className="fa fa-angle-double-left" />}
                            nextPageText={<i className="fa fa-chevron-right" />}
                            lastPageText={<i className="fa fa-angle-double-right" />}
                            itemClass={"page-item"}
                            linkClass={"page-link"}
                            disabledClass={""}
                            pageRangeDisplayed={4}
                            onChange={e => this.pagination(e)}
                        />
                    </div>
                </div>
            </div>
        )
    }

    renderRows() {

        if (this.state.pagination.list.length) {

            return this.state.pagination.testPage.map(tests => {

                return (
                    <tr key={tests.id}>
                        <td>{tests.id}</td>
                        <td>{tests.name}</td>
                        <td>{formatDate(tests.finishedDate)}</td>
                        <td>
                            <div className="btn-group dropleft ml-2 float-center" role="group">
                                <button id="btnGroupDrop1" type="button" className="btn btn-login dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                    <i className="fas fa-bars" />
                                </button>
                                <div className="dropdown-menu dropdown-menu-down float-left" aria-labelledby="btnGroupDrop1">

                                    <Link to={`/finalReport/${tests.id}`}>
                                        <button className="dropdown-item"
                                            value=''>
                                            <i className="fas fa-desktop mr-2 " />| Resumo em tela
                                        </button>
                                    </Link>

                                    <div role="separator" className="dropdown-divider" />

                                    <button className="dropdown-item"
                                        value={tests.id || ''} onClick={e => this.createPdf(e)}>
                                        <i className="fas fa-download mr-2 " />| Download do PDF
                                    </button>

                                    <div role="separator" className="dropdown-divider" />

                                    <Link to={`/resultAnswers/${tests.id}`}>
                                        <button className="dropdown-item">
                                            <i className="fas fa-file-alt mr-2" />| Respostas
                                        </button>
                                    </Link>

                                    <div role="separator" className="dropdown-divider" />

                                    <button className="dropdown-item"
                                        value={tests.id || ''} onClick={e => this.renderBoxPdfToEmail(e)}
                                        data-toggle="modal" data-target="#modalSendToEmailPdf">
                                        <i className="fas fa-envelope mr-2 " />| Enviar por E-mail
                                    </button>

                                </div>
                            </div>
                            {/*
 
                            <div className="d-flex justify-content-center">
                                <Link to={`/resultAnswers/${tests.id}`}>
                                    <button className="btn btn-login text-white fas fa-file-signature "
                                        data-toggle="tooltip" data-placement="top" title="Respostas das questões" />
                                </Link>
                                <Link to={`/finalReport/${tests.id}`}>
                                    <button className="btn btn-primary fas fa-desktop  ml-2"
                                        data-toggle="tooltip" data-placement="top" title="Resultado em tela" />
                                </Link>
                                <button className="btn btn-success far fa-file-pdf  ml-2 "
                                    data-toggle="tooltip" data-placement="top" title="Relatório"
                                    value={tests.id || ''} onClick={e => this.createPdf(e)}
                                />
                            </div>
                            */}
                        </td>
                    </tr>
                )
            })
        } else {
            return (
                <LoadingIconList loadingIconList={this.state.loadingIconList} />
            )
        }
    }

    render() {
        return (
            <Content>
                <Loading {...this.state.loading} />
                <Alert
                    msg={this.state.alert.msg}
                    class={this.state.alert.class}
                    onClick={e => this.showAlert(false)}
                    visible={this.state.alert.msg ? '' : 'd-none'}
                />
                {<a href="/" onClick={e => this.openPdf(e)} className={`ml-2 btn btn-login text-white ${this.state.downloadPdf ? '' : 'd-none'} `} target="_blank" rel="noopener noreferrer"> <i className="fas fa-arrow-alt-circle-down fa-lg" /> Download</a>}
                {this.renderHeaderTable()}
                {this.renderTable()}
                <div id="ModalFormsEmail">
                    {this.renderFormsPdfToEmail()}
                </div>
            </Content>
        )
    }

}