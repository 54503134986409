import React, { Component } from 'react'
import Content from '../template/jsx/Content'
import Pagination from "react-js-pagination"
import API from '../../utils/API'
import './ResultList.css'
import $ from 'jquery'

const initialState = {
    pagination: {
        activePage: 1,
        limit: 10,
        count: '',
        list: [],
        questionsPage: [],
    },
    answers: [],
    result: {
        D: '',
        I: '',
        S: '',
        C: '',
    },
    cardColor: ['bg-danger', 'bg-primary', 'bg-warning', 'bg-success']
}

export default class QuestionsList extends Component {

    state = { ...initialState }

    async componentDidMount() {

        await this.getAnswers(this.props.match.params.id)
        await this.getQuestions()
        await this.pagination(this.state.pagination.activePage)

        //console.log(this.state)
    }

    async getQuestions() {

        const pagination = { ...this.state.pagination }
        await API.get('/questions')
            .then(resp => this.setState({
                pagination: {
                    ...pagination,
                    list: resp.data.data,
                    count: resp.data.count,
                }
            }))
    }

    async getAnswers(id) {
        let answers = this.state.answers
        await API.get(`/resultAnswers/${id}`)
            .then(resp => answers = resp.data.data)
        this.setState({ answers })
    }

    pagination(activePage) {

        const pagination = { ...this.state.pagination }
        const minListTest = activePage * this.state.pagination.limit - this.state.pagination.limit
        const questions = this.state.pagination.list.slice(minListTest, minListTest + this.state.pagination.limit)

        pagination.activePage = activePage
        pagination.questionsPage = questions

        this.setState({ pagination })
        //window.scrollTo(500, 0);
        $("html, body").animate({ scrollTop: 0 }, 'swing')
    }

    renderHeaders() {

        return (
            <React.Fragment>
                <div className="row mb-2">
                    <div className="col-12">
                        <div className="alert alert-dark alert-dismissible fade show" role="alert">
                            <strong>Atenção</strong><small> As opções das respostas abaixo estão na ordem a qual foram respondidas.</small>
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderQuestions() {

        return this.state.pagination.questionsPage.map((question, ind) => {
            return (
                <div className="col-sm-6" key={question.id}>
                    <div className="card m-1" id={question.id}>
                        <div className="card-body">
                            <h6 className="card-title  m-1 "><b>{`${question.ordem} - ${question.name}`}</b></h6>
                            <div className="d-flex flex-wrap-reverse">
                                <div className={`order-${this.state.answers[ind].optionA} col-sm-12`}><p className="card-text text-muted m-1"> <b>A) </b>{question.optionA}</p></div>
                                <div className={`order-${this.state.answers[ind].optionB} col-sm-12`}><p className="card-text text-muted m-1"> <b>B) </b>{question.optionB}</p></div>
                                <div className={`order-${this.state.answers[ind].optionC} col-sm-12`}><p className="card-text text-muted m-1"> <b>C) </b>{question.optionC}</p></div>
                                <div className={`order-${this.state.answers[ind].optionD} col-sm-12`}><p className="card-text text-muted m-1"> <b>D) </b>{question.optionD}</p></div>
                            </div>
                        </div>

                    </div>
                </div>
            )
        })
    }

    renderCards() {
        return (
            <div className="content">
                <div className="card-group">
                    <div className="row">
                        {this.renderQuestions()}
                    </div>
                </div>

                <div>
                    <Pagination
                        activePage={this.state.pagination.activePage}
                        itemsCountPerPage={this.state.pagination.limit}
                        totalItemsCount={this.state.pagination.count}
                        innerClass={"pagination mr-5 mt-3 d-flex justify-content-end"}
                        prevPageText={<i className="fa fa-chevron-left" />}
                        firstPageText={<i className="fa fa-angle-double-left" />}
                        nextPageText={<i className="fa fa-chevron-right" />}
                        lastPageText={<i className="fa fa-angle-double-right" />}
                        itemClass={"page-item"}
                        linkClass={"page-link"}
                        disabledClass={""}
                        pageRangeDisplayed={4}
                        onChange={e => this.pagination(e)}
                    />
                </div>
            </div>
        )
    }

    render() {
        return (
            <Content>
                {this.renderHeaders()}
                {this.renderCards()}
            </Content>
        )
    }
}