import React, { Component } from 'react'
import './Home.css'
import Content from '../template/jsx/Content'
import { Link } from 'react-router-dom'
import API from '../../utils/API'

const initialState = {
    posts: {
        postFilter: [],
        postCards: [],
    },
    showLoadIcon: false,
    data: []
}

export default class Home extends Component {

    state = { ...initialState }

    loadIcon = () => <div className="text-center m-auto p-auto"> <i className="fas fa-circle-notch fa-pulse text-light m-auto fa-5x loadingIcon" /> </div>

    async componentDidMount() {
        this.setState({ showLoadIcon: true })
        await this.getData()
        this.setState({ showLoadIcon: false })
    }

    async getData() {

        await API.get('/infoHome/')
            .then(suc => {
                this.setState({ data: suc.data })
            })
            .catch(err => {
                console.log('Erro Home.js => ', err.response)
            })

        await this.processData()

    }

    async processData() {
        const { statusType, profilesType, credits, recentTop } = this.state.data

        let testsStatus = { send: 0, started: 0, finished: 0 }

        for (let i = 0; i < statusType.length; i++) {
            if (statusType[i].status === '05') testsStatus.finished += parseInt(statusType[i].quantity)
            else if (statusType[i].status === '03') testsStatus.send += parseInt(statusType[i].quantity)
            else if (statusType[i].status === '04') testsStatus.started += parseInt(statusType[i].quantity)
        }

        let testsProfiles = { D: 0, I: 0, S: 0, C: 0, amt: 0 }

        for (let i = 0; i < profilesType.length; i++) {
            if (profilesType[i].prof === 'D') testsProfiles.D += parseInt(profilesType[i].amt)
            else if (profilesType[i].prof === 'I') testsProfiles.I += parseInt(profilesType[i].amt)
            else if (profilesType[i].prof === 'S') testsProfiles.S += parseInt(profilesType[i].amt)
            else if (profilesType[i].prof === 'C') testsProfiles.C += parseInt(profilesType[i].amt)
        }

        this.setState({ data: { testsStatus, testsProfiles, credits, recentTop } })
    }

    renderCardStatus() {
        const { send, started, finished } = this.state.data.testsStatus || 0
        const amt = send + started + finished
        const sendPercent = (send * 100 / amt || 0).toFixed(0) + '%' || 0
        const startedPercent = (started * 100 / amt || 0).toFixed(0) + '%' || 0
        const finishedPercent = (finished * 100 / amt || 0).toFixed(0) + '%' || 0

        return (
            <div className="card h-100 bg-c-blue order-card">
                <div className="card-block pb-0 px-3 pt-3 ">
                    <div className="row">
                        <div className="col-12">
                            <h6 className="card-title mb-0">Status</h6>
                        </div>
                    </div>
                    {
                        this.state.showLoadIcon ?
                            this.loadIcon()
                            :
                            <React.Fragment>

                                <div className="row">
                                    <div className="col-12">
                                        <div className="row text-center">
                                            <div className="col-4 px-1">
                                                <div className="card-value">
                                                    <span className="">{send || 0}</span>
                                                    <label className="card-value-percent pl-1">| {sendPercent || 0}</label>
                                                </div>
                                                <span className="card-value-legend badge rounded badge-primary">Enviado</span>
                                            </div>

                                            <div className="col-4 px-1">
                                                <div className="card-value">
                                                    <span className="">{started || 0}</span>
                                                    <label className="card-value-percent pl-1">| {startedPercent || 0}</label>
                                                </div>
                                                <span className="card-value-legend badge rounded badge-warning text-white">Inciado</span>
                                            </div>

                                            <div className="col-4 px-1">
                                                <div className="card-value">
                                                    <span className="">{finished || 0}</span>
                                                    <label className="card-value-percent pl-1">| {finishedPercent || 0}</label>
                                                </div>
                                                <span className="card-value-legend badge rounded badge-success ">Finalizado</span>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-12">
                                        <div className="bg-light rounded p-2">
                                            <div className="progress m-auto" style={{ height: '14px' }}>
                                                <div className="progress-bar bg-primary" role="progressbar" style={{ width: sendPercent || 0, ariaValuenow: "25", ariaValuemin: '50', ariaValuemax: '100' }}></div>
                                                <div className="progress-bar bg-warning" role="progressbar" style={{ width: startedPercent || 0, ariaValuenow: "50", ariaValuemin: '50', ariaValuemax: '100' }}></div>
                                                <div className="progress-bar bg-success" role="progressbar" style={{ width: finishedPercent || 0, ariaValuenow: "25", ariaValuemin: '50', ariaValuemax: '100' }}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                    }
                </div>
            </div>
        )
    }

    renderCardRecents() {

        const recentTop = this.state.data.recentTop || []

        return (
            <div className="card h-100 bg-c-blue order-card">
                <div className="card-block p-3">
                    <h6 className="card-title ">Testes Recentes</h6>
                    {
                        this.state.showLoadIcon ?
                            this.loadIcon()
                            :
                            <div className="row text-secondary">
                                {recentTop.length ?
                                    <React.Fragment>
                                        <div className={`col-${recentTop.length > 5 ? '6' : '12'} pr-0 border-right border-light`}>
                                            <ul className="list-group list-group-flush list-tests">
                                                {this.renderRowsTests(1)}
                                            </ul>
                                        </div>
                                        <div className="col-6 pl-0">
                                            <ul className="list-group list-group-flush list-tests">
                                                {this.renderRowsTests(2)}
                                            </ul>
                                        </div>
                                    </React.Fragment>
                                    :
                                    <label className="card-title text-light px-3 m-auto align-middle">Sem dados...</label>
                                }
                            </div>
                    }
                </div>
            </div>
        )
    }

    renderRowsTests(column) {
        const recentTop = this.state.data.recentTop || []
        const pageTest = recentTop.slice(column * 5 - 5, column * 5) || []

        return (
            recentTop ? pageTest.map((tests, i) => {
                return (
                    <li key={i} className="list-group-item d-flex justify-content-between align-items-center px-2 p-0 m-0">
                        <small className="text-lowercase text-truncate" >{tests.name || ''}</small>
                        <i className={`${tests.status === '05' ? 'fas fa-check' : tests.status === '04' ? 'fas fa-cog fa-spin' : 'far fa-clock'}`} />
                    </li>
                )
            }) : ''
        )
    }

    renderMenu() {

        const token = !!JSON.parse(localStorage.getItem('@disc-app/signin')) ? JSON.parse(localStorage.getItem('@disc-app/signin')) : this.props

        return (
            <div className="card h-100 bg-c-blue order-card">
                <div className="container-fluid">

                    <div className="row text-center my-2">

                        <div className="col-sm-12 col-md-2 m-auto menu-fast">
                            <h5 className="m-b-20 menu-fast-title">Menu rápido</h5>
                            <i className="fa fa-history menu-fast-icon" />
                        </div>

                        <div className="col-sm-12 col-md-10">
                            <div className="row break-colums mx-auto">

                                <Link to={{ pathname: '/testsList', senddisc: true }} >
                                    <div className="card-item mx-auto my-2 p-2">
                                        <h5><i className="fa fa-play " /></h5>
                                        <h6 className="card-title">Enviar Teste</h6>
                                    </div>
                                </Link>

                                <Link to="/resultList">
                                    <div className="card-item mx-auto my-2 p-2">
                                        <h5><i className="fa fa-chart-line " /></h5>
                                        <h6 className="card-title">Resultados</h6>
                                    </div>
                                </Link>

                                <Link to={`/usersCreate/${token.id || ''}`}>
                                    <div className="card-item mx-auto my-2 p-2">
                                        <h5><i className="fa fa-user-cog " /></h5>
                                        <h6 className="card-title">Usuário</h6>
                                    </div>
                                </Link>

                                <Link to="/CreditsUser">
                                    <div className="card-item mx-auto my-2 p-2">
                                        <h5><i className="fa fa-dollar-sign " /></h5>
                                        <h6 className="card-title">Saldo</h6>
                                    </div>
                                </Link>

                                <Link to={`/setReport/${token.id || ''}`}>
                                    <div className="card-item mx-auto my-2 p-2">
                                        <h5><i className="fa fa-paint-brush " /></h5>
                                        <h6 className="card-title">Customizar</h6>
                                    </div>
                                </Link>

                                <Link to="/groupsList">
                                    <div className="card-item mx-auto my-2 p-2">
                                        <h5><i className="fa fa-users " /></h5>
                                        <h6 className="card-title">Grupos</h6>
                                    </div>
                                </Link>
                                <Link to="/positionsList">
                                    <div className="card-item mx-auto my-2 p-2">
                                        <h5><i className="fa fa-id-badge " /></h5>
                                        <h6 className="card-title">Cargos</h6>
                                    </div>
                                </Link>
                                <Link to="/dashboard">
                                    <div className="card-item mx-auto my-2 p-2">
                                        <h5><i className="fa fa-tachometer-alt" /></h5>
                                        <h6 className="card-title">Dashboard</h6>
                                    </div>
                                </Link>
                                <Link to="/questionsList">
                                    <div className="card-item mx-auto my-2 p-2">
                                        <h5><i className="fa fa-question " /></h5>
                                        <h6 className="card-title">Questões</h6>
                                    </div>
                                </Link>
                                <Link to="/postWall" className="d-sm-block d-none">
                                    <div className="card-item mx-auto my-2 p-2">
                                        <h5><i className="fas fa-rss" /></h5>
                                        <h6 className="card-title">Blog</h6>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderBalance() {

        const { balanceCurrent, balanceUsed } = this.state.data.credits || 0
        const currentPercent = ((balanceCurrent - balanceUsed) * 100 / balanceCurrent || 0).toFixed(0) + '%' || 0
        const usedPercent = (balanceUsed * 100 / balanceCurrent || 0).toFixed(0) + '%' || 0

        return (
            <div className="card h-100 bg-c-blue order-card">
                <div className="card-block pb-0 px-3 pt-3 ">
                    <div className="row">
                        <div className="col-12">
                            <h6 className="card-title mb-0">Saldo</h6>
                        </div>
                    </div>
                    {
                        this.state.showLoadIcon ?
                            this.loadIcon()
                            :
                            <React.Fragment>

                                <div className="row">
                                    <div className="col-12">
                                        <div className="row text-center">
                                            <div className="col-6 px-1">
                                                <div className="card-value">
                                                    <span className="">{balanceCurrent - balanceUsed || 0}</span>
                                                    <label className="card-value-percent pl-1">| {currentPercent || 0}</label>
                                                </div>
                                                <span className="card-value-legend badge rounded badge-primary">Disponivel</span>
                                            </div>

                                            <div className="col-6 px-1">
                                                <div className="card-value">
                                                    <span className="">{balanceUsed || 0}</span>
                                                    <label className="card-value-percent pl-1">| {usedPercent || 0}</label>
                                                </div>
                                                <span className="card-value-legend badge rounded badge-warning text-white">Utilizado</span>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-12">
                                        <div className="bg-light rounded p-2">
                                            <div className="progress m-auto" style={{ height: '14px' }}>
                                                <div className="progress-bar bg-primary" role="progressbar" style={{ width: currentPercent || 0, ariaValuenow: "25", ariaValuemin: '50', ariaValuemax: '100' }} />
                                                <div className="progress-bar bg-warning" role="progressbar" style={{ width: usedPercent || 0, ariaValuenow: "50", ariaValuemin: '50', ariaValuemax: '100' }} />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </React.Fragment>
                    }
                </div>
            </div>
        )
    }

    renderTests() {
        const { D, I, S, C } = this.state.data.testsProfiles || 0
        const amt = D + I + S + C
        const dPercent = (D * 100 / amt || 0).toFixed(0) + '%' || 0
        const iPercent = (I * 100 / amt || 0).toFixed(0) + '%' || 0
        const sPercent = (S * 100 / amt || 0).toFixed(0) + '%' || 0
        const cPercent = (C * 100 / amt || 0).toFixed(0) + '%' || 0

        return (
            <div className="card h-100 bg-c-blue order-card">
                <div className="card-block pb-0 px-3 pt-3 ">
                    <div className="row">
                        <div className="col-12">
                            <h6 className="card-title mb-0">Perfis Disc</h6>
                        </div>
                    </div>
                    {
                        this.state.showLoadIcon ?
                            this.loadIcon()
                            :
                            <React.Fragment>

                                <div className="row">
                                    <div className="col-12">
                                        <div className="row text-center">
                                            <div className="col-3 px-1">
                                                <div className="card-value">
                                                    <span className="">{D || 0}</span>
                                                    <label className="card-value-percent">|{dPercent || 0}</label>
                                                </div>
                                                <span className="card-value-percent badge rounded badge-danger">Dominante</span>
                                            </div>

                                            <div className="col-3 px-1">
                                                <div className="card-value">
                                                    <span className="">{I || 0}</span>
                                                    <label className="card-value-percent">|{iPercent || 0}</label>
                                                </div>
                                                <span className="card-value-percent badge rounded badge-primary text-white">Influente</span>
                                            </div>

                                            <div className="col-3 px-1">
                                                <div className="card-value">
                                                    <span className="">{S || 0}</span>
                                                    <label className="card-value-percent">|{sPercent || 0}</label>
                                                </div>
                                                <span className="card-value-percent badge rounded badge-warning text-light ">Estável</span>
                                            </div>

                                            <div className="col-3 px-1">
                                                <div className="card-value">
                                                    <span className="">{C || 0}</span>
                                                    <label className="card-value-percent">|{cPercent || 0}</label>
                                                </div>
                                                <span className="card-value-percent badge rounded badge-success ">Conforme</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-12">
                                        <div className="bg-light rounded p-2">
                                            <div className="progress m-auto" style={{ height: '14px' }}>
                                                <div className="progress-bar bg-danger" role="progressbar" style={{ width: dPercent || 0, ariaValuenow: "25", ariaValuemin: '50', ariaValuemax: '100' }} />
                                                <div className="progress-bar bg-primary" role="progressbar" style={{ width: iPercent || 0, ariaValuenow: "50", ariaValuemin: '50', ariaValuemax: '100' }} />
                                                <div className="progress-bar bg-warning" role="progressbar" style={{ width: sPercent || 0, ariaValuenow: "25", ariaValuemin: '50', ariaValuemax: '100' }} />
                                                <div className="progress-bar bg-success" role="progressbar" style={{ width: cPercent || 0, ariaValuenow: "25", ariaValuemin: '50', ariaValuemax: '100' }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                    }
                </div>
            </div>
        )
    }

    render() {
        return (
            <Content>
                <div id="Home">
                    <div id="cardHome">
                        <div className="container-fluid">

                            <div className="row my-2">
                                <div className="col-sm-12 col-md-6 col-xl-6 mb-2 mb-md-0">
                                    {this.renderCardStatus()}
                                </div>

                                <div className="col-sm-12 col-md-6 col-xl-6">
                                    {this.renderCardRecents()}
                                </div>
                            </div>

                            <div className="row my-2">
                                <div className="col-12">
                                    {this.renderMenu()}
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 col-md-6 my-2">
                                    {this.renderTests()}
                                </div>

                                <div className="col-sm-12 col-md-6 my-2">
                                    {this.renderBalance()}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Content >
        )
    }
}