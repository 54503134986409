import React, { Component } from 'react'
import './Token.css'
import { Link } from 'react-router-dom'
import Index from '../template/jsx/Index'
import { testByToken } from '../tools/utils'
import Alert from '../tools/Alert'

const InitialState = {
    test: {
        name: '',
        idTest: '',
        token: '',
        email: '',
        status: '',
    },
    alert: {
        class: 'alert-warning',
        msg: null,
        onClick: null,
        visible: alert.msg ? '' : 'd-none',
    },
    loading: '',
}

export default class Token extends Component {

    state = { ...InitialState }

    componentDidMount() {
        const token = JSON.parse(localStorage.getItem('@disc-app/signin'))
        if (token) {
            localStorage.removeItem('@disc-app/signin')
            window.location.reload()
        }
    }

    updateField(event) {
        const test = { ...this.state.test }
        test[event.target.id] = event.target.value
        this.setState({ test })
    }

    async login(event) {
        //Stop no envio do formulário
        event.preventDefault()
        let tests = { ...this.state.test }

        if (!tests.token) {
            this.showAlert(true, 'informe um token', 'alert-warning')
            return
        }

        this.setState({ loading: true })
        await testByToken(tests)
            .then(suc => {
                this.setState({ loading: false })
                if (suc.process) {
                    const test = JSON.stringify(suc.test)
                    this.showAlert(true, suc.msg || 'Logado', suc.alert)
                    sessionStorage.setItem('@disc-app/test', test)
                    this.props.history.push({ pathname: "/Welcome" })
                } else {
                    this.showAlert(true, suc.msg || 'Erro inesperado', suc.alert)
                }
            })
            .catch(err => {
                this.setState({ loading: false })
                console.log(err)
            })
    }

    showAlert(show, msg, color) {
        const alert = this.state.alert
        if (show === true) {
            alert.msg = msg
            alert.class = color
            this.setState({ alert })
        } else {
            alert.msg = null
            this.setState({ alert })
        }
    }

    renderForms() {
        return (
            <React.Fragment>
                <form className="login-form mt-4">
                    <div className="form-group">
                        {/*<label htmlFor="exampleInputEmail1" className="text-uppercase">Token</label>*/}
                        <input type="text" className="form-control"
                            placeholder="Informe o token."
                            id="token"
                            value={this.state.test.token || ''}
                            onChange={e => this.updateField(e)}
                        />
                    </div>
                    {
                        this.state.loading ?
                            <div className="text-center">
                                <i className="fas fa-circle-notch fa-spin fa-3x text-CubohColor1" />
                            </div>
                            :
                            <div className="text-center">
                                <button type="submit" className="col-12 btn btn-login" onClick={e => this.login(e)}>
                                    Acessar
                                </button>
                            </div>
                    }
                </form>

                <Link to={`/Login`}>
                    <button className="btn-block btn btn-outline-info mt-5 ButtonsOfLogin">Entrar com E-mail e Senha</button>
                </Link>

            </React.Fragment>
        )
    }

    render() {
        return (
            <Index>
                <Alert
                    msg={this.state.alert.msg}
                    class={this.state.alert.class}
                    onClick={e => this.showAlert(false)}
                    visible={this.state.alert.msg ? '' : 'd-none'}
                />
                {this.renderForms()}
            </Index>
        )
    }
}