import React, { Component } from 'react'
import Content from '../template/jsx/Content'
import API from '../../utils/API'
import { getStatus, formatDate } from '../tools/utils'
import Alert from '../tools/Alert'
import './TestsCrud.css'
import Loading from '../tools/Loading'
import ModalOne from '../tools/ModalOne'
import { ifError } from '../tools/utils'
import { Link } from 'react-router-dom'

/*import axios from 'axios'
import './Tests.css'
const fs = require('browserify-fs')*/

const headerProsps = {
    icon: 'question',
    title: ' Tests',
    subtitle: 'Insira ou remova o teste a ser aplicado pelo usuário.'
}

const initialState = {
    test: {
        id: '',
        name: '',
        email: '',
        note: '',
        status: '',
        token: '',
        sendDate: '',
        createdAt: '',
        userId: '',
        startedDate: null,
        finishedDate: null
    },
    alert: {
        class: 'alert-warning',
        msg: null,
        onClick: null,
        visible: alert.msg ? '' : 'd-none',
    },
    alertBox: {
        title: '',
        subtitle: '',
        subtitle2: '',
        titleButton1: '',
        titleButton2: '',
        onClick: null,
        value: '',
    },
    loading: {
        class: 'd-none',
        msg: 'loading...'
    },
    list: [],
    credits: {},
}

export default class Tests extends Component {

    state = { ...initialState }
    async componentDidMount() {
        if (this.props) this.showAlert(false)
        if (this.props.match.params.id) this.showAlert(false)
        if (this.props.location.msg) this.showAlert(true, this.props.location.msg || 'Salvo com Sucesso', this.props.location.alert || 'alert-warning')
        if (this.props.match.params.id) {
            await this.getTests(this.props.match.params.id)
        }
        if (!this.state.test.id) this.getBalance()
    }

    async getTests(id) {
        let test = { ...this.state.test }

        await API.get(`/tests/${id}`)
            .then(resp => {
                test = {
                    id: resp.data.id,
                    name: resp.data.name,
                    email: resp.data.email,
                    note: resp.data.note,
                    status: getStatus(resp.data.status),
                    token: resp.data.token,
                    sendDate: formatDate(resp.data.sendDate),
                    startedDate: formatDate(resp.data.startedDate),
                    finishedDate: formatDate(resp.data.finishedDate),
                    createdAt: formatDate(resp.data.createdAt)
                }
                this.setState({ test })
            })
            .catch(err => console.log(err.response))
        return test
    }

    async getBalance() {
        await API.get(`/userCredit`)
            .then(suc => this.setState({ credits: suc.data }))
            .catch(err => {
                console.log(err)
                this.showAlert(true, 'Credito não identificado, contacte o administrador', 'alert-warning')
            })
    }

    updateField(event) {
        const test = { ...this.state.test }
        test[event.target.id] = event.target.value
        this.setState({ test })
    }

    clear() {
        this.setState({ test: initialState.test })
    }

    async save(e) {

        //Stop no envio do formulário
        e.preventDefault()

        //validate required fields 
        if (!this.validFields()) return

        const test = { ...this.state.test }
        const url = test.id ? `/tests/${test.id}` : '/tests'
        const method = test.id ? 'put' : 'post'

        this.loading(true, 'Salvando Teste...')
        await API[method](url, test)
            .then(async resp => {

                if (method === 'post') {

                    test.id = resp.data.id
                    test.token = resp.data.token
                    const ret = await this.sendEmailTest(test)

                    if (ret.status === 200) {//Email Enviado com Sucesso
                        this.props.history.push({ pathname: `/testsEdit/${resp.data.id}`, msg: 'Teste salvo, enviamos também um e-mail com token para você e o avaliado', alert: 'alert-success' })
                    } else {//Falha ao enviar o E-mail
                        this.props.history.push({ pathname: `/testsEdit/${resp.data.id}`, msg: ret.response.data.msg || `Teste salvo, Mas houve um problema no momento de enviar o e-mail, contacte o adminstrador.`, alert: 'alert-warning' })
                    }

                } else {
                    await this.getTests(this.state.test.id)
                    this.showAlert(true, resp.data.msg || 'Salvo com Sucesso.', resp.data.alert || 'alert-warning')
                    this.loading(false)
                }
            })
            .catch(err => {
                const backWs = ifError(err)
                this.showAlert(true, backWs || 'Erro inesperado', 'alert-warning')
                this.loading(false)
            })
    }

    validFields() {
        const test = { ...this.state.test }
        let ret = true

        if (!test.email) { //Email campo Obrigatório
            this.showAlert(true, 'E-mail não informado.', 'alert-danger')
            ret = false
        } else if (!test.name) { //Nome Obrigatório
            this.showAlert(true, 'Nome não informado.', 'alert-danger')
            ret = false
        }

        return ret
    }

    loading(show, msg) {
        const loading = this.state.loading
        if (show === true) {
            loading.msg = msg
            loading.class = ''
            this.setState({ loading })
        } else {
            loading.class = 'd-none'
            this.setState({ loading })
        }
    }

    showAlert(show, msg, color) {
        const alert = this.state.alert
        if (show === true) {
            alert.msg = msg
            alert.class = color
            this.setState({ alert })
        } else {
            alert.msg = null
            this.setState({ alert })
        }
    }

    async cancelTest(e) {
        e.preventDefault()

        await API.put(`cancelTest/${this.state.test.id}`)
            .then(async test => {
                await this.getTests(this.state.test.id)
                this.showAlert(true, test.data.msg || 'Erro inesperado', test.data.alert || 'alert-danger')
            })
            .catch(err => {
                console.log(err)
                const msg = ifError(err)
                this.showAlert(true, msg || 'Erro inesperado', 'alert-danger')
            })
    }

    async deleteTest(e) {

        await API.delete(`tests/${this.state.test.id}`)
            .then(test => {
                this.props.history.push({ pathname: "/testsList", msg: `Teste com nome ${this.state.test.name} excluído com sucesso!.`, alert: 'alert-success' })
            })
            .catch(err => {
                console.log(err)
                const msg = ifError(err)
                this.showAlert(true, msg || 'Erro inesperado', 'alert-danger')
            })
    }

    deleteTestBox(e) {
        e.preventDefault()

        const alertBox = {
            title: 'Exclusão de teste',
            subtitle: `Deseja mesmo excluir o teste de ${this.state.test.name} ?`,
            subtitle2: 'Caso essa ação seja confirmada, o teste nunca mais aparecerá na sua lista. Você pode usar a opção "Cancelar teste" que apenas altera o status para cancelado (continuará aparecendo na sua lista, porém, não fará parte do seu dashboard).',
            titleButton1: 'Excluir',
            titleButton2: 'Voltar',
            onClick: e => this.deleteTest(e),
            value: `${parseInt(this.state.test.id)}`,
        }
        this.setState({ alertBox })
    }

    async sendEmailTest(test, e) {
        if (e !== undefined) e.preventDefault()

        let ret

        const userLoged = JSON.parse(localStorage.getItem('@disc-app/signin'))
        if (!userLoged) window.location.replace('/login')

        const objEmail = {
            evaluatorEmail: userLoged.email,
            evaluatorName: userLoged.name,
            id: test.id,
            token: test.token,
            ratedName: test.name,
            ratedEmail: test.email
        }
        
        this.loading(true, 'enviando email...')

        await API.post('/sendEmailTest', objEmail)
            .then(suc => {
                this.showAlert(true, suc.data.msg || 'O Email foi enviado com sucesso.', 'alert-success')
                ret = suc
            })
            .catch(err => {
                ret = err
                this.showAlert(true, ret.response.data.msg || 'Erro inesperado, contacte o administrador.', ret.response.data.alert || 'alert-danger')
            })

        this.loading(false)
        return ret
    }

    renderForm() {

        return (
            <form className={`${(this.state.credits.balanceCurrent - this.state.credits.balanceUsed) <= 0 ? 'd-none' : ''}  `}>
                <div className='form container-fluid'>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                            <div className="form-group">
                                <label>Nome:</label>
                                <input type="text" className="form-control" id="name"
                                    disabled={!!this.state.test.finishedDate === true || this.state.test.status === "Cancelado" ? true : ''}
                                    placeholder="Maria da Silva"
                                    value={this.state.test.name || ''}
                                    onChange={e => this.updateField(e)}
                                />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                            <div className="form-group">
                                <label>E-mail:</label>
                                <input type="email" className="form-control"
                                    disabled={!!this.state.test.finishedDate === true || this.state.test.status === "Cancelado" || this.state.test.id ? true : ''}
                                    id="email" aria-describedby="emailHelp"
                                    placeholder="maria.silva@exemplo.com.br"
                                    value={this.state.test.email || ''}
                                    onChange={e => this.updateField(e)}
                                />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 ">
                            <div className="form-group">
                                <label>Token de acesso:</label>
                                <input type="text" className="form-control disabled" disabled
                                    placeholder="Gerado automaticamente"
                                    value={this.state.test.token || ''}
                                />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 ">
                            <div className="form-group">
                                <label>Status:</label>
                                <input type="text" className="form-control" disabled
                                    placeholder="Status"
                                    value={this.state.test.status || ''} />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 ">
                            <div className="form-group">
                                <label>Data de envio:</label>
                                <input type="text" className="form-control" disabled
                                    id="sendDate"
                                    placeholder="Não enviado"
                                    value={this.state.test.sendDate || ''}
                                />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 ">
                            <div className="form-group">
                                <label>Criado em:</label>
                                <input type="text" className="form-control" disabled
                                    id="createdAt"
                                    placeholder="Gerado Automaticamente"
                                    value={this.state.test.createdAt || ''} />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 ">
                            <div className="form-group">
                                <label>Iniciado em:</label>
                                <input type="text" className="form-control" disabled
                                    id="startedDate"
                                    placeholder="Não iniciado"
                                    value={this.state.test.startedDate || ''}
                                />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 ">
                            <div className="form-group">
                                <label>Finalizado em:</label>
                                <input type="text" className="form-control" disabled
                                    id="finishedDate"
                                    placeholder="Não finalizado"
                                    value={this.state.test.finishedDate || ''}
                                />
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="form-group">
                                <label>Anotações:</label>
                                <textarea
                                    className="form-control" rows="4" maxLength="250"
                                    id="note"
                                    placeholder="Área para salvar rascunhos ou anotações gerais"
                                    value={this.state.test.note || ''}
                                    onChange={e => this.updateField(e)} />
                            </div>
                        </div>

                        <div className="col-12 d-flex justify-content-end">
                            <Link to='/testsList'>
                                <button type="submit" className="btn btn-login mr-2">
                                    <i className="fas fa-arrow-left" /> Voltar
                                </button>
                            </Link>

                            <div className="btn-group" role="group">
                                <button id="btnGroupDrop1" type="button" className="btn btn-login dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"
                                    disabled={!!this.state.test.finishedDate === true ? true : ''}>
                                    <i className="fas fa-cog" /> Opções
                                    </button>
                                <div className="dropdown-menu" aria-labelledby="btnGroupDrop1">


                                    <button className="dropdown-item"
                                        onClick={e => this.sendEmailTest(this.state.test, e)}
                                        disabled={!!this.state.test.finishedDate === true || this.state.test.status === "Cancelado" ? true : ''}>
                                        <i className="fas fa-envelope  mr-2" /><b>Reenviar Email</b>
                                    </button>
                                    <div className="dropdown-divider" />
                                    <button className="dropdown-item"
                                        onClick={e => this.cancelTest(e)}
                                        disabled={!!this.state.test.finishedDate === true || this.state.test.status === "Cancelado" ? true : ''}>
                                        <i className="fas fa-ban mr-2" /><b>Cancelar Teste</b>
                                    </button>
                                    <div className="dropdown-divider" />
                                    <button className="dropdown-item"
                                        onClick={e => this.clear(e)}
                                        disabled={!!this.state.test.finishedDate === true || this.state.test.status === "Cancelado" ? true : ''}>
                                        <i className="fas fa-eraser mr-2" /><b>Limpar Formulário</b>
                                    </button>
                                    <div className="dropdown-divider" />
                                    <button className="dropdown-item text-danger"
                                        value={this.state.test.id} data-toggle="modal" data-target="#exampleModal"
                                        onClick={e => this.deleteTestBox(e)}
                                        disabled={!!this.state.test.finishedDate === true ? true : ''}>
                                        <i className="fas fa-trash mr-2 " /><b>Excluir Teste</b>
                                    </button>

                                </div>

                            </div>
                            <button type="submit" className="btn btn-login ml-2"
                                onClick={e => this.save(e)}>
                                <i className="fas fa-save" /> Salvar
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        )
    }

    noCredits() {
        const name = JSON.parse(localStorage.getItem('@disc-app/signin')).name

        return (
            <div className="row  mt-5">
                <div className="col-1"></div>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 ">
                    <div className="d-flex justify-content-center"><i className="far btn-outline-info fa-frown fa-10x" /></div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-3 text-center ">
                    <h2 className="section-title btn-outline-info font-weight-bold">{`Vixiiii, ${name}.`}</h2>
                    <p className="text-muted h5">Me parece que você não possui saldo suficiente.</p>
                    <p className="text-muted h5">Entre em contato com nosso time ;D</p>
                    <p className="text-muted h5"></p>
                    <Link to="/Contact">
                        <button className="btn btn-login">Contactar agora!</button>
                    </Link>
                </div>
                <div className="col-1"></div>
            </div>
        )
    }

    ifCredits() {
        if (this.state.credits) {
            if (this.state.credits.balanceCurrent - this.state.credits.balanceUsed <= 0) {
                return this.noCredits()
            } else {
                return this.renderForm()
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <Content {...headerProsps}>
                    <Alert
                        msg={this.state.alert.msg}
                        class={this.state.alert.class}
                        onClick={e => this.showAlert(false)}
                        visible={this.state.alert.msg ? ' ' : 'd-none'}
                    />
                    {this.ifCredits()}
                    <Loading {...this.state.loading} />
                    <ModalOne
                        titleButton1={this.state.alertBox.titleButton1}
                        titleButton2={this.state.alertBox.titleButton2}
                        subtitle={this.state.alertBox.subtitle}
                        subtitle2={this.state.alertBox.subtitle2}
                        onClick={this.state.alertBox.onClick}
                        title={this.state.alertBox.title}
                        value={this.state.alertBox.value}
                    />
                </Content>
            </React.Fragment>
        )
    }
}