import React, { Component } from 'react'
import Content from '../template/jsx/Content'
import avatarDefault from '../../assets/imgs/avatar/default.png'
import API from '../../utils/API'
import $ from 'jquery'
import './UserCrud.css'
import Alert from '../tools/Alert'
import Loading from '../tools/Loading'
import phoneMask from '../../utils/phoneMask'

//const fs = require('browserify-fs')

const headerProsps = {
    icon: 'users',
    title: ' Users',
    subtitle: 'Crud = Creat, Read, Update, Delete'
}

const initialState = {
    user: {
        id: null,
        name: '',
        email: '',
        confirmEmail: '',
        company: '',
        phone: '',
        birthday: '',
        password: '',
        confirmPassword: '',
        admin: false,
        status: false,
        imageUrl: '',
    },
    sendEmail: false,
    imageFile: null,
    imageUpdate: null,
    loading: {
        class: 'd-none',
        msg: 'loading...'
    },
    alert: {
        class: 'alert-warning',
        msg: null,
        onClick: null,
        visible: alert.msg ? '' : 'd-none',
    },
    list: [],
    signin: ''
}

export default class UserCrud extends Component {

    state = { ...initialState }

    async componentDidMount() {

        if (this.props.match.params.id) {
            this.getUser(this.props.match.params.id)
        } else {
            await this.setState({ sendEmail: true })
        }

        this.getSignin()
        //console.log(this.state.user)
    }

    getSignin() {
        if (localStorage.getItem("@disc-app/signin") !== null) {
            this.setState({ signin: JSON.parse(localStorage.getItem('@disc-app/signin')) })
        } else {
            window.location.replace('/Login')
        }
    }

    async getUser(id) {
        let user = { ...this.state.user }

        await API.get(`/users/${id}`)
            .then(resp => {
                //console.log(resp.data)
                user = {
                    id: resp.data.id,
                    name: resp.data.name,
                    email: resp.data.email,
                    confirmEmail: resp.data.email,
                    company: resp.data.company,
                    phone: resp.data.phone,
                    birthday: resp.data.birthday,
                    password: '',
                    confirmPassword: '',
                    admin: (!!resp.data.admin),
                    status: (!!resp.data.status),
                    imageUrl: resp.data.imageUrl
                }
                this.setState({ user })
            })
            .catch(err => {
                if (err.response.status) this.props.history.push({ pathname: "/" })
                console.log(err.response)
            })

        if (user.status) $('#putStatus').attr('checked', '')
        if (user.admin) $('#putAdmin').attr('checked', '')

        return user
    }

    cancel() {
        this.props.history.push({ pathname: "/usersList" })
    }

    onUploadProgress(ProgressEvent, txtLoading, txtFinished) {
        const percent = Math.round(ProgressEvent.loaded / ProgressEvent.total * 100)
        if (percent < 100) {
            this.loading(true, `${txtLoading} ${percent}%`)
        } else {
            this.loading(true, `${txtFinished}`)
        }
    }

    async save(e) {

        const user = this.state.user
        const url = user.id ? `/users/${user.id}` : '/users'
        const method = user.id ? 'put' : 'post'

        //Senão for informado senha, eu deleto pois o backend não precisa desse atributo
        if (user.id && user.password === '') {
            delete user.password
            delete user.confirmPassword
        }

        this.showAlert(false)
        await API[method](url, user, { onUploadProgress: event => this.onUploadProgress(event, 'Enviado dados...', 'Processando...') })
            .then(async resp => {
                this.loading(false)
                //Salvando o Avatar caso exista
                if (this.state.imageFile) {
                    await API.post(`/userAvatar/${user.id ? user.id : resp.data.id}`, this.state.imageFile, { onUploadProgress: event => this.onUploadProgress(event, 'Enviado imagem...', 'Finalizando...') })
                        .then(async respo => {
                            this.loading(false)
                            //await this.getUser(user.id ? user.id : resp.data.id)
                            this.setState({ user })
                            this.showAlert(true, respo.data.msg || 'Alteração salva.', 'alert-success')
                        })
                        .catch(err => {
                            this.loading(false)
                            console.log(err)
                            this.showAlert(true, resp.data.msg || 'Alteração salva, erro ao salvar foto, verifique o arquivo e tente novamente.', 'alert-warning')
                        })
                } else {
                    this.showAlert(true, resp.data.msg || 'Alteração salva.', 'alert-success')
                }

                //Enviando E-mail com password
                if (this.state.sendEmail) {

                    //testando se tem senha antes de enviar
                    if (!user.password) {
                        this.showAlert(true, 'Não foi possivel enviar senha ao usuário, a mesma não foi informada.', 'alert-warning')
                        return
                    }

                    const { name: ratedName, email: ratedEmail, password: ratedPassword } = user
                    this.loading(true, 'Enviando email...')
                    await API.post('/sendEmailPassword', { ratedName, ratedEmail, ratedPassword })
                        .then(resp => {
                            this.loading(false)
                            this.showAlert(true, resp.data.msg || 'Salvo e foi enviado um e-mail com os dados de acesso ao usuário ', 'alert-success')
                        })
                        .catch(err => {
                            this.loading(false)
                            console.log('Erro! ', err)
                        })
                }

                //Se o usuário for o mesmo que está logado atualiza as informações no NAV e Session
                if (this.state.signin.id === resp.data.id) {
                    const signin = this.state.signin
                    const user = this.state.user

                    signin.admin = user.admin
                    signin.avatar = user.imageUrl
                    signin.company = user.company
                    signin.email = user.email
                    signin.name = user.name

                    localStorage.setItem('@disc-app/signin', JSON.stringify(signin))
                    document.location.reload(true);
                }

                if (!user.id) this.getUser(resp.data.id)
            })
            .catch(err => {
                this.loading(false)
                let backWs
                if (err.response && err.response.data && err.response.data.msg != null) {
                    backWs = err.response.data.msg
                } else {
                    backWs = 'erro inesperado'
                }
                this.showAlert(true, backWs || 'erro inesperado', 'alert-danger')
            })
    }

    loading(show, msg) {
        const loading = this.state.loading
        if (show === true) {
            loading.msg = msg
            loading.class = ''
            this.setState({ loading })
        } else {
            loading.class = 'd-none'
            this.setState({ loading })
        }
    }

    showAlert(show, msg, color) {
        const alert = this.state.alert
        if (show === true) {
            alert.msg = msg
            alert.class = color
            this.setState({ alert })
        } else {
            alert.msg = null
            this.setState({ alert })
        }
    }

    fileSelected(e) {
        const image = e.target.files[0]
        const typeImage = ['image/jpeg', 'image/png', 'image/jpg']
        const maxByte = 1024 * 1024 * 2 //Máximo de 2mb
        const imageUpdate = URL.createObjectURL(image)

        //Check pra saber se a imagem é valida        
        if (!typeImage.includes(image.type)) {
            this.showAlert(true, 'Tipo de imagem não permitido.(permitidos: jpeg/jpg/png)', 'alert-danger')
            return
        } else if (image.size > maxByte) {
            this.showAlert(true, 'Tamanho da imagem não permitido.(máximo: 2mb)', 'alert-danger')
            return
        }
        else {
            //Salvando a imagem para ser feito o Upload
            const fd = new FormData()
            fd.append('imageFile', image, image.name)
            this.setState({
                imageFile: fd,
                imageUpdate
            })

        }
    }

    clearImage(e) {
        this.setState({
            imageFile: null,
            imageUpdate: null
        })
    }

    updateField(event) {
        const user = { ...this.state.user }

        if (event.target.name === "admin" || event.target.name === "status") {
            user[event.target.name] = (!user[event.target.name])
        } else {
            user[event.target.name] = event.target.value
        }

        this.setState({ user })
        //console.log(user.status, user.admin)
    }

    renderForm() {

        return (
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-3">
                    <div className="d-flex justify-content-center">
                        <img id="crudAvatarPefil" src={this.state.imageUpdate ? this.state.imageUpdate : this.state.user.imageUrl ? `${process.env.REACT_APP_API_URL}/files/${this.state.user.imageUrl}` : avatarDefault} className="img-fluid" alt="avatar Perfil" />
                    </div>
                    <div className="m-2">
                        <div className="d-flex justify-content-center">
                            <label htmlFor="clearImg" className="btn btn-danger" type="button"><i className="fas fa-trash fa-lg" /></label>
                            <input name="imageUrl" onChange={e => this.clearImage(e)} id="clearImg" className="btn btn-danger" type="checkbox" />
                            <label htmlFor="inputGroupFile03" className="btn btn-primary ml-2" type="button"><i className="fas fa-camera fa-lg" /></label>
                            <input name="imageUrl" onChange={e => this.fileSelected(e)} id="inputGroupFile03" type="file" />
                        </div>
                    </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-9">
                    <div className="form">
                        <div className="row">

                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Nome:</label>
                                    <input type="text" className="form-control" name="name"
                                        value={this.state.user.name}
                                        onChange={e => this.updateField(e)}
                                        placeholder="Ex. Maria Silva...">
                                    </input>
                                </div>
                            </div>

                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Empresa:</label>
                                    <input type="text" className="form-control" name="company"
                                        value={this.state.user.company}
                                        onChange={e => this.updateField(e)}
                                        placeholder="Ex. Pulsar RH LTDA...">
                                    </input>
                                </div>
                            </div>

                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Telefone:</label>
                                    <input type="tel" className="form-control"
                                        name="phone"
                                        placeholder="(DDD) 9.0000-0000"
                                        onKeyUp={e => phoneMask(e)}
                                        value={this.state.user.phone || ''}
                                        onChange={e => this.updateField(e)}>
                                    </input>
                                </div>
                            </div>

                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Data de Nascimento:</label>
                                    <input type="date" className="form-control" name="birthday"
                                        value={this.state.user.birthday || ''}
                                        onChange={e => this.updateField(e)}
                                        placeholder="DD/MM/AAAA">
                                    </input>
                                </div>
                            </div>

                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>E-mail:</label>
                                    <input type="email" className="form-control"
                                        aria-describedby="emailHelp"
                                        name="email"
                                        value={this.state.user.email}
                                        onChange={e => this.updateField(e)}
                                        placeholder="Ex. exemplo@email.com">
                                    </input>
                                </div>
                            </div>

                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Confirme o E-mail:</label>
                                    <input type="email" className="form-control"
                                        name="confirmEmail"
                                        value={this.state.user.confirmEmail}
                                        onChange={e => this.updateField(e)}
                                        placeholder="Ex. exemplo@email.com">
                                    </input>
                                </div>
                            </div>

                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Senha:</label>
                                    <input type="password" className="form-control"
                                        name="password"
                                        onChange={e => this.updateField(e)}
                                        placeholder="**********">
                                    </input>
                                </div>
                            </div>

                            <div className="col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Confirme a senha:</label>
                                    <input type="password" className="form-control"
                                        name="confirmPassword"
                                        onChange={e => this.updateField(e)}
                                        placeholder="**********">
                                    </input>
                                </div>
                            </div>

                            {
                                this.state.signin.admin
                                    ?
                                    <div className="col-12">
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-4 col-sm-12">
                                                    <div className="custom-control custom-switch">
                                                        <input name="status" type="checkbox" className="custom-control-input" id="putStatus" onClick={e => this.updateField(e)} />
                                                        <label className="custom-control-label" htmlFor="putStatus">Usuário bloqueado.</label>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-12">
                                                    <div className="custom-control custom-switch">
                                                        <input name="admin" type="checkbox" className="custom-control-input" id="putAdmin" onClick={e => this.updateField(e)} />
                                                        <label className="custom-control-label" htmlFor="putAdmin">Usuário admin.</label>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 col-sm-12">
                                                    <div className="custom-control custom-switch">
                                                        <input name="sendEmail" type="checkbox" className="custom-control-input" id="putSendEmail" checked={this.state.sendEmail ? true : ''} onChange={e => this.setState({ sendEmail: !this.state.sendEmail })} />
                                                        <label className="custom-control-label" htmlFor="putSendEmail">Enviar com senha.</label>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    : ''
                            }

                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-12 d-flex justify-content-end">
                                <button className="btn btn-success ml-2" type="submit"
                                    onClick={e => this.save(e)}>
                                    Salvar
                                </button>
                                <button className="btn btn-secondary ml-2"
                                    onClick={e => this.cancel(e)}>
                                    Cancelar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    load(user) {
        this.setState({ user })
    }

    render() {
        return (
            <React.Fragment>
                <Content {...headerProsps}>
                    <Loading {...this.state.loading} />
                    <Alert
                        msg={this.state.alert.msg}
                        class={this.state.alert.class}
                        onClick={e => this.showAlert(false)}
                        visible={this.state.alert.msg ? '' : 'd-none'}
                    />
                    {this.renderForm()}
                </Content>
            </React.Fragment>
        )
    }
}