import React, { Component } from 'react'
import './CreditsList.css'
import Pagination from "react-js-pagination"
import { formatDate } from '../tools/utils'
import Content from '../template/jsx/Content'
import API from '../../utils/API'
import Alert from '../tools/Alert'
import LoadingIconList from '../tools/LoadingIconList'


const InitialState = {
    pagination: {
        activePage: 1,
        limit: 10,
        count: '',
        list: [],
        creditsPage: []
    },
    credit: {
        balanceUsed: 0,
        balanceCurrent: 0,
    },
    loadingIconList: false,
    alert: {
        class: 'alert-warning',
        msg: null,
        onClick: null,
        visible: alert.msg ? '' : 'd-none',
    },
}

export default class CreditsUser extends Component {

    state = { ...InitialState }

    async componentDidMount() {

        const user = JSON.parse(localStorage.getItem('@disc-app/signin'))

        if (this.props.location.msg) this.showAlert(true, this.props.location.msg || 'Salvo com Sucesso', this.props.location.alert || 'alert-warning')
        const pagination = { ...this.state.pagination }

        await API.get(`/getListByUser/${user.id}`)
            .then(resp => {
                if (resp.data.data.length) {
                    this.setState({
                        pagination: {
                            ...pagination, list: resp.data.data, count: resp.data.count,
                        }
                    })
                } else {
                    this.setState({ loadingIconList: true })
                }
            })
            .catch(err => {
                console.log(err)
                this.showAlert(true, 'Ocorreu algo inesperado ao tentar listar os créditos, contacte o administrador.', 'alert-warning')
                this.setState({ loadingIconList: true })
            })

        this.selectLastCredits()
        this.pagination(this.state.pagination.activePage)

    }

    pagination(activePage) {

        const pagination = { ...this.state.pagination }
        const minListCredits = activePage * pagination.limit - pagination.limit
        const credits = pagination.list.slice(minListCredits, minListCredits + pagination.limit)

        pagination.activePage = activePage
        pagination.creditsPage = credits

        this.setState({ pagination })
    }

    selectLastCredits() {
        const creditsArray = this.state.pagination.list
        const credit = creditsArray.find(credit => credit.status === true) || {}
        this.setState({ credit })
    }

    showAlert(show, msg, color) {
        const alert = this.state.alert
        if (show === true) {
            alert.msg = msg
            alert.class = color ? color : 'alert-warning'
            this.setState({ alert })
        } else {
            alert.msg = null
            this.setState({ alert })
        }
    }

    renderBarProgress() {

        return (
            <React.Fragment>
                <div className="border border-primary rounded-lg p-3 mb-2">
                    <h4 className="h4 text-CubohColor2">Saldo de disc</h4>
                    <p className="text-muted">{`Utilizado ${this.state.credit.balanceUsed || '00'} de ${this.state.credit.balanceCurrent || '00'}`}.</p>
                    <div className="progress bg-white">
                        <div className="progress-bar" role="progressbar" style={{ width: `${(this.state.credit.balanceUsed / this.state.credit.balanceCurrent) * 100 || 2}%` }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"><b>{((this.state.credit.balanceUsed / this.state.credit.balanceCurrent) * 100).toFixed(0) || 0}%</b></div>
                    </div>
                </div>

            </React.Fragment>
        )
    }

    renderTable() {
        return (
            <React.Fragment>
                <div className="pt-2 mb-1">
                    <h4 className="h4 text-CubohColor2 text-center">Histórico </h4>
                </div>

                <div className="table-responsive-sm">
                    <table className="table table-hover table-sm table-striped border">
                        <caption>{`${this.state.pagination.creditsPage.length} de ${this.state.pagination.list.length}`}</caption>
                        <thead>
                            <tr>
                                <th scope="col">Nº Ticket</th>
                                <th scope="col">Quantidade</th>
                                <th scope="col">Utilizado</th>
                                <th scope="col">Saldo</th>
                                <th scope="col">R$</th>
                                <th scope="col">Criado em</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderRows()}
                        </tbody>
                    </table>
                    <div>
                        <Pagination
                            activePage={this.state.pagination.activePage}
                            itemsCountPerPage={this.state.pagination.limit}
                            totalItemsCount={this.state.pagination.list.length}
                            innerClass={"pagination m-0 p-0 d-flex justify-content-end"}
                            prevPageText={<i className="fa fa-chevron-left" />}
                            firstPageText={<i className="fa fa-angle-double-left" />}
                            nextPageText={<i className="fa fa-chevron-right" />}
                            lastPageText={<i className="fa fa-angle-double-right" />}
                            itemClass={"page-item"}
                            linkClass={"page-link"}
                            disabledClass={""}
                            pageRangeDisplayed={4}
                            onChange={e => this.pagination(e)}
                        />
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderRows() {

        if (this.state.pagination.list.length) {

            return this.state.pagination.creditsPage.map(credits => {

                return (
                    <tr className={credits.status === true ? '' : "text-danger"} key={credits.id}>
                        <td>#{credits.typeSale}0{credits.id}{credits.status ? '' : '-Cancelado'}</td>
                        <td>{credits.quantity}</td>
                        <td>{credits.balanceUsed}</td>
                        <td>{credits.balanceCurrent - credits.balanceUsed}</td>
                        <td>R$ {credits.cashValue}</td>
                        <td>{formatDate(credits.createdAt)}</td>
                    </tr>
                )
            })
        } else {
            return (
                <LoadingIconList loadingIconList={this.state.loadingIconList} />
            )
        }
    }

    render() {
        return (
            <Content>
                <Alert
                    msg={this.state.alert.msg}
                    class={this.state.alert.class}
                    onClick={e => this.showAlert(false)}
                    visible={this.state.alert.msg ? '' : 'd-none'}
                />
                {this.renderBarProgress()}
                {this.renderTable()}
            </Content>
        )
    }
}