import React, { Component } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const notify = (type, msg) => toast[type](msg);

export default class Toastify extends Component {

    render() {
        return <ToastContainer
            position={this.props.position || "top-right"}
            autoClose={this.props.autoClose || 4000}
            hideProgressBar={this.props.hideProgressBar || false}
            newestOnTop={this.props.newestOnTop || false}
            closeOnClick={this.props.closeOnClick || false}
            rtl={this.props.rtl || false}
            pauseOnFocusLoss={this.props.pauseOnFocusLoss || false}
            draggable={this.props.draggable || true}
            pauseOnHover={this.props.pauseOnHover || true}
        />
    }
}