import React, { Component } from 'react'
import Content from '../template/jsx/Content'
import API from '../../utils/API'
import './FinalReport.css'
//graficos
import DiscResult from '../report/DiscResult'
import DiscProgressive from '../report/DiscProgressive'
import DiscAcumulate from '../report/DiscAcumulate'

const initialState = {
    result: {
        D: '',
        I: '',
        S: '',
        C: '',
    },
    profile: {
        profileType: '',
        title: '',
        resume: ''
    },
    cardColor: ['bg-danger', 'bg-primary', 'bg-warning', 'bg-success'],
}

export default class FinalReport extends Component {

    state = { ...initialState }

    async componentDidMount() {
        await this.getResult(this.props.match.params.id)
        await this.getProfile(this.props.match.params.id)
    }

    async getResult(id) {
        await API.get(`/resultDisc/${id}/natural`)
            .then(resp => this.setState({
                result: resp.data
            }))
    }

    async getProfile(id) {
        await API.get(`/profileBytest/${id}`)
            .then(resp => this.setState({
                profile: resp.data.profile
            }))
    }

    renderReport() {
        return (
            <React.Fragment>
                <div id="pdfpage" className="container_FinalReport">
                    <div className="row mb-2">
                        {this.renderProfile()}
                    </div>
                    <div className="row mb-5">
                        {this.renderCards()}
                    </div>

                    <div className="cardCanvas">
                        <div className="row pt-4 pb-1 ">
                            <div className="col-lg-6 col-md-12 d-flex justify-content-center ">
                                <DiscResult id={this.props.match.params.id} type='natural' />
                            </div>
                            <div className="col-lg-6 col-md-12 d-flex justify-content-center pt-2">
                                <div className="titleCanvas">
                                    Perfil natural
                                    <div className="subtitleCanvas pr-2 pl-2">
                                        A partir do resultado das respostas “MAIS SE IDENTIFICA” obtivemos o Gráfico DISC no Perfil Natural, que apresenta quem o (a) avaliado (a) é em sua “essência”, ou seja, reflete as experiências internas tendo como base a forma de pensar, sentir e agir. Quando muito diferente do Perfil Adaptado, podemos presumir que esses comportamentos estão sofrendo modificação por influência do ambiente, situação ou um determinado objetivo.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cardCanvas mt-2">
                        <div className="row pt-4 pb-1 ">
                            <div className="col-lg-6 col-md-12 d-flex justify-content-center ">
                                <div className="titleCanvas">
                                    Perfil adaptado
                                    <div className="subtitleCanvas pr-2 pl-2">
                                        O gráfico DISC Perfil Adaptado, pressupõe que o (a) avaliado (a) está adaptando seus comportamentos em prol de estímulos de uma determinada situação ou um determinado objetivo, quando a diferença é de 8 pontos ou mais fica mais perceptível essa adaptação ao meio e o indivíduo pode está passando por algum estresse ou conflito.
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-12 d-flex justify-content-center pt-2">
                                <DiscResult id={this.props.match.params.id} type='adaptado' />
                            </div>
                        </div>

                    </div>

                    <div className="cardCanvas mt-2">
                        <div className="row pt-4 pb-1 ">
                            <div className="col-lg-12 col-md-12 d-flex justify-content-center">
                                <DiscProgressive id={this.props.match.params.id} />
                            </div>

                            <div className="col-lg-12 col-md-12 d-flex justify-content-center pt-2">
                                <div className="titleCanvas">
                                    GAPRE progressivo
                                    <div className="subtitleCanvas pr-2 pl-2">
                                        Esse gráfico mostra pergunta a pergunta como foi a progressão de respostas do (a) avaliado (a). Caso exista muita alternância significa que ele (a) varia muito entre os perfis e talvez não tenha um conhecimento consolidado sobre si. É possível avaliar exatamente onde se mantém um padrão de respostas e onde tem muita variação, para uma análise mais consolidada você pode visitar a opção de ver as respostas individuais no primeiro ícone de ações aqui na página de resultados.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="cardCanvas mt-2">
                        <div className="row pt-4 pb-1 ">

                            <div className="col-lg-12 col-md-12 d-flex justify-content-center ">
                                <div className="titleCanvas">
                                    GAPRE acumulado
                                    <div className="subtitleCanvas pr-2 pl-2">
                                        Esse gráfico mostra o acumulado de respostas do (a) avaliado (a) a cada pergunta, sendo possível verificar a diferença entre os perfis predominantes, se estão próximos ou distantes, isto pode ser usado para se ter uma noção melhor da influência de um perfil sobre o outro.
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12 d-flex justify-content-center pt-2 mt-4">
                                <DiscAcumulate id={this.props.match.params.id} />
                            </div>
                        </div>

                    </div>

                </div>
            </React.Fragment>
        )
    }

    renderCards() {
        const arrayResult = Object.entries(this.state.result)
        const cardColor = this.state.cardColor

        return arrayResult.map((obj, ind) => {
            return (
                <div className="col-3" key={ind}>
                    <div className={`card text-white font-weight-bold text-center ${cardColor[ind]}`}>
                        <div className="card-header">{obj[0]}</div>
                        <div className="card-body p-1 m-1">
                            <div className="card-title text-bold p-0 m-0"><h4>{obj[1]}</h4></div>
                        </div>
                    </div>
                </div>
            )
        })
    }

    renderProfile() {

        return (
            <div className="col-lg-12 col-md-12 d-flex justify-content-center pt-2 mb-4">
                <div className="titleCanvas text-center">
                    {`${this.state.profile.profileType || ''} - ${this.state.profile.title || ''}`}
                    <div className="subtitleCanvas pr-2 pl-2  mt-3">
                        {this.state.profile.resume}
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <Content>
                {this.renderReport()}
            </Content>
        )
    }
}