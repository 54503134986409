import React, { Component } from 'react'
import './GroupsList.css'
import API from '../../utils/API'
import Pagination from "react-js-pagination"
import { Link } from 'react-router-dom'
import { formatDate } from '../tools/utils'
import Content from '../template/jsx/Content'
import Alert from '../tools/Alert'
import Loading from '../tools/Loading'
import LoadingIconList from '../tools/LoadingIconList'
import CreateGroups from '../groups/GroupsCrud'
import ModalForms from '../tools/ModalForms'
import { ifError } from '../tools/utils'
import $ from 'jquery'
import { ToastContainer, toast } from 'react-toastify';

const InitialState = {
    user: {
        id: '',
        name: '',
        company: '',
        email: '',
    },
    groups: {
        name: '',
        idParent: null,
        selectedName: '',
        status: true
    },
    groupsDelete: {
        name: '',
        idParent: null,
        selectedName: '',
        status: true
    },
    pagination: {
        activePage: 1,
        limit: 10,
        count: '',
        search: '',
        filter: [],
        list: [],
        testPage: [],
    },
    paramsEmail: {
        idTest: '',
        ratedName: '',
        ratedEmail: '',
        msg: 'Prezado(a), segue seu relatório DISC, espero que ele possa agregar e auxiliar no seu desenvolvimento, precisando disponha.'
    },
    alert: {
        class: 'alert-warning',
        msg: null,
        onClick: null,
        visible: alert.msg ? '' : 'd-none',
    },
    loading: {
        class: 'd-none',
        msg: '...'
    },
    loadingIconList: false,
    downloadPdf: null,
    modalDelete: {
        id: 'ModalDeleteGroups',
        title: '',
        footer: true,
        funccb1: null
    },
    modalCreate: {
        id: 'modalCreateGroups',
        title: '',
        footer: true,
        funccb1: null
    },
    childrenGroupsById: [],
    toggleFilter: true,

}

export default class GroupsList extends Component {

    state = { ...InitialState }

    componentDidMount() {
        this.getGroups()
    }

    refreshGroupsCb = () => this.getGroups()

    toggleFilter = () => this.setState({ toggleFilter: !this.state.toggleFilter })

    notify = (type, msg) => toast[type](msg)

    togglePopover = (e) => $(`#${e.target.id}`).popover('toggle')

    clearGroups = async () => {
        await this.setState({ groups: InitialState.groups })
    }

    linkAddGroupVsTests = (idTest) => this.props.history.push({ pathname: `/groupsVsTests`, paramIdGroup: idTest })

    deleteGroupsCb = async () => {

        if (this.state.groups.id) {
            await API.delete(`/groups/${this.state.groups.id}`)
                .then(suc => {
                    /* this.showAlert(true, 'Grupo excluido com sucesso', 'alert-success') */
                    this.notify('success', `${this.state.groups.name} excluido.`)
                    this.getGroups()
                })
                .catch(err => {
                    console.log(err)
                    const backWs = ifError(err)
                    /* this.showAlert(true, backWs, 'alert-danger') */
                    this.notify('warning', `${backWs || 'Falha ao excluir.'}`)
                })

        } else {
            this.showAlert(true, 'Não foi possível identificar o grupo, contacte o adm.', 'alert-warning')
        }
    }

    async getGroups() {
        const pagination = this.state.pagination
        await API.get('/groups')
            .then(resp => {
                if (resp.data.data.length) {
                    this.setState({
                        pagination: {
                            ...pagination,
                            list: resp.data.data,
                            filter: resp.data.data,
                            count: resp.data.count
                        }
                    })
                } else {
                    this.setState({ loadingIconList: true })
                }
            })
            .catch(err => {
                console.log(err)
                this.showAlert(true, 'Ocorreu algo inesperado ao tentar listar os créditos, contacte o administrador.', 'alert-warning')
                this.setState({ loadingIconList: true })
            })

        this.pagination(this.state.pagination.activePage)
    }

    updateField(event) {
        const paramsEmail = { ...this.state.paramsEmail }
        paramsEmail[event.target.id] = event.target.value
        this.setState({ paramsEmail })
    }

    showAlert(show, msg, color) {
        const alert = this.state.alert
        if (show === true) {
            alert.msg = msg
            alert.class = color
            this.setState({ alert })
        } else {
            alert.msg = null
            this.setState({ alert })
        }
    }

    loading(show, msg) {
        const loading = this.state.loading
        if (show === true) {
            loading.msg = msg
            loading.class = ''
            this.setState({ loading })
        } else {
            loading.class = 'd-none'
            this.setState({ loading })
        }
    }

    renderHeaderTable() {
        return (
            <React.Fragment>
                <div className="container-fluid mb-2">
                    <div className="row mb-2 mt-2">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 px-1">
                            <div className="d-flex flex-nowrap">
                                <h4 className="text-CubohColor1"><b> Lista de grupos </b></h4>
                                <i id="buttonPopoverList" onMouseOver={e => this.togglePopover(e)} onMouseLeave={e => this.togglePopover(e)} onClick={e => this.togglePopover(e)} type="button" className="fas fa-question-circle text-muted fa-sm align-self-center ml-1"
                                    container='principal' data-container="main" data-toggle="popover" data-placement="bottom"
                                    data-content="Crie grupos, edite e analise. Você pode montar ornagrama da sua empresa ou do seu cliente, colocando grupos dentro de grupos, divirta-se." />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 px-1">
                            <div className="d-flex justify-content-end mt-2 mt-md-0 ">

                                <button className="btn btn-sm btn-login fas fa-plus fa-1x mr-2 "
                                    data-toggle="modal" data-target="#modalCreateGroups"
                                    onClick={e => this.clearGroups(e)}
                                />

                                <button className={`btn ${this.state.toggleFilter ? 'btn-login' : 'btn-outline-primary'} mr-2 btn-sm`} onClick={e => this.toggleFilter(e)}>
                                    <i className="fas fa-filter fa-1x" />
                                </button>

                                <Link to='/groupsVsTests'>
                                    <button className="btn btn-sm btn-login mr-2"><i className="fas fa-exchange-alt fa-1x" /></button>
                                </Link>

                                <Link to='/groupsHierarchy'>
                                    <button className="btn btn-sm btn-login "><i className="fas fa-sitemap fa-1x" /></button>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className={` ${this.state.toggleFilter ? 'd-none' : ''} row my-2 `}>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 px-1">
                            <div className="input-group input-group-sm">
                                <input type="text" className="form-control" id="search" autoComplete="off"
                                    placeholder="Pesquisar por nome"
                                    value={this.state.pagination.search || ''}
                                    onChange={(e) => this.updateFilter(e)} />
                                <div className="input-group-append">
                                    <button className="btn btn-sm btn-login" type="button"><i className="fas fa-search" /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        )
    }

    pagination(activePage) {

        const pagination = { ...this.state.pagination }
        const minListTest = activePage * this.state.pagination.limit - this.state.pagination.limit
        const tests = this.state.pagination.filter.slice(minListTest, minListTest + this.state.pagination.limit)
        pagination.activePage = activePage
        pagination.testPage = tests
        this.setState({ pagination })
    }

    async updateFilter(event) {
        const pagination = { ...this.state.pagination }
        pagination.search = event.target.value
        if (event.target.value !== '') {
            pagination.filter = pagination.list.filter(total => !total.name.toUpperCase().indexOf(event.target.value.toUpperCase()))
        } else {
            pagination.filter = pagination.list
        }
        await this.setState({ pagination })
        this.pagination(this.state.pagination.activePage)
    }

    renderTable() {
        return (
            <div className="content">
                <div className="table-responsive-sm" style={{ overflowX: 'initial' }}>
                    <table className="table table-hover table-sm">
                        <thead>
                            <tr>
                                <th scope="col">Grupo</th>
                                <th scope="col">Nome</th>
                                <th scope="col">Status</th>
                                <th scope="col">Criado</th>
                                <th scope="col">Opções</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderRows()}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th className="align-middle text-center" colSpan="2">{`${this.state.pagination.testPage.length} de ${this.state.pagination.filter.length}`}</th>
                                <th className="align-middle text-center" colSpan="3">
                                    <Pagination
                                        activePage={this.state.pagination.activePage}
                                        itemsCountPerPage={this.state.pagination.limit}
                                        totalItemsCount={parseInt(this.state.pagination.count)}
                                        innerClass={"pagination pagination-sm justify-content-center"}
                                        prevPageText={<i className="fa fa-chevron-left" />}
                                        firstPageText={<i className="fa fa-angle-double-left" />}
                                        nextPageText={<i className="fa fa-chevron-right" />}
                                        lastPageText={<i className="fa fa-angle-double-right" />}
                                        itemClass={"page-item"}
                                        linkClass={"page-link"}
                                        disabledClass={""}
                                        pageRangeDisplayed={5}
                                        onChange={e => this.pagination(e)}
                                    />
                                </th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        )
    }

    async setGroups(e) {
        e.preventDefault()
        const list = this.state.pagination.list
        const groupSelected = await list.find(group => parseInt(e.target.value) === group.id)
        const selectParentName = await groupSelected.idParent ? list.find(group => group.id === groupSelected.idParent).name : ''

        this.setState({
            groups: {
                id: groupSelected.id,
                name: groupSelected.name,
                idParent: groupSelected.idParent,
                selectedName: selectParentName,
                status: true
            }
        })
    }

    async setModal(e) {
        e.preventDefault()
        const dataset = e.target.dataset

        await this.setGroups(e)

        if (dataset.state === 'modalDelete') {
            await API.get(`/childrenGroupsById/${this.state.groups.id}`)
                .then(resp => { this.setState({ childrenGroupsById: resp.data }) })
                .catch(err => console.log(err))
        }

        this.setState({
            [dataset.state]: {
                ...this.state[dataset.state],
                id: dataset.id,
                title: dataset.title,
                funccb1: dataset.funccb1 ? this[String(dataset.funccb1)] : null
            }
        })

    }

    modalDeleteGroup() {
        return (
            <div>
                <p className="">{`Se o grupo`} <b className="h6 text-CubohColor2 text-bold"> {this.state.groups.name || ''} </b> {`tiver outros grupos atrelados, os mesmo também serão excluidos. Caso você queria evitar isso, você pode editar o grupo e desvincular. `}</p>
                <span className="text-danger">Vão ser excluidos: </span> {this.renderRowsGroups()}
            </div>
        )
    }

    renderRowsGroups() {

        if (this.state.childrenGroupsById.length) {
            return this.state.childrenGroupsById.map(groups => {
                return (
                    <span className="badge badge-primary mx-1" key={groups.id} >{`${groups.id}- ${groups.name}`}</span>
                )
            })
        }
    }

    renderRows() {

        if (this.state.pagination.list.length) {

            return this.state.pagination.testPage.map(groups => {

                return (
                    <tr className="align-middle" key={groups.id}>
                        <td className="align-middle">{groups.idByUser}</td>
                        <td className="align-middle">{groups.name}</td>
                        <td className="align-middle">{groups.status ? 'Ativo' : 'Inativo'}</td>
                        <td className="align-middle">{formatDate(groups.createdAt)}</td>
                        <td className="align-middle">
                            <div className="btn-group dropleft ml-2 float-center" role="group">
                                <button id="btnGroupDrop1" type="button" className="btn btn-sm m-0 btn-login dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                    <i className="fas fa-bars" />
                                </button>
                                <div className="dropdown-menu dropdown-menu-down float-left" aria-labelledby="btnGroupDrop1">

                                    <button className="dropdown-item" value={groups.id} onClick={e => this.setModal(e)}
                                        data-toggle="modal" data-target="#modalCreateGroups"
                                        data-state="ModalCreate" data-title="Editar Grupo"
                                    >
                                        <i className="fas fa-pen fa-1x" /> | Editar Grupo
                                    </button>
                                    <div role="separator" className="dropdown-divider" />

                                    <button className="dropdown-item" value={groups.id} onClick={e => this.linkAddGroupVsTests(groups.id)}>
                                        <i className="fas fa-plus text-success fa-1x" /> | Adicionar Teste
                                    </button>

                                    <div role="separator" className="dropdown-divider" />

                                    <button className="dropdown-item" value={groups.id} onClick={e => this.setModal(e)}
                                        data-toggle="modal" data-target="#ModalDeleteGroups"
                                        data-state="modalDelete" data-title="Excluir grupo"
                                        data-funccb1="deleteGroupsCb" data-id="ModalDeleteGroups">
                                        <i className="fas fa-trash text-danger" /> | Excluir grupo
                                    </button>

                                </div>
                            </div>

                        </td>
                    </tr>
                )
            })
        } else {
            return (
                <LoadingIconList loadingIconList={this.state.loadingIconList} />
            )
        }
    }

    render() {
        return (
            <Content>
                <ToastContainer position="top-right"
                    autoClose={4000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable
                    pauseOnHover />
                <Loading {...this.state.loading} />
                <Alert
                    msg={this.state.alert.msg}
                    class={this.state.alert.class}
                    onClick={e => this.showAlert(false)}
                    visible={this.state.alert.msg ? '' : 'd-none'}
                />
                {this.renderHeaderTable()}
                {this.renderTable()}
                <div id="ModalFormsGroups">
                    < CreateGroups {...this.props} groups={this.state.groups} refreshGroupsCb={this.refreshGroupsCb} />
                </div>
                <ModalForms {...this.props} modal={this.state.modalDelete}>
                    {this.modalDeleteGroup()}
                </ModalForms>
            </Content>
        )
    }
}