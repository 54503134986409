import React from 'react'
import '../css/Content.css'

export default props =>
    <React.Fragment>
        <main className="content">
            <div id="principal" className="p-2">
                {props.children}
            </div>

        </main>
    </React.Fragment>