import React from 'react'

export default props =>

    <div className="modal fade" id="exampleModal" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h3 className="modal-title">{props.title}</h3>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <h5>{props.subtitle}</h5>
                    <p>{props.subtitle2}</p>
                    <small className="text-danger">
                        *Essa ação pode ser irreversível.
                    </small>
                </div>
                <div className="modal-footer">
                    <button type="button" data-dismiss="modal" value={props.value} className="btn btn-danger" onClick={props.onClick}><b>{props.titleButton1}</b></button>
                    <button type="button" className="btn btn-success" data-dismiss="modal"><b>{props.titleButton2}</b></button>
                </div>
            </div>
        </div>
    </div>