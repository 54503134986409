//import ReactDOM from 'react-dom'
import React, { Component } from 'react'
//import Index from '../components/template/jsx/Index'

export default function (ComposedComponent) {

    class Admin extends Component {

        isAdmin() {
            const user = JSON.parse(localStorage.getItem('@disc-app/signin'))
            if (user) {
                if (new Date(user.exp * 1000) < new Date()) {
                    this.redirectToLogin()
                } else {
                    if (!user.admin) {
                        this.props.history.push({ pathname: "/" })
                    }
                }
            } else {
                this.redirectToLogin()
            }
        }

        redirectToLogin() {
            localStorage.removeItem("@disc-app/signin")
            window.location.replace('/Login')
        }

        componentDidMount() {
            this.isAdmin()
        }

        render() {
            return <ComposedComponent {...this.props} />
        }

    }

    return Admin
}