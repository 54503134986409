import React, { Component } from 'react'
import './PostCrud.css'
import Content from '../template/jsx/Content'
import PostCard from '../postCard/PostCard'
import Alert from '../tools/Alert'
import { Link } from 'react-router-dom'
import API from '../../utils/API'
import { ifError } from '../tools/utils'
import Loading from '../tools/Loading'
import { formatDate } from '../tools/utils'

const initialState = {
    post: {
        id: null,
        title: null,
        subTitle: null,
        type: null,
        category: null,
        order: null,
        status: null,
        imageUrl: null,
        pdfUrl: null,
        audience: null,
        createdAt: null,
    },
    imageUpdate: null,
    alert: {
        class: 'alert-warning',
        msg: null,
        onClick: null,
        visible: alert.msg ? '' : 'd-none',
    },
    loading: {
        class: 'd-none',
        msg: 'loading...'
    },
}

export default class PostCrud extends Component {

    state = { ...initialState }

    async componentDidMount() {
        if (this.props.match.params.id) this.showAlert(false)
        if (this.props.location.msg) this.showAlert(true, this.props.location.msg || 'Salvo com Sucesso', this.props.location.alert || 'alert-warning')

        if (this.props.match.params.id) {
            await this.getPost(this.props.match.params.id)
        }
    }

    async getPost(id) {
        let post = this.state.post

        await API.get(`/post/${id}`)
            .then(resp => {
                post = {
                    id: resp.data.id,
                    title: resp.data.title,
                    subTitle: resp.data.subTitle,
                    type: resp.data.type.toString(),
                    category: resp.data.category.toString(),
                    order: resp.data.order,
                    status: resp.data.status.toString(),
                    imageUrl: resp.data.imageUrl,
                    pdfUrl: resp.data.pdfUrl,
                    audience: resp.data.audience,
                    createdAt: formatDate(resp.data.createdAt),
                }
                this.setState({ post })
            })
            .catch(err => console.log(err.response))
        return post
    }

    updateField(event) {
        const post = { ...this.state.post }
        post[event.target.id] = event.target.value
        this.setState({ post })
        //console.log(this.state.post)
    }

    save(e) {
        e.preventDefault()
        this.showAlert(false)
        const post = this.state.post
        delete post.createdAt //backEnd não precisa dessa informação.

        const fd = new FormData()
        const method = post.id ? 'put' : 'post'
        const url = post.id ? `/post/${post.id}` : '/post'

        if (this.validForm()) {

            Object.keys(post).forEach(function (item) {
                if (typeof post[item] === 'object' && post[item] !== null) {
                    fd.append(item, post[item], post[item].name)
                } else if (post[item] !== null) {
                    fd.append(item, post[item])
                }
            })

            API[method](url, fd, {
                onUploadProgress: ProgressEvent => {
                    const percent = Math.round(ProgressEvent.loaded / ProgressEvent.total * 100)
                    if (percent < 100) {
                        this.loading(true, `Enviando arquivo... ${percent}%`)
                    } else {
                        this.loading(true, `Processando dados...`)
                    }
                }
            })
                .then(resp => {
                    this.loading(false)
                    if (method === 'put') {
                        this.getPost(post.id)
                        this.showAlert(true, 'Post salvo com sucesso.', 'alert-success')
                    } else {
                        this.props.history.push({ pathname: `/postEdit/${resp.data.id}`, msg: 'Post salvo com sucesso', alert: 'alert-success' })
                    }
                })
                .catch(err => {
                    this.loading(false)
                    console.log('err =', err)
                    const backWs = ifError(err)
                    this.showAlert(true, backWs, 'alert-danger')
                })
        }
    }

    async remove(e) {
        e.preventDefault()

        await API.delete(`/post/${this.state.post.id}`)
            .then(resp => {
                this.props.history.push({ pathname: "/postList", msg: `Post '${this.state.post.title}' excluído com sucesso!.`, alert: 'alert-success' })
            })
            .catch(err => {
                console.log(err)
                const msg = ifError(err)
                this.showAlert(true, msg || 'Ocorreu algo inesperado, contacte o administrador.', 'alert-danger')
            })
    }

    validForm(e) {
        const { post } = this.state
        let ret = false

        if (!post.title) {
            this.showAlert(true, 'Título não informado. ', 'alert-danger')
        } else if (!post.type) {
            this.showAlert(true, 'Tipo não selecionado. ', 'alert-danger')
        } else if (!post.category) {
            this.showAlert(true, 'Categoria não informada. ', 'alert-danger')
        } else if (!post.status) {
            this.showAlert(true, 'Ativo ou Inativo, não informado. ', 'alert-danger')
        } else if (!post.pdfUrl) {
            this.showAlert(true, 'Pdf não informado. ', 'alert-danger')
        } else if (!post.audience) {
            this.showAlert(true, 'Audiência não informada.', 'alert-danger')
        }
         else {
            ret = true
        }

        return ret

    }

    showAlert(show, msg, color) {
        const alert = this.state.alert
        if (show === true) {
            alert.msg = msg
            alert.class = color
            this.setState({ alert })
        } else {
            alert.msg = null
            this.setState({ alert })
        }

    }

    loading(show, msg) {
        const loading = this.state.loading
        if (show === true) {
            loading.msg = msg
            loading.class = ''
            this.setState({ loading })
        } else {
            loading.class = 'd-none'
            this.setState({ loading })
        }
    }

    removeImage(e) {
        e.preventDefault()
        const post = this.state.post
        post.imageUrl = ''
        this.setState({ post, imageUpdate: null })

    }

    fileSelected(e) {
        const file = e.target.files[0]
        if (!file) return

        const post = this.state.post
        const format = file.type === 'application/pdf' ? 'pdf' : file.type.substr(0, 5) === 'image' ? 'img' : null
        const fileType = format === 'img' ? ['image/jpeg', 'image/png', 'image/jpg'] : ['application/pdf']
        const maxByte = format === 'img' ? 1024 * 1024 * 2 : 1024 * 1024 * 5 //Máximo de 2mb e 5mb 

        //Check pra saber se a imagem é valida      
        if (!format) {
            this.showAlert(true, 'Tipo de extensão não permitada.', 'alert-danger')
            return
        } else if (!fileType.includes(file.type)) {
            this.showAlert(true, `Tipo não permitido. (permitido: ${fileType})`, 'alert-danger')
            return
        } else if (file.size > maxByte) {
            this.showAlert(true, `Tamanho do arquivo não permitido. (máximo: ${maxByte / 1048576}Mb)`, 'alert-danger')
            return
        }
        else {

            if (format === 'img') {
                const imageUpdate = URL.createObjectURL(file)
                post.imageUrl = file
                this.setState({ post, imageUpdate })

            } else if (format === 'pdf') {
                post.pdfUrl = file
                this.setState({ post })

            }
        }
    }

    renderForm() {
        return (
            <div className="row">

                <div className="col-xs-12 col-sm-12 col-md-4 mb-3">
                    <PostCard
                        {...this.state.post}
                        urlImage={this.state.imageUpdate ? this.state.imageUpdate : this.state.post.imageUrl ? `${process.env.REACT_APP_API_URL}/postCover/${this.state.post.imageUrl}` : null}
                    />
                </div>
                <div className="col-xs-12 col-sm-12 col-md-8">
                    <div className="form">
                        <div className="row">
                            <div className="col-12">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <label className="input-group-text btn-login">Título:</label>
                                    </div>
                                    <input type="text" className="form-control" id="title"
                                        maxLength="80"
                                        value={this.state.post.title || ''}
                                        onChange={e => this.updateField(e)}
                                        placeholder="Título do post">
                                    </input>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <label className="input-group-text btn-login">Subtítulo:</label>
                                    </div>
                                    <textarea
                                        className="form-control" rows="2" maxLength="250"
                                        id="subTitle"
                                        placeholder="Breve descrição do post"
                                        value={this.state.post.subTitle || ''}
                                        onChange={e => this.updateField(e)} />
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <label className="input-group-text btn-login" htmlFor="type">Tipo</label>
                                    </div>
                                    <select className="custom-select" value={this.state.post.type || ''} id="type"
                                        onChange={e => this.updateField(e)}>
                                        <option value="0">Selecionar</option>
                                        <option value="1">01</option>
                                        <option value="2">02</option>
                                        <option value="3">03</option>
                                        <option value="4">04</option>
                                        <option value="5">05</option>
                                        <option value="6">06</option>
                                        <option value="7">07</option>
                                        <option value="8">08</option>
                                        <option value="9">09</option>
                                        <option value="10">10</option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <label className="input-group-text btn-login" htmlFor="category">Categoria</label>
                                    </div>
                                    <select className="custom-select" value={this.state.post.category || ''} id="category"
                                        onChange={e => this.updateField(e)}>
                                        <option value="0">Selecionar</option>
                                        <option value="1">Tutorial</option>
                                        <option value="2">Conteúdo</option>
                                        <option value="3">Outros</option>

                                    </select>
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <label className="input-group-text btn-login">Ordem</label>
                                    </div>
                                    <input type="text" className="form-control" id="order"
                                        maxLength="80"
                                        value={this.state.post.order || ''}
                                        onChange={e => this.updateField(e)}
                                        placeholder="Ordem do Post">
                                    </input>
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <label className="input-group-text btn-login" htmlFor="status">Status</label>
                                    </div>
                                    <select className="custom-select" value={this.state.post.status || ''} id="status"
                                        onChange={e => this.updateField(e)}>
                                        <option value="0">Selecionar</option>
                                        <option value="01">Ativo</option>
                                        <option value="02">Inativo</option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <label className="input-group-text btn-login" htmlFor="audience">Audiência</label>
                                    </div>
                                    <select className="custom-select" value={this.state.post.audience || '0'} id="audience"
                                        onChange={e => this.updateField(e)}>
                                        <option value="0">Selecione</option>
                                        <option value="1">Sistema</option>
                                        <option value="2">Site</option>
                                        <option value="3">Ambos</option>
                                    </select>
                                </div>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <label className="input-group-text btn-login">Criado</label>
                                    </div>
                                    <input type="text" className="form-control" id="createdAt"
                                        maxLength="80" readOnly
                                        value={this.state.post.createdAt || ''}
                                        onChange={e => this.updateField(e)}
                                        placeholder="Criado em:">
                                    </input>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text btn-login"><i className="fas fa-image fa-lg" /></span>
                                    </div>
                                    <div className="custom-file">
                                        <input
                                            id="imageUrl"
                                            type="file"
                                            className="custom-file-input"
                                            onChange={e => this.fileSelected(e)}
                                        />
                                        <label
                                            className="custom-file-label"
                                            htmlFor="imageUrl"
                                            placeholder="Escolha a imagem da capa">{this.state.post.imageUrl ? this.state.post.imageUrl.name ? this.state.post.imageUrl.name : this.state.post.imageUrl : 'Escolha uma imagem da capa'}
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text btn-login"><i className="fas fa-file-pdf fa-lg" /></span>
                                    </div>
                                    <div className="custom-file">
                                        <input
                                            id="pdfUrl"
                                            type="file"
                                            className="custom-file-input"
                                            onChange={e => this.fileSelected(e)}
                                        />
                                        <label
                                            className="custom-file-label"
                                            htmlFor="pdfUrl">{this.state.post.pdfUrl ? this.state.post.pdfUrl.name ? this.state.post.pdfUrl.name : this.state.post.pdfUrl : 'Escolha o arquivo pdf'}
                                        </label>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <hr />

                        <div className="row">
                            <div className="col-12 d-flex justify-content-end">

                                <div className="btn-group dropleft ml-2" role="group">
                                    <button id="btnGroupDrop1" type="button" className="btn btn-login dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                        disabled={!!this.state.post.id === false ? true : ''}>
                                        <i className="fas fa-cog" /> Opções
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="btnGroupDrop1">
                                        <button className="dropdown-item"
                                            onClick={e => this.removeImage(e)}
                                            disabled={!!this.state.post.imageUrl === false ? true : ''}>
                                            <i className="fas fa-image  mr-2" /><b>Remover Imagem</b>
                                        </button>
                                        <div role="separator" className="dropdown-divider" />
                                        <button className="dropdown-item text-danger"
                                            value={this.state.post.id} data-toggle="modal" data-target="#exampleModal"
                                            onClick={e => this.remove(e)}>
                                            <i className="fas fa-trash mr-2 " /><b>Excluir Post</b>
                                        </button>

                                    </div>
                                </div>

                                <button className="btn btn-success ml-2" type="submit"
                                    onClick={e => this.save(e)}>
                                    Salvar
                                    </button>
                                <Link to="/postList">
                                    <button className="btn btn-secondary ml-2">Voltar</button>
                                </Link>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <Content>
                <Loading {...this.state.loading} />
                <Alert
                    msg={this.state.alert.msg}
                    class={this.state.alert.class}
                    onClick={e => this.showAlert(false)}
                    visible={this.state.alert.msg ? '' : 'd-none'}
                />
                {this.renderForm()}
            </Content>
        )
    }
}