import React, { Component } from 'react'
import './GroupsList.css'
import API from '../../utils/API'
import { Link } from 'react-router-dom'
import Content from '../template/jsx/Content'
import Loading from '../tools/Loading'
import Alert from '../tools/Alert'
import Tree from '@naisutech/react-tree'
import LoadingIconList from '../tools/LoadingIconList'
import $ from 'jquery'

const InitialState = {
    groups: {
        search: '',
        list: [],
        data: []
    },
    alert: {
        class: 'alert-warning',
        msg: null,
        onClick: null,
        visible: alert.msg ? '' : 'd-none',
    },
    loading: {
        class: 'd-none',
        msg: '...'
    },
    loadingIconList: false,
    toggleFilter: true,
    groupLists: {
        data: [],
        search: '',
    },
    childrenGroupsById: [],
}


const themeCuboh = {
    'cuboh': {
        text: '#212529',
        bg: '#eee',
        highlight: '#dee2e6 ', // the colours used for selected and hover items
        decal: '#00a2f2', // the colours used  for open folder indicators and icons
        accent: '#212529' // the colour used for row borders and empty file indicators
    }
}

const Icons = iconSet => {
    return (
        <i className={`fas fa-${iconSet || 'user'} fa-1x`} />
    )
}

export default class GroupsHierarchy extends Component {

    state = { ...InitialState }

    togglePopover = (e) => $(`#${e.target.id}`).popover('toggle')
    toggleFilter = () => {
        this.setState({ toggleFilter: !this.state.toggleFilter })
        if (!this.state.groupLists.data.length) this.getGroupsList()
    }

    componentDidMount() {
        this.getGroups()
    }

    async getGroups() {
        const groups = this.state.groups
        await API.get('/getGroupsTree')
            .then(resp => {

                if (resp.data.length) {
                    this.setState({
                        groups: {
                            ...groups,
                            list: resp.data,
                            data: resp.data
                        }
                    })
                } else {
                    this.setState({ loadingIconList: true })
                }
            })
            .catch(err => {
                console.log(err)
                this.showAlert(true, 'Ocorreu algo inesperado ao tentar listar os créditos, contacte o administrador.', 'alert-warning')
                this.setState({ loadingIconList: true })
            })

    }

    async getGroupsList() {
        const groupLists = this.state.groupLists

        await API.get('/getGroupsLists')
            .then(resp => {
                if (resp.data.length) {
                    this.setState({
                        groupLists: {
                            ...groupLists,
                            data: resp.data
                        }
                    })
                } else {
                    this.setState({ loadingIconList: true })
                }
            })
            .catch(err => {
                console.log(err)
                this.notify('warning', 'Ocorreu algo inesperado ao tentar listar os créditos, contacte o administrador.')
                this.setState({ loadingIconList: true })
            })
        console.log('Carregou a lista de grupo')
    }

    async updateField(e) {
        e.preventDefault()
        const idGroup = parseInt(e.target.value)
        const groupLists = this.state.groupLists
        groupLists.search = idGroup

        if (idGroup) {
            await API.get(`/childrenGroupsById/${idGroup}`)
                .then(resp => {
                    if (resp.data[0].parentId) resp.data[0].parentId = null
                    this.setState({ childrenGroupsById: resp.data, groupLists })
                })
                .catch(err => console.log(err))
            console.log(this.state.childrenGroupsById)
        } else {
            this.showAlert(true, 'Selecione um grupo válido', 'alert-warning')
        }

    }

    showAlert(show, msg, color) {
        const alert = this.state.alert
        if (show === true) {
            alert.msg = msg
            alert.class = color
            this.setState({ alert })
        } else {
            alert.msg = null
            this.setState({ alert })
        }
    }

    loading(show, msg) {
        const loading = this.state.loading
        if (show === true) {
            loading.msg = msg
            loading.class = ''
            this.setState({ loading })
        } else {
            loading.class = 'd-none'
            this.setState({ loading })
        }
    }

    renderHeader() {
        return (
            <React.Fragment>
                <div className="container-fluid mb-2">
                    <div className="row mb-2 mt-2">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                            <div className="d-flex flex-nowrap">
                                <h4 className="text-CubohColor1"><b>Hierarquia dos grupos</b></h4>
                                <i id="TitleQuestionHirarchyList" onMouseOver={e => this.togglePopover(e)} onMouseLeave={e => this.togglePopover(e)} onClick={e => this.togglePopover(e)} type="button" className="fas fa-question-circle text-muted fa-sm align-self-center ml-1"
                                    container='principal' data-container="main" data-toggle="popover" data-html="true" data-placement="bottom"
                                    data-content="Observe como está a organização dos seus grupos. <br/> Para editar basta ir no menu de grupos e organizar da forma que achar melhor. <br/> divirta-se ;)" />
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div className="d-flex justify-content-end mt-2 mt-md-0">

                                <button className={`btn ${this.state.toggleFilter ? 'btn-login' : 'btn-outline-primary'} mr-2 btn-sm`} onClick={e => this.toggleFilter(e)}>
                                    <i className="fas fa-filter fa-1x" />
                                </button>

                                <Link to='/groupsVsTests'>
                                    <button className="btn btn-sm btn-login mr-2"><i className="fas fa-exchange-alt fa-1x" /></button>
                                </Link>

                                <Link to='/GroupsList'>
                                    <button className="btn btn-sm btn-login"><i className="fas fa-list-ul fa-1x" /></button>
                                </Link>

                            </div>
                        </div>
                    </div>

                    <div className={` ${this.state.toggleFilter ? 'd-none' : ''} row justify-content-end my-2 `}>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div className="input-group input-group-sm flex-nowrap">
                                <div className="input-group-prepend">
                                    <span htmlFor="idGroup" className="input-group-text btn-login" id="inputGroup-sizing-sm">Grupo</span>
                                </div>
                                <select id="idGroupSelectHierarchy" className="custom-select"
                                    value={this.state.groupLists.search || '0'} onChange={e => this.updateField(e)}>
                                    <option className="list-group-item" value="0" defaultValue>Selecione um grupo</option>
                                    {this.renderOptionsListGroups()}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        )
    }

    seeGroupsTree() {

        if (this.state.groups.list.length) {

            return (
                <div className="mt-3">
                    <Tree nodes={this.state.childrenGroupsById.length ? this.state.childrenGroupsById : this.state.groups.data} //onSelect={onSelect}
                        customTheme={themeCuboh} theme={'cuboh'}
                        search='Fin'
                        iconSet={{
                            file: <Icons iconSet='users' /> // the  file item indicator (default 'paperclip')
                        }} />
                </div>
            )

        } else {

            return (
                <div className="mt-4 align-content-center m-auto">
                    <LoadingIconList loadingIconList={this.state.loadingIconList} type="div" />
                </div>
            )

        }
    }

    renderOptionsListGroups() {
        if (this.state.groupLists.data.length) {
            return this.state.groupLists.data.map(rows => {
                return <option key={rows.id} className="list-group-item" value={rows.id}>{`${rows.name}`}</option>
            })
        }
    }

    render() {
        return (
            <Content>
                <Loading {...this.state.loading} />
                <Alert
                    msg={this.state.alert.msg}
                    class={this.state.alert.class}
                    onClick={e => this.showAlert(false)}
                    visible={this.state.alert.msg ? '' : 'd-none'}
                />
                {this.renderHeader()}
                {this.seeGroupsTree()}
            </Content>
        )
    }

}