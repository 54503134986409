import React, { Component } from 'react'
import './Dashboard.css'
import Content from '../template/jsx/Content'
import API from '../../utils/API'
import Chart from 'chart.js'

const initialState = {
    credits: {
        quantity: 0,
        balanceUsed: 0,
        balanceCurrent: 0,
    },
    profileTypes: {},
    subProfileTypes: {},
    testsByStatus: {},
    durationAvg: {
        global: {
            minutes: 0,
            seconds: 0
        },
        user: {
            minutes: 0,
            seconds: 0
        }
    }

}

const pieOptions = {
    title: {
        display: false,
        text: 'Sem dados até o momento, quando o primeiro teste for respondido, poderemos gerar gráficos ;)'
    },
    legend: {
        position: 'left'
    },
    animation: {
        duration: 1500,
        easing: "easeOutQuart",
        onComplete: function () {
            var ctx = this.chart.ctx;
            ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontFamily, 'normal', Chart.defaults.global.defaultFontFamily);
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';

            this.data.datasets.forEach(function (dataset) {

                for (var i = 0; i < dataset.data.length; i++) {
                    var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
                        total = dataset._meta[Object.keys(dataset._meta)[0]].total,
                        mid_radius = model.innerRadius + (model.outerRadius - model.innerRadius) / 2,
                        start_angle = model.startAngle,
                        end_angle = model.endAngle,
                        mid_angle = start_angle + (end_angle - start_angle) / 2;

                    var x = mid_radius * Math.cos(mid_angle);
                    var y = mid_radius * Math.sin(mid_angle);

                    ctx.fillStyle = '#fff';
                    if (i === 3) { // Darker text color for lighter background
                        ctx.fillStyle = '#444';
                    }
                    var percent = String(Math.round(dataset.data[i] / total * 100)) + "%";
                    //Don't Display If Legend is hide or value is 0

                    ctx.fillText(dataset.data[i], model.x + x, model.y + y);
                    // Display percent in another line, line break doesn't work for fillText
                    ctx.fillText(percent, model.x + x, model.y + y + 15);

                }
            });
        }
    }
};

export default class Dashboard extends Component {

    state = { ...initialState }

    async componentDidMount() {
        /* await API.get(`/creditsDashboard`)
            .then(resp => {
                if (resp && resp.data) {
                    this.setState({ credits: resp.data })
                }
            }) */

        await API.get(`/profileTypes`)
            .then(resp => {
                this.splitProfiles(resp.data, 'profileTypes')
            })
            .catch(err => console.log(err))

        await API.get(`/subprofileTypes`)
            .then(resp => {
                this.splitProfiles(resp.data, 'subProfileTypes')
            })
            .catch(err => console.log(err))

        await API.get(`/testsByStatus`)
            .then(resp => {
                this.splitTestsStatus(resp.data)
            })
            .catch(err => console.log(err))

        await API.get(`/durationAvg`)
            .then(resp => {
                this.setState({ durationAvg: resp.data })
            })
            .catch(err => console.log(err))

        this.renderDoughnutChart()
        this.renderBarChartSubProfiles()
        this.renderTestsByStatus()
    }

    splitProfiles(profiles, object) {

        const profileName = []
        const profileValue = []
        const profileColor = []
        let prof = '', quantity = 0 //bug exist in eslint 

        for ({ prof, quantity } of profiles) {
            profileName.push(prof)
            profileValue.push(quantity)

            if (object === 'profileTypes') {
                if (prof === 'D' && !profileColor.includes('#dc3545')) profileColor.push('#dc3545');
                if (prof === 'I' && !profileColor.includes('#007bff')) profileColor.push('#007bff');
                if (prof === 'S' && !profileColor.includes('#ffc107')) profileColor.push('#ffc107');
                if (prof === 'C' && !profileColor.includes('#28a745')) profileColor.push('#28a745');
            }
        }

        if (profileName.length !== 0 && profileValue.length !== 0) {
            if (object === 'profileTypes') {
                this.setState({
                    profileTypes: { profileName, profileValue, profileColor }
                })
            } else if (object === 'subProfileTypes') {
                this.setState({
                    subProfileTypes: { profileName, profileValue }
                })
            }
        }
    }

    splitTestsStatus(profiles) {

        const profileName = []
        const profileValue = []
        const profileColor = []
        let status = '', quantity = 0 //bug exist in eslint 

        for ({ status, quantity } of profiles) {
            profileValue.push(quantity)

            if (status === '05') {
                profileColor.push('#dc3545')
                profileName.push(`Finalizado`)
            };
            if (status === '04') {
                profileColor.push('#007bff')
                profileName.push(`Iniciado`)
            };
            if (status === '03') {
                profileColor.push('#ffc107')
                profileName.push(`Enviado`)
            };
            if (status === '02') {
                profileColor.push('#28a745')
                profileName.push(`Cancelado`)
            };
            if (status === '01') {
                profileColor.push('#28a745')
                profileName.push(`Criado`)
            };
        }


        if (profileName.length !== 0 && profileValue.length !== 0) {
            this.setState({
                testsByStatus: { profileName, profileValue, profileColor }
            })
        }
    }

    renderBarChartSubProfiles() {
        if (Object.keys(this.state.subProfileTypes).length > 0) {
            new Chart(document.getElementById("renderBarChartSubProfiles"), {
                type: 'bar',
                data: {
                    //labels: ["DI", "DS", "DC", "ID", "IS", "IC", "SD", "SI", "SC", "CD", "CI", "CS"],
                    labels: this.state.subProfileTypes.profileName,
                    datasets: [
                        {
                            label: 'Quantidade',
                            backgroundColor: "#00b4db",
                            hoverBorderColor: "#0083b0",
                            borderWidth: 2,
                            barPercentage: 0.7,
                            data: this.state.subProfileTypes.profileValue
                        },
                    ]
                },
                options: {
                    title: {
                        display: false,
                        text: 'Sem dados até o momento ;)'
                    },
                    label: {
                        display: false
                    },
                    scales: {
                        xAxes: [{
                            stacked: true,
                        }],
                        yAxes: [{
                            stacked: true,
                            ticks: {
                                min: 0,
                                stepSize: Math.max(...this.state.subProfileTypes.profileValue) < 20 ? 1 : 2,
                                max: Math.max(...this.state.subProfileTypes.profileValue) + 1
                            }
                        }]
                    }
                }
            })
        }
    }

    renderDoughnutChart() {
        if (Object.keys(this.state.profileTypes).length > 0) {
            new Chart(document.getElementById("DoughnutChart"), {
                type: 'doughnut',
                data: {
                    labels: this.state.profileTypes.profileName,
                    datasets: [
                        {
                            label: "Population (millions)",
                            backgroundColor: this.state.profileTypes.profileColor,
                            data: this.state.profileTypes.profileValue
                        },
                    ]
                },
                options: pieOptions,
            })
        }
    }

    renderTestsByStatus() {
        if (Object.keys(this.state.testsByStatus).length > 0) {
            new Chart(document.getElementById("renderTestsByStatus"), {
                type: 'pie',
                data: {
                    labels: this.state.testsByStatus.profileName,
                    datasets: [
                        {
                            label: "Tipos de perfis",
                            backgroundColor: this.state.testsByStatus.profileColor,
                            data: this.state.testsByStatus.profileValue,
                        },
                    ]
                },
                options: pieOptions,
            })
        }
    }

    toglleIconColapsse(e) {
        e.target.classList.toggle("fa-sort-up")
        e.target.classList.toggle("fa-sort-down")
    }

    verifyStateIsEmpty(nameOfState) {
        if (Object.keys(this.state[nameOfState]).length === 0) {
            return true
        } else {
            return false
        }
    }

    renderCards() {
        return (
            <div id="dashboard">
                <div className="container-fluid ">
                    <div className="row">

                        <div className="col-sm-12 col-md-12 col-lg-6 mt-3">
                            <div className="card text-center">
                                <div className="card-header btn-login">
                                    <div className="col-2 align-self-center">
                                        <i className="fas fa-chart-pie fa-2x" />
                                    </div>
                                    <div className="col-8 align-self-center">
                                        <div className="text-title-canva">Perfis</div>
                                    </div>
                                    <div className="col-2 align-self-center" onClick={e => this.toglleIconColapsse(e)} data-toggle="collapse" data-target="#viewTypePerfil" aria-expanded="true">
                                        <i className="fas fa-sort-up colapseIcon" />
                                    </div>
                                </div>

                                <div className="collapse show" id="viewTypePerfil">
                                    <div className="card-body">
                                        {this.verifyStateIsEmpty('profileTypes') ?
                                            <span className="text-dashboard-empty">Sem dados até o momento, após a 1º resposta poderemos gerar dados inteligentes pra você ;)</span>
                                            :
                                            <canvas id="DoughnutChart"></canvas>
                                        }
                                    </div>
                                    <div className="card-footer">
                                        <small className="text-muted">Considerando todos os perfis principais.</small>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-12 col-lg-6 mt-3">
                            <div className="card text-center">
                                <div className="card-header btn-login">
                                    <div className="col-2 align-self-center">
                                        <i className="fas fa-chart-bar fa-2x" />
                                    </div>
                                    <div className="col-8 align-self-center">
                                        <div className="text-title-canva">Perfis secundários</div>
                                    </div>
                                    <div className="col-2 align-self-center" onClick={e => this.toglleIconColapsse(e)} data-toggle="collapse" data-target="#viewTypeSubPerfil" aria-expanded="true">
                                        <i className="fas fa-sort-up colapseIcon" />
                                    </div>
                                </div>

                                <div className="collapse show" id="viewTypeSubPerfil">
                                    <div className="card-body card h-100">
                                        {this.verifyStateIsEmpty('subProfileTypes') ?
                                            <span className="text-dashboard-empty">Sem dados até o momento ;)</span>
                                            :
                                            <canvas id="renderBarChartSubProfiles"></canvas>
                                        }
                                    </div>

                                    <div className="card-footer">
                                        <small className="text-muted">Considerando todos os perfis secundários.</small>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row">

                        <div className="col-sm-12 col-md-12 col-lg-6 mt-3">
                            <div className="card text-center">
                                <div className="card-header btn-login">
                                    <div className="col-2 align-self-center">
                                        <i className="fas fa-stopwatch fa-2x" />
                                    </div>

                                    <div className="col-8 align-self-center">
                                        <div className="text-title-canva">Tempo Médio</div>
                                    </div>

                                    <div className="col-2 align-self-center" onClick={e => this.toglleIconColapsse(e)} data-toggle="collapse" data-target="#viewDurationAvg" aria-expanded="true">
                                        <i className="fas fa-sort-up colapseIcon" />
                                    </div>

                                </div>

                                <div className="collapse show" id="viewDurationAvg">
                                    <div className="card-body card h-100">
                                        <div className="placa">
                                            <span className="text-value">{this.state.durationAvg.global.minutes || 0} : {this.state.durationAvg.global.seconds || 0} </span>
                                            <span className="text-value-title">Global</span>
                                        </div>
                                        <div className="placa">
                                            <span className="text-value">{this.state.durationAvg.user.minutes || 0} : {this.state.durationAvg.user.seconds || 0} </span>
                                            <span className="text-value-title">Seus testes</span>
                                        </div>
                                    </div>

                                    <div className="card-footer">
                                        <small className="text-muted">Tempo médio dos respondentes. (em min:sec)</small>
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className="col-sm-12 col-md-12 col-lg-6 mt-3">
                            <div className="card text-center">
                                <div className="card-header btn-login">
                                    <div className="col-2 align-self-center">
                                        <i className="fas fa-chart-pie fa-2x" />
                                    </div>
                                    <div className="col-8 align-self-center">
                                        <div className="text-title-canva">Status dos testes</div>
                                    </div>
                                    <div className="col-2 align-self-center" onClick={e => this.toglleIconColapsse(e)} data-toggle="collapse" data-target="#viewTestsByStatus" aria-expanded="true">
                                        <i className="fas fa-sort-up colapseIcon" />
                                    </div>
                                </div>

                                <div className="collapse show" id="viewTestsByStatus">
                                    <div className="card-body">
                                        {this.verifyStateIsEmpty('testsByStatus') ?
                                            <span className="text-dashboard-empty">Sem dados até o momento, após a 1º resposta poderemos gerar dados inteligentes pra você ;)</span>
                                            :
                                            <canvas id="renderTestsByStatus"></canvas>
                                        }
                                    </div>

                                    <div className="card-footer">
                                        <small className="text-muted">Considerando todos os testes.</small>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <Content>
                {this.renderCards()}
            </Content>
        )
    }
}
