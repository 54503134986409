import React, { Component } from 'react'
//import './GroupsList.css'
import API from '../../utils/API'
import Pagination from "react-js-pagination"
import { formatDate } from '../tools/utils'
import Content from '../template/jsx/Content'
import Alert from '../tools/Alert'
import Loading from '../tools/Loading'
import LoadingIconList from '../tools/LoadingIconList'
import CreatePositions from '../positions/PostionsCrud'
import ModalForms from '../tools/ModalForms'
import { ifError } from '../tools/utils'
import $ from 'jquery'
import Toastify, { notify } from '../tools/Toastify'

const InitialState = {

    positions: {
        id: '',
        name: '',
        status: true
    },
    positionsDelete: {
        name: '',
        status: true
    },
    pagination: {
        activePage: 1,
        limit: 10,
        count: '',
        search: '',
        data: [],
        page: [],
    },
    alert: {
        class: 'alert-warning',
        msg: null,
        onClick: null,
        visible: alert.msg ? '' : 'd-none',
    },
    loading: {
        class: 'd-none',
        msg: '...'
    },
    loadingIconList: false,
    modalCrud: {
        id: 'modalCrudPositions',
        title: 'Criar cargo',
        footer: false,
        funccb1: null
    },
    modalDelete: {
        id: 'ModalDeletePositions',
        title: '',
        footer: true,
        funccb1: null
    },
    childrenGroupsById: [],
    toggleFilter: true,
}

export default class PositionsList extends Component {

    state = { ...InitialState }

    componentDidMount() {
        this.getPositions()
    }

    insertPositionCb = async (positions, insert) => {
        const { pagination } = this.state
        let indexSelected = 0

        if (!insert) { // insert = false or editing
            indexSelected = await pagination.data.findIndex(position => positions.id === position.id)
            if (indexSelected === -1) return this.showAlert(true, 'Ocorreu algo inesperado, recarregue ou contacte o adm.')
        }

        pagination.data.splice(indexSelected, insert ? 0 : 1, { ...positions })
        this.setState({ pagination })
        this.pagination(pagination.activePage)
    }

    toggleFilter = () => this.setState({ toggleFilter: !this.state.toggleFilter })

    togglePopover = (e) => $(`#${e.target.id}`).popover('toggle')

    totalOfItemsTable = () => this.state.pagination.search ? this.state.pagination.filter.length : this.state.pagination.data.length

    clearPositions = async () => await this.setState({ positions: InitialState.positions, modalCrud: InitialState.modalCrud })

    deletePositionsCb = async () => {
        const positionId = this.state.positions.id

        if (positionId) {
            await API.delete(`/positions/${positionId}`)
                .then(async suc => {
                    const pagination = this.state.pagination
                    const indexPosition = await pagination.data.findIndex(position => positionId === position.id)
                    if (indexPosition < 0) notify('warning', 'Houve uma falha ao atualizar tabela, recarregue a tela.')
                    pagination.data.splice(indexPosition, 1)
                    this.setState({ pagination })
                    this.pagination(pagination.activePage)
                    notify('success', 'Cargo excluido com sucesso')
                })
                .catch(err => {
                    console.log(err)
                    const backWs = ifError(err)
                    this.showAlert(true, backWs, 'alert-danger')
                })

        } else {
            this.showAlert(true, 'Não foi possível identificar o cargo, contacte o adm.', 'alert-warning')
        }
    }

    async getPositions() {
        const pagination = this.state.pagination
        await API.get('/positions')
            .then(resp => {
                if (resp.data.data.length) {
                    this.setState({
                        pagination: {
                            ...pagination,
                            data: resp.data.data,
                            count: resp.data.count
                        }
                    })
                } else {
                    this.setState({ loadingIconList: true })
                }
            })
            .catch(err => {
                console.log(err)
                this.showAlert(true, 'Ocorreu algo inesperado ao tentar listar os cargos, contacte o administrador.', 'alert-warning')
                this.setState({ loadingIconList: true })
            })

        this.pagination(this.state.pagination.activePage)
    }

    updateField(event) {
        const paramsEmail = { ...this.state.paramsEmail }
        paramsEmail[event.target.id] = event.target.value
        this.setState({ paramsEmail })
    }

    openPdf(e) {
        e.preventDefault()
        const pdf = window.URL.createObjectURL(this.state.downloadPdf)
        window.open(pdf, '_blank', '', true);
    }

    showAlert(show, msg, color) {
        const alert = this.state.alert
        if (show === true) {
            alert.msg = msg
            alert.class = color
            this.setState({ alert })
        } else {
            alert.msg = null
            this.setState({ alert })
        }
    }

    loading(show, msg) {
        const loading = this.state.loading
        if (show === true) {
            loading.msg = msg
            loading.class = ''
            this.setState({ loading })
        } else {
            loading.class = 'd-none'
            this.setState({ loading })
        }
    }

    renderHeaderTable() {
        return (
            <React.Fragment>
                <div className="container-fluid mb-2">
                    <div className="row mb-2 mt-2">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 px-1">
                            <div className="d-flex flex-nowrap">
                                <h4 className="text-CubohColor1"><b> Lista de cargos </b></h4>
                                <i id="buttonPopoverList" onMouseOver={e => this.togglePopover(e)} onMouseLeave={e => this.togglePopover(e)} onClick={e => this.togglePopover(e)} type="button" className="fas fa-question-circle text-muted fa-sm align-self-center ml-1"
                                    container='principal' data-container="main" data-toggle="popover" data-placement="bottom"
                                    data-content="Crie cargos, edite e exclua. Os cargos servem pra você analisar as pessoas através de suas funções." />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 px-1">
                            <div className="d-flex justify-content-end mt-2 mt-md-0 ">

                                <button className="btn btn-sm btn-login fas fa-plus fa-1x mr-2 "
                                    data-toggle="modal" data-target="#modalCrudPositions" data-title="Criar cargo"
                                    onClick={e => this.clearPositions(e)}
                                />

                                <button className={`btn ${this.state.toggleFilter ? 'btn-login' : 'btn-outline-primary'} mr-2 btn-sm`} onClick={e => this.toggleFilter(e)}>
                                    <i className="fas fa-filter fa-1x" />
                                </button>

                            </div>
                        </div>
                    </div>

                    <div className={` ${this.state.toggleFilter ? 'd-none' : ''} row my-2 `}>
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 px-1">
                            <div className="input-group input-group-sm">
                                <input type="text" className="form-control" id="search" autoComplete="off"
                                    placeholder="Pesquisar por cargo"
                                    value={this.state.pagination.search || ''}
                                    onChange={(e) => this.updateFilter(e, 'pagination')} />
                                <div className="input-group-append">
                                    <button className="btn btn-sm btn-login" type="button"><i className="fas fa-search" /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        )
    }

    pagination(activePage) {
        const stateData = this.state.pagination.search ? 'filter' : 'data'
        const pagination = { ...this.state.pagination }

        const minListTest = activePage * this.state.pagination.limit - this.state.pagination.limit

        const rowsData = this.state.pagination[stateData].slice(minListTest, minListTest + this.state.pagination.limit)

        pagination.activePage = activePage
        pagination.page = rowsData

        this.setState({ pagination })
    }

    async updateFilter(e, nameList) {
        const list = { ...this.state[nameList] }
        list.search = e.target.value
        if (e.target.value !== '') {
            list.filter = list.data.filter(total =>
                total.name.toLowerCase().indexOf(list.search.toLowerCase()) > -1
                || total.id.toString().toLowerCase().indexOf(list.search.toLowerCase()) > -1
            )
        } else {
            list.filter = []
        }
        await this.setState({ [nameList]: { ...list } })
        this.pagination(this.state.pagination.activePage)
    }

    renderTable() {
        return (
            <div className="content">
                <div className="table-responsive-sm" style={{ overflowX: 'initial' }}>
                    <table className="table table-hover table-sm">
                        <thead>
                            <tr>
                                <th scope="col">Cargo</th>
                                <th scope="col">Nome</th>
                                <th scope="col">Status</th>
                                <th scope="col">Criado</th>
                                <th scope="col">Opções</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderRows()}
                        </tbody>
                        <tfoot>
                            <tr>
                                <th className="align-middle text-center" colSpan="2">{`${this.state.pagination.page.length} de ${this.totalOfItemsTable()}`}</th>
                                <th className="align-middle text-center" colSpan="3">
                                    <Pagination
                                        activePage={this.state.pagination.activePage}
                                        itemsCountPerPage={this.state.pagination.limit}
                                        totalItemsCount={parseInt(this.totalOfItemsTable())}
                                        innerClass={"pagination pagination-sm justify-content-center"}
                                        prevPageText={<i className="fa fa-chevron-left" />}
                                        firstPageText={<i className="fa fa-angle-double-left" />}
                                        nextPageText={<i className="fa fa-chevron-right" />}
                                        lastPageText={<i className="fa fa-angle-double-right" />}
                                        itemClass={"page-item"}
                                        linkClass={"page-link"}
                                        disabledClass={""}
                                        pageRangeDisplayed={5}
                                        onChange={e => this.pagination(e)}
                                    />
                                </th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        )
    }

    async setPosition(e) {
        e.preventDefault()
        const list = this.state.pagination.data
        const positionSelected = await list.find(position => parseInt(e.target.value) === position.id)

        if (!positionSelected) return this.showAlert(true, 'Ocorreu algo inesperado, recarrega e tente novamento ou procure o ADM.', 'alert-warning')

        this.setState({
            positions: {
                id: positionSelected.id,
                name: positionSelected.name,
                status: positionSelected.status
            }
        })
    }

    async setModal(e) {
        e.preventDefault()
        const dataset = e.target.dataset

        await this.setPosition(e)

        this.setState({
            [dataset.state]: {
                ...this.state[dataset.state],
                //id: dataset.id,
                title: dataset.title,
                funccb1: dataset.funccb1 ? this.deletePositionsCb : null
            }
        })
    }

    modalDeleteGroup() {
        return (
            <div>
                <label className="">{`Deseja mesmo excluir o cargo `} <b className="h5 text-CubohColor2 text-bold"> {this.state.positions.name || ''} {`?`}</b>
                    <br /> <br />
                    <p className="text-danger"><strong>Atenção: Se confirmar, este cargo será removido de todos os testes vinculados.</strong></p>
                </label>
            </div>
        )
    }

    renderRows() {

        if (this.state.pagination.page.length) {

            return this.state.pagination.page.map(positions => {

                return (
                    <tr className="align-middle" key={positions.id}>
                        <td className="align-middle">{positions.idByUser}</td>
                        <td className="align-middle">{positions.name}</td>
                        <td className="align-middle">{positions.status ? 'Ativo' : 'Inativo'}</td>
                        <td className="align-middle">{formatDate(positions.createdAt)}</td>
                        <td className="align-middle">
                            <div className="btn-group dropleft ml-2 float-center" role="group">
                                <button id="btnGroupDrop1" type="button" className="btn btn-sm m-0 btn-login dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                    <i className="fas fa-bars" />
                                </button>
                                <div className="dropdown-menu dropdown-menu-down float-left" aria-labelledby="btnGroupDrop1">

                                    <button className="dropdown-item" value={positions.id} onClick={e => this.setModal(e)}
                                        data-toggle="modal" data-target="#modalCrudPositions"
                                        data-state="modalCrud" data-title="Editar Cargo">
                                        <i className="fas fa-pen fa-1x" /> | Editar
                                    </button>

                                    <div role="separator" className="dropdown-divider" />

                                    <button className="dropdown-item" value={positions.id} onClick={e => this.setModal(e)}
                                        data-toggle="modal" data-target="#ModalDeletePositions"
                                        data-state="modalDelete" data-title="Excluir cargo"
                                        data-funccb1="delete" data-id="ModalDeletePositions">
                                        <i className="fas fa-trash text-danger" /> | Excluir cargo
                                    </button>

                                </div>
                            </div>

                        </td>
                    </tr>
                )
            })
        } else {
            return (
                this.state.pagination.search && !this.state.pagination.page.length ?
                    <tr className="align-middle">
                        <td className="align-middle text-CubohColor2" colSpan="4" >Sem dados para exibir.</td>
                    </tr>
                    :
                    <LoadingIconList loadingIconList={this.state.loadingIconList} />
            )
        }
    }

    render() {
        return (
            <Content>
                <Loading {...this.state.loading} />
                <Toastify />
                <Alert
                    msg={this.state.alert.msg}
                    class={this.state.alert.class}
                    onClick={e => this.showAlert(false)}
                    visible={this.state.alert.msg ? '' : 'd-none'}
                />
                {this.renderHeaderTable()}
                {this.renderTable()}
                <ModalForms modal={this.state.modalCrud}>
                    < CreatePositions {...this.props} positions={this.state.positions} insertPositionCb={this.insertPositionCb} />
                </ModalForms>
                <ModalForms {...this.props} modal={this.state.modalDelete}>
                    {this.modalDeleteGroup()}
                </ModalForms>
            </Content>
        )
    }
}