import React, { Component } from 'react'
import avatarDefault from '../../assets/imgs/logoHeader.png'
import examplaLogoHeader from '../../assets/imgs/exampleLogoHeader.jpg'
import './SetHeader.css'
import Alert from '../tools/Alert'
import API from '../../utils/API'
import { ifError } from '../tools/utils'
import Loading from '../tools/Loading'

const initialState = {
    customReport: {
        id: null,
        idUser: '',
        name: '',
        company: '',
        imageUrl: '',
    },
    imageFile: null,
    imageUpdate: null,
    imagePreview: true,
    loading: {
        class: 'd-none',
        msg: 'loading...'
    },
    alert: {
        class: 'alert-warning',
        msg: null,
        onClick: null,
        visible: alert.msg ? '' : 'd-none',
    },
}

export default class setHeader extends Component {

    state = { ...initialState }

    async componentDidMount() {
        this.showAlert(false)
        await this.getCustomReport(this.props.match.params.id)
    }

    async getCustomReport(id) {
        let customReport = this.state.customReport

        await API.get(`/customReport/${id}`)
            .then(resp => {
                customReport = {
                    id: resp.data.id || null,
                    idUser: resp.data.idUser || null,
                    name: resp.data.name,
                    company: resp.data.company,
                    imageUrl: resp.data.imageUrl,
                }
                this.setState({ customReport })
            })
            .catch(err => {
                console.log('Err =>', err.response)
            })
    }

    fileSelected(e) {
        const file = e.target.files[0]
        if (!file) return

        const customReport = this.state.customReport
        const format = file.type.substr(0, 5) === 'image' ? 'img' : null
        const fileType = ['image/jpeg', 'image/png', 'image/jpg']
        const maxByte = 1024 * 1024 * 2 //Máximo de 2mb 

        //Check pra saber se a imagem é valida      
        if (!format) {
            this.showAlert(true, 'Tipo de extensão não permitada.', 'alert-danger')
            return
        } else if (!fileType.includes(file.type)) {
            this.showAlert(true, `Tipo não permitido. (permitido: ${fileType})`, 'alert-danger')
            return
        } else if (file.size > maxByte) {
            this.showAlert(true, `Tamanho do arquivo não permitido. (máximo: ${maxByte / 1048576}Mb)`, 'alert-danger')
            return
        }
        else {
            const imageUpdate = URL.createObjectURL(file)
            customReport.imageUrl = file
            this.setState({ customReport, imageUpdate })
        }
    }

    showAlert(show, msg, color) {
        const alert = this.state.alert
        if (show === true) {
            alert.msg = msg
            alert.class = color
            this.setState({ alert })
        } else {
            alert.msg = null
            this.setState({ alert })
        }
    }

    removeImage(e) {

        e.preventDefault()
        const customReport = this.state.customReport
        customReport.imageUrl = ''
        this.setState({ customReport, imageUpdate: null })
    }

    async updateField(event) {
        const customReport = { ...this.state.customReport }
        customReport[event.target.id] = event.target.value
        this.setState({ customReport })
    }

    loading(show, msg) {
        const loading = this.state.loading
        if (show === true) {
            loading.msg = msg
            loading.class = ''
            this.setState({ loading })
        } else {
            loading.class = 'd-none'
            this.setState({ loading })
        }
    }

    validForm(e) {
        const { customReport } = this.state
        let ret = false

        if (!customReport.imageUrl && !customReport.name && !customReport.company) {
            this.showAlert(true, 'Informar ao menos um campo.', 'alert-warning')
        } else {
            ret = true
        }
        return ret
    }

    save(e) {

        e.preventDefault()
        this.showAlert(false)
        const customReport = this.state.customReport

        if (!customReport.idUser) customReport.idUser = this.props.match.params.id

        const fd = new FormData()
        const method = customReport.id ? 'put' : 'post'
        const url = `/customReport/${this.props.match.params.id}`

        //if (this.validForm()) {

        Object.keys(customReport).forEach(function (item) {
            if (typeof customReport[item] === 'object' && customReport[item] !== null) {
                fd.append(item, customReport[item], customReport[item].name)
            } else if (customReport[item] !== null) {
                fd.append(item, customReport[item] || "")
            } else {

            }
        })

        API[method](url, fd, {
            onUploadProgress: ProgressEvent => {
                const percent = Math.round(ProgressEvent.loaded / ProgressEvent.total * 100)
                if (percent < 100) {
                    this.loading(true, `Enviando arquivo... ${percent}%`)
                } else {
                    this.loading(true, `Processando dados...`)
                }
            }
        })
            .then(async resp => {
                this.loading(false)
                await this.getCustomReport(customReport.idUser)
                this.showAlert(true, 'Seu cabeçalho foi customizado com sucesso.', 'alert-success')
                this.setState({ imagePreview: null, imageUpdate: null })
            })
            .catch(err => {
                this.loading(false)
                console.log('err =', err)
                const backWs = ifError(err)
                this.showAlert(true, backWs, 'alert-danger')
            })
        //}

    }

    renderForms() {
        return (
            <div id="SetHeader">
                <div className="container-fluid">


                    <div className="row mt-3">

                        <div className="col-xs-12 col-sm-12 col-md-5">
                            <div className="card" >
                                <div className="card-body">
                                    <h5 className="card-title text-CubohColor2">Customize seu cabeçalho</h5>
                                    <p className="card-text">Queremos você junto conosco, sua logo aparecerá no cabeçalho de todas as páginas.</p>
                                </div>
                                <img className="card-img-bottom" src={examplaLogoHeader} alt="Logo preview" />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-7 mt-3">

                            <div className="col-xs-12 col-sm-12 col-md-10">
                                <div className="form">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text btn-login">Texto 1</span>
                                                    </div>
                                                    <input type="text" id="name" value={this.state.customReport.name || ''} onChange={e => this.updateField(e)} placeholder="João Silva" className="form-control" />
                                                </div>
                                                <small className=" d-flex form-text text-muted">*Caso queira somente logo, deixe vazio.</small>
                                            </div>
                                        </div>

                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                            <div className="form-group">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text btn-login">Texto 2</span>
                                                    </div>
                                                    <input type="text" id="company" value={this.state.customReport.company || ''} onChange={e => this.updateField(e)} placeholder="Js Consultoria | @jsconsult" className="form-control" />
                                                </div>
                                                <small className=" d-flex form-text text-muted">*Caso queira somente logo, deixe vazio.</small>
                                            </div>
                                        </div>

                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                                            <div className="input-group mb-3">

                                                <div className="input-group-prepend">
                                                    <span className="input-group-text btn-login">Logo</span>
                                                </div>

                                                <div className="input-group-prepend">
                                                    <input name="imageUrl" onChange={e => this.fileSelected(e)} id="inputPictureHeader" type="file" className="form-control btn btn-primary" />
                                                </div>

                                                <div className="input-group-prepend">
                                                    <label htmlFor="inputPictureHeader" className="input-group-text inputFileImageUrl" type="button">
                                                        {
                                                            this.state.customReport.imageUrl || this.state.imageUpdate ?
                                                                <i>{this.state.imageUpdate ? this.state.customReport.imageUrl.name : this.state.customReport.imageUrl ? this.state.customReport.imageUrl.split('_').slice(3, 4).join(' ') : 'Clique e insira sua logo na capa'}</i>
                                                                :
                                                                <small className='text-muted'>
                                                                    Clique para inserir sua logo
                                                            </small>
                                                        }
                                                    </label>
                                                </div>

                                                <div className="input-group-prepend">
                                                    <label className="input-group-text btn-primary" type="button">
                                                        <i className="fas fa-image fa-lg" data-toggle="collapse" data-target="#collapseLogoHeader" aria-expanded="true" aria-controls="collapseLogoHeader" />
                                                    </label>
                                                </div>

                                                <div className="input-group-prepend">
                                                    <label htmlFor="clearImgPictureHeader" className="input-group-text btn-danger" type="button">
                                                        <i className="fas fa-trash fa-lg" />
                                                    </label>
                                                    <input name="imageUrl" onChange={e => this.removeImage(e)} id="clearImgPictureHeader" className="form-control btn btn-danger" type="checkbox" />
                                                </div>

                                                <div className="input-group-prepend">
                                                    <div id="collapseLogoHeader" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                                        <div className="card-body ">
                                                            {
                                                                this.state.customReport.imageUrl || this.state.imageUpdate ?
                                                                    <img id="crudLogoHeader" src={this.state.imageUpdate ? this.state.imageUpdate : this.state.customReport.imageUrl ? `${process.env.REACT_APP_API_URL}/logo/${this.state.customReport.imageUrl}` : avatarDefault} className="" alt="avatar Perfil" />
                                                                    :
                                                                    'Sem logo disponivel'
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <small id="emailHelp" className=" d-flex form-text text-muted">
                                                    *Ideal .png(tamanho 250x150) ou      imagem com fundo branco.
                                            </small>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <hr />
                            <div className="row">
                                <div className="col-12 d-flex justify-content-end">
                                    <button className="btn btn-success ml-2" type="submit"
                                        onClick={e => this.save(e)}>
                                        Salvar
                                </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >

        )
    }

    render() {
        return (
            <React.Fragment>
                <Loading {...this.state.loading} />
                <Alert
                    msg={this.state.alert.msg}
                    class={this.state.alert.class}
                    onClick={e => this.showAlert(false)}
                    visible={this.state.alert.msg ? '' : 'd-none'}
                />
                {this.renderForms()}
            </React.Fragment>
        )
    }

}