import React, { Component } from 'react'
import logo from '../../assets/imgs/avatar/avatarSmille.png'
import Content from '../template/jsx/Content'
import './Contact.css'

const token = !!JSON.parse(localStorage.getItem('@disc-app/signin')) ? JSON.parse(localStorage.getItem('@disc-app/signin')) : '0'

export default class Contact extends Component {
    render() {
        return (
            <Content>
                <div id="Contact" className="align-middle my-3">
                    <div className="container-fluid ">
                        <div className="row d-flex bd-highlight">
                            <div className="col-sm-12 col-md-5 col-lg-5 p-2  bd-highlight">
                                <div className="d-flex justify-content-center">
                                    <img src={logo} alt="logo" className=" avatar-cuboh" />
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-7 col-lg-7 col-xl-7 text-center mt-auto p-2 bd-highlight">
                                <h2 className="section-title btn-outline-info font-weight-bold">{`Olá, ${token.name}`}</h2>
                                <p className="text-muted h5">Estamos disponíveis nos canais abaixo: </p>

                                <div className="d-flex justify-content-center">
                                    <a href="mailto:contato@cuboh.com.br?&subject=Informe%20o%20motivo%20do%20contato&body=descreva%20como%20podemos%20ajudar%20você%20;)"
                                        className="btn-block btn btn-outline-info mt-3" style={{ maxWidth: '250px' }}>
                                        <i className="far fa-envelope fa-lg mr-2" />
                                        contato@cuboh.com.br
                                    </a>
                                </div>

                                <div className="d-flex justify-content-center">
                                    <a href="tel:85997464894" className="btn-block btn btn-outline-info mt-2" style={{ maxWidth: '250px' }}>
                                        <i className="fas fa-phone fa-lg mr-2" />
                                        (85) 9.9746-4894
                                    </a>
                                </div>

                                <div className="d-flex justify-content-center">
                                    <a href="https://api.whatsapp.com/send?phone=5585997464894&text=Como%20a%20CUBOH%20pode%20ajudar%20%3F%C2%A0"
                                        className="btn-block btn btn-outline-info mt-2 " style={{ maxWidth: '250px' }}>
                                        <i className="fab fa-whatsapp fa-lg mr-2" />
                                        Whatsapp
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </Content>
        )
    }
}