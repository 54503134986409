//import ReactDOM from 'react-dom'
import React, { Component } from 'react'
//import Index from '../components/template/jsx/Index'

export default function (ComposedComponent) {

    class RequireAuth extends Component {

        state = {
            isAuthenticated: false
        }

        isLogged() {
            if(localStorage.getItem("@disc-app/signin") === null){
                //this.props.history.push({ pathname: "/Login" })
                this.redirectToLogin()
            }else{
                const token = JSON.parse(localStorage.getItem('@disc-app/signin'))
                //verifica se o token está expirado
                if(new Date(token.exp * 1000) < new Date()) {
                    //expirado
                    localStorage.removeItem("@disc-app/signin")
                    this.redirectToLogin()
                }else {
                    //não expirado
                }
            }
        }

        redirectToLogin() {
            window.location.replace('/Login')
        }

        componentDidMount() {
            this.isLogged()
        }

        render() {
            return <ComposedComponent {...this.props} />
        }

    }

    return RequireAuth
}