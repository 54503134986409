import React, { Component } from 'react'
import './CreditsCrud.css'
import Content from '../template/jsx/Content'
import { Link } from 'react-router-dom'
import API from '../../utils/API'
import Alert from '../tools/Alert'
import { ifError, formatDate } from '../tools/utils'


const initialState = {
    credits: {
        id: '',
        userId: '',
        adminId: '',
        cashValue: 0,
        quantity: '',
        balanceUsed: '',
        balanceCurrent: '',
        status: '',
        payForm: '',
        typeSale: '',
        createdAt: '',
        updatedAt: '',
        note: '',
    },
    users: {
        id: '',
        balanceUsed: 0,
        balanceCurrent: 0,
        list: [],
    },
    alert: {
        class: 'alert-warning',
        msg: null,
        onClick: null,
        visible: alert.msg ? '' : 'd-none',
    },
}

export default class CreditsCrud extends Component {
    state = { ...initialState }

    async componentDidMount() {
        this.showAlert(false)

        await this.getUsersAndCredits()
        if (this.props.match.params.id) {
            await this.getCredits(this.props.match.params.id)
        }

        //caso tenha salvo, recarregado página e deixado mensagem
        if (this.props.location.msg) this.showAlert(true, this.props.location.msg || 'Salvo com Sucesso', this.props.location.alert || 'alert-warning')

        //recarregar página pois o usuário ficou selecionado
        if (this.state.credits.userId && !this.state.users.id) window.location.reload()

    }

    async getCredits(id) {
        await API.get(`/credits/${id}`)
            .then(suc => this.setState({
                credits: suc.data,
                users: {
                    id: suc.data.userId,
                    balanceUsed: suc.data.balanceUsed,
                    balanceCurrent: suc.data.balanceCurrent,
                    list: this.state.users.list
                }
            }))
            .catch(err => {
                console.log(err)
                this.showAlert(true, 'Ocorreu algo inesperado, contacte o administrador.')
            })

        if (this.state.credits.balanceUsed !== 0 ) {
            this.showAlert(true, 'Com crédito utilizado, não é possivel editar.', 'alert-warning')
        }

    }

    async getUsersAndCredits() {
        const users = { ...this.state.users }
        await API.get('/usersAndCredits')
            .then(suc => this.setState({
                users: {
                    ...users,
                    list: suc.data.data,
                }
            }))
            .catch(err => {
                console.log(err)
            })
    }

    async updateField(event) {
        const credits = { ...this.state.credits }
        credits[event.target.id] = event.target.value
        this.setState({ credits })
    }

    setquantity(e, op) {
        const credits = { ...this.state.credits }
        if (op === true) { credits.quantity++ } else { credits.quantity-- }
        if (credits.quantity < 0) credits.quantity = 0
        this.setState({ credits })
    }

    renderUserList() {
        return this.state.users.list.map(user => {
            return (
                <option value={user.id} key={user.id}>{user.name}</option>
            )
        })
    }

    async setUser(e) {

        if (parseInt(e.target.value)) {
            let { users, credits } = this.state
            const userSelected = users.list.filter(user => user.id === parseInt(e.target.value))
            credits.userId = userSelected[0].id
            users = {
                list: this.state.users.list,
                ...userSelected[0]
            }
            await this.setState({ users, credits })
        }
    }

    async save(e) {
        e.preventDefault()
        const credits = { ...this.state.credits }
        const url = credits.id ? `/credits/${credits.id}` : '/credits'
        const method = credits.id ? 'put' : 'post'

        //campos em comum tanto da criação como edição
        credits.adminId = JSON.parse(localStorage.getItem('@disc-app/signin')).id

        //lógica distinta na criação e edição
        if (!this.props.match.params.id) { //criação
            credits.balanceCurrent = parseInt(credits.quantity) + (parseInt(this.state.users.balanceCurrent ? this.state.users.balanceCurrent : 0) - parseInt(this.state.users.balanceUsed ? this.state.users.balanceUsed : 0))
            credits.status = true
            credits.balanceUsed = 0
        } else { //edição
            credits.balanceCurrent = parseInt(this.state.users.balanceCurrent ? this.state.users.balanceCurrent : 0)
        }

        //validate required fields
        if (!this.validFields(credits)) return

        //Deletando Campos desnecessário
        delete credits.createdAt
        delete credits.updatedAt

        await API[method](url, credits)
            .then(resp => {
                if (method === 'post') {
                    this.props.history.push({ pathname: `/creditsEdit/${resp.data.id}`, msg: resp.data.msg || 'Credito salvo e disponível pra uso.', alert: resp.data.alert || 'alert-success' })
                } else {
                    this.getCredits(credits.id)
                    this.showAlert(true, resp.data.msg || 'Salvo com Sucesso!', resp.data.alert || 'alert-success')
                }
            })
            .catch(err => {
                console.log(err)
                const backWs = ifError(err)
                this.showAlert(true, backWs, 'alert-warning')
            })
    }

    validFields(credits) {
        let ret = false

        if (!credits.userId) {
            this.showAlert(true, 'Usuário não informado, dado obrigatório.', 'alert-danger')
        } else if (!credits.quantity) {
            this.showAlert(true, 'Quantidade não informada, dado obrigatório.', 'alert-danger')
        } else if (!credits.cashValue) {
            this.showAlert(true, 'Valor R$ não informado, dado obrigatório.', 'alert-danger')
        } else if (!credits.payForm) {
            this.showAlert(true, 'Forma de pagamento não informada, dado obrigatório.', 'alert-danger')
        } else if (!credits.typeSale) {
            this.showAlert(true, 'Tipo de venda não informada, dado obrigatório.', 'alert-danger')
        } else if (!credits.balanceCurrent) {
            this.showAlert(true, 'Saldo Atual não identificado, contacte o administrador.', 'alert-danger')
        } else if (!credits.adminId) {
            this.showAlert(true, 'Admin não identificado, contacte o administrador.', 'alert-danger')
        } else {
            ret = true
        }

        return ret
    }

    showAlert(show, msg, color) {
        const alert = this.state.alert
        if (show === true) {
            alert.msg = msg
            alert.class = color
            this.setState({ alert })
        } else {
            alert.msg = null
            this.setState({ alert })
        }
    }

    async cancelCredit(e) {
        e.preventDefault()

        await API.put(`/cancelCredit/${this.state.credits.id}`)
            .then(async suc => {
                await this.getCredits(this.state.credits.id)
                this.showAlert(true, suc.data.msg || 'Salvo com sucesso.', suc.data.alert || 'alert-warning')
            })
            .catch(err => {
                console.log(err)
                const msg = ifError(err)
                this.showAlert(true, msg, 'alert-warning')
            })
    }

    async removeCredit(e) {
        e.preventDefault()

        await API.put(`/removeCredit/${this.state.credits.id}`)
            .then(async suc => {
                this.props.history.push({ pathname: `/creditsList`, msg: suc.data.msg || 'Credito excluido com sucesso.', alert: suc.data.alert || 'alert-success' })
            })
            .catch(err => {
                console.log(err)
                const msg = ifError(err)
                this.showAlert(true, msg, 'alert-warning')
            })
    }

    renderForms() {
        return (
            <form>
                <div className="form">
                    <div className="row">

                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <label className="input-group-text btn-login" htmlFor="userList"><i className="fas fa-user fa-lg" /></label>
                                </div>
                                <select className="custom-select" value={this.state.credits.userId || ''}
                                    disabled={this.state.credits.id ? true : ''} id="userList"
                                    onChange={e => this.setUser(e)}>
                                    <option value="0" defaultValue>Selecionar Usuário...</option>
                                    {this.renderUserList()}
                                </select>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 ">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text btn-login">Utilizado</span>
                                </div>
                                <input type="text" id="oldAmountActual" value={this.state.users.balanceUsed || ''} placeholder="0" readOnly className="form-control" />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 ">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text btn-login">Saldo</span>
                                </div>
                                <input type="text" id="oldAmountActual" value={this.state.users.balanceCurrent || ''} placeholder="0" readOnly className="form-control" />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text btn-login"><i className="fas fa-donate fa-lg" /></span>
                                </div>
                                <input type="number" id="quantity" value={this.state.credits.quantity || ''} className="form-control"
                                    placeholder="Créditos para inserir" onChange={e => this.updateField(e)}
                                    disabled={this.state.credits.id ? true : ''} />
                                <div className="input-group-prepend">

                                    <button type="button" id="quantity" className="btn btn-login"
                                        onClick={e => this.setquantity(e, true)}
                                        disabled={this.state.credits.id ? true : ''}><i className="fas fa-plus" /></button>

                                    <button type="button" id="quantity" className="btn btn-login"
                                        onClick={e => this.setquantity(e, false)}
                                        disabled={this.state.credits.id ? true : ''}><i className="fas fa-minus" /></button>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 ">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text btn-login">R$</span>
                                </div>
                                <input type="number" id="cashValue" onChange={e => this.updateField(e)} value={this.state.credits.cashValue || ''}
                                    disabled={this.state.credits.balanceUsed > 0 && this.state.credits.id ? true : ''}
                                    className="form-control" placeholder="000,00" />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 ">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text btn-login">Status</span>
                                </div>
                                <input type="tel" id="status" value={this.state.credits.status ? 'Ativo' : 'Cancelado'} className="form-control" readOnly placeholder="Criado" />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text btn-login"><i className="fas fa-calendar-plus fa-lg" /></span>
                                </div>
                                <input type="tel" id="createdAt" value={formatDate(this.state.credits.createdAt) || ''} placeholder="Data de criação" className="form-control" readOnly />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text btn-login"><i className="fas fa-calendar-check fa-lg" /></span>
                                </div>
                                <input type="tel" id="updatedAt" value={formatDate(this.state.credits.updatedAt) || ''} placeholder="Data de Atualização" className="form-control" readOnly />
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <label className="input-group-text btn-login" htmlFor="payList"><i className="fas fa-search-dollar fa-lg" /></label>
                                </div>
                                <select className="custom-select" value={this.state.credits.payForm || ''} id="payForm"
                                    onChange={e => this.updateField(e)}
                                    disabled={this.state.credits.balanceUsed > 0 && this.state.credits.id ? true : ''}>
                                    <option defaultValue>Forma de pagamento...</option>
                                    <option value="01">Pagseguro</option>
                                    <option value="02">Itau</option>
                                    <option value="03">BB</option>
                                    <option value="04">Caixa</option>
                                    <option value="05">Nubank</option>
                                    <option value="06">Bradesco</option>
                                    <option value="07">Santander</option>
                                    <option value="08">Sicredi</option>
                                    <option value="09">Dinheiro</option>
                                    <option value="10">Outros</option>
                                    <option value="11">Inter</option>
                                    <option value="12">Não Aplica</option>
                                </select>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <label className="input-group-text btn-login" htmlFor="payType"><i className="fas fa-handshake fa-lg" /></label>
                                </div>
                                <select className="custom-select" value={this.state.credits.typeSale || ''} id="typeSale"
                                    disabled={this.state.credits.balanceUsed > 0 && this.state.credits.id ? true : ''}
                                    onChange={e => this.updateField(e)}>
                                    <option defaultValue>Tipo de venda...</option>
                                    <option value="01">Avulsa</option>
                                    <option value="02">Recorrente</option>
                                    <option value="03">Bonificação</option>
                                    <option value="04">Treinamento</option>
                                    <option value="05">Prospecção</option>
                                    <option value="06">Outros</option>
                                </select>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="form-group">
                                <textarea
                                    className="form-control" rows="4" maxLength="250"
                                    id="note"
                                    placeholder="Área para salvar rascunhos ou anotações gerais..."
                                    value={this.state.credits.note || ''}
                                    onChange={e => this.updateField(e)} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 d-flex justify-content-end">
                            <Link to='/creditsList'>
                                <button type="submit" className="btn btn-login mr-2">
                                    <i className="fas fa-arrow-left" /> Voltar
                                </button>
                            </Link>

                            <div className="btn-group" role="group">
                                <button id="optionsCredits" type="button" className="btn btn-login dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"
                                    disabled={this.state.credits.balanceUsed !== 0 || !this.state.credits.id ? true : ''}>
                                    <i className="fas fa-cog" /> Opções
                                    </button>
                                <div className="dropdown-menu" aria-labelledby="optionsCredits">

                                    <button className="dropdown-item" onClick={e => this.cancelCredit(e)}>
                                        <i className="fas fa-ban mr-2" /><b>Cancelar Crédito</b>
                                    </button>

                                    <div className="dropdown-divider" />

                                    <button className="dropdown-item text-danger"
                                        data-toggle="modal" data-target="#exampleModal" onClick={e => this.removeCredit(e)}>
                                        <i className="fas fa-trash mr-2 " /><b>Excluir Crédito</b>
                                    </button>

                                </div>

                            </div>
                            <button type="submit" className="btn btn-login ml-2"
                                onClick={e => this.save(e)}>
                                <i className="fas fa-save" /> Salvar
                            </button>
                        </div>
                    </div>
                </div>
            </form >
        )
    }

    render() {
        return (
            <Content>
                <Alert
                    msg={this.state.alert.msg}
                    class={this.state.alert.class}
                    onClick={e => this.showAlert(false)}
                    visible={this.state.alert.msg ? '' : 'd-none'}
                />
                {this.renderForms()}
            </Content>
        )
    }
}