import axios from "axios";

const backSignin = JSON.parse(localStorage.getItem('@disc-app/signin')) ? JSON.parse(localStorage.getItem('@disc-app/signin')) : true

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: "json",
  ContentType: "application/json",
  timeout: (1000 * 60 * 2),
  headers: {
    Authorization: `bearer ${backSignin.token}`
  }
})

export default API