import React, { Component } from 'react'
import API from '../../utils/API'

const initialState = {
    modeButton: true, // true = left
    test: {
        id: '',
        name: '',
        email: '',
        note: '',
        genre: '',
        groupId: null,
        positionId: null,
    },
    showClassification: false,
    groupLists: [],
    positionsLists: [],
}

export default class TestsSingle extends Component {

    state = { ...initialState }

    toogleShowClassification = () => {
        if (!this.state.groupLists.length && !this.state.positionsLists.length) {
            this.getGroupsList()
            this.getPositionsList()
        }
        this.setState({ showClassification: !this.state.showClassification })
    }

    componentDidUpdate(e) {

        if (this.props.test.id !== this.state.test.id) {

            if (this.props.test.id) {

                this.setState({ test: { ...this.props.test } })
            } else {
                if (this.state.test.id) this.setState({ test: initialState.test })
            }
        }
    }

    async getGroupsList() {
        await API.get('/getGroupsLists')
            .then(resp => {
                if (resp.data.length) {
                    this.setState({
                        groupLists: [
                            ...resp.data
                        ]
                    })
                } else {
                    this.setState({ loadingIconList: true })
                }
            })
            .catch(err => {
                console.log(err)
                this.props.notify('warning', 'Ocorreu algo inesperado ao tentar listar os grupos, contacte o administrador.')
                this.setState({ loadingIconList: true })
            })
    }

    async getPositionsList() {
        await API.get('/getPositionsLists')
            .then(resp => {
                if (resp.data.length) {
                    this.setState({
                        positionsLists: [
                            ...resp.data
                        ]
                    })
                } else {
                    this.setState({ loadingIconList: true })
                }
            })
            .catch(err => {
                console.log(err)
                this.props.notify('warning', 'Ocorreu algo inesperado ao tentar listar os créditos, contacte o administrador.')
                this.setState({ loadingIconList: true })
            })
    }

    async updateField(event) {
        const test = { ...this.state.test }
        test[event.target.id] = event.target.value
        await this.setState({ test })
    }

    async save(e) {
        e.preventDefault()
        const save = await this.props.save({ ...this.state.test }, 'single')

        if (save) {
            this.setState({ test: initialState.test })
            this.props.notify('success', 'Teste incluído.')
            document.getElementById('buttonCloseModal').click()
        }

    }

    renderForms() {

        const genre = this.state.test.genre || ''

        return (
            <div className='container-fluid'>
                <div className="row py-0">

                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 px-0 pr-md-2 mb-0 ">
                        <div className="form-group">
                            <small className="d-flex form-text text-CubohColor2 m-0">Nome: <span className="badge text-danger">*</span></small>
                            <div className="input-group input-group-sm">
                                <input type="text" id="name" autoComplete="new-password" value={this.state.test.name || ''} onChange={e => this.updateField(e)} placeholder="Maria Silva" className="form-control" />
                            </div>
                            <div className="ml-1">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="masculino" id="genre" value="masculino" checked={genre.toLowerCase() === 'masculino' ? true : false} onChange={e => this.updateField(e)} />
                                    <small className="form-check-label" htmlFor="inlineCheckbox1">Masc.</small>
                                </div>

                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="feminino" id="genre" value="feminino" checked={genre.toLowerCase() === 'feminino' ? true : false} onChange={e => this.updateField(e)} />
                                    <small className="form-check-label" htmlFor="inlineCheckbox2">Femin.</small>
                                </div>

                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="outros" id="genre" value="outros" checked={genre.toLowerCase() === 'outros' ? true : false} onChange={e => this.updateField(e)} />
                                    <small className="form-check-label" htmlFor="inlineCheckbox3">Outros</small>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 px-0 mb-0">
                        <div className="form-group">
                            <small className="d-flex form-text text-CubohColor2 m-0">E-mail: <span className="badge text-danger">*</span></small>
                            <div className="input-group input-group-sm">
                                <input className="form-control" type="email" id="email"
                                    disabled={this.props.test.id ? true : false}
                                    autoComplete="new-password" value={this.state.test.email || ''}
                                    onChange={e => this.updateField(e)} placeholder="maria.silva@exemplo.com.br" aria-describedby="emailHelp" />
                            </div>
                        </div>
                    </div>

                </div>

                <div className="row">

                    <div className="col-12 px-0">
                        <div className="separator">
                            <div className="btn-group btn-group-sm" role="group" aria-label="Basic example" data-toggle="collapse" href="#showClassicationDiv" aria-expanded="false" aria-controls="showClassicationDiv">
                                <button type="button" className="btn btn-rounded-left buttonInative font-weight-normal" onClick={e => this.toogleShowClassification(e)}>Mais informações</button>
                                <button type="button" className="btn btn-rounded-right buttonActive" onClick={e => this.toogleShowClassification(e)}><i className={`fas fa-arrow-${this.state.showClassification ? 'up' : 'down'} down fa-md`} /></button>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="collapse" id="showClassicationDiv">

                    <div className="row px-0">

                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 px-0 mt-2 pr-md-2">
                            <small className="d-flex form-text text-CubohColor2 m-0">Cargo: <span className="badge text-muted">(opcional)</span></small>
                            <div className="input-group input-group-sm">
                                <select className="custom-select custom-select-sm" id="positionId" value={this.state.test.positionId || ''} onChange={e => this.updateField(e)}
                                    aria-label="Select the positions">
                                    <option value="" defaultValue>Escolha o cargo...</option>
                                    {this.renderListOfPositions()}
                                </select>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 px-0 mt-2">
                            <small className="d-flex form-text text-CubohColor2 m-0">Grupo: <span className="badge text-muted">(opcional)</span></small>
                            <div className="input-group input-group-sm">
                                <select className="custom-select custom-select-sm" id="groupId" value={this.state.test.groupId || ''} onChange={e => this.updateField(e)}
                                    aria-label="Select the group"
                                    disabled={this.state.test.id ? true : false}>
                                    <option value="" defaultValue>{this.state.test.id ? 'Edição: menu > grupo' : 'Escolha o grupo...'}</option>
                                    {this.renderListOfGroups()}
                                </select>
                            </div>
                        </div>


                        <div className="col-12 px-0 mt-2">
                            <div className="form-group">
                                <small className=" d-flex form-text text-CubohColor2 m-0">Anotações: <span className="badge text-muted">(opcional)</span></small>
                                <div className="input-group input-group-sm">
                                    <textarea
                                        autoComplete="new-password"
                                        className="form-control" rows="2" maxLength="250"
                                        id="note"
                                        placeholder="Área para salvar rascunhos ou anotações gerais"
                                        value={this.state.test.note || ''}
                                        onChange={e => this.updateField(e)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div >

                <div className="row pt-2">
                    <div className="col-12 d-flex justify-content-end px-0">
                        <button className={`btn btn-sm btn-${this.state.test.name && this.state.test.email ? 'success' : 'secondary'} w-25`} type="submit"
                            onClick={e => this.save(e)}>
                            {this.state.test.id ? 'Salvar' : 'Enviar'}
                        </button>
                    </div>
                </div>

            </div >
        )
    }

    renderListOfPositions() {
        if (this.state.positionsLists.length) {
            return this.state.positionsLists.map(position => {
                return <option key={position.id} value={position.id}>{position.name}</option>
            })
        } else {
            return <option value={null}>Nenhum Cargo cadastrado</option>
        }
    }

    renderListOfGroups() {
        if (this.state.groupLists.length) {
            return this.state.groupLists.map(group => {
                return <option key={group.id} value={group.id}>{group.name}</option>
            })
        } else {
            return <option value={null}>Nenhum Grupo cadastrado</option>
        }
    }

    render() {
        return (
            <div id="TestsSingle">
                {this.renderForms()}
            </div>
        )
    }
}