import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import * as serviceWorker from './serviceWorker'
import App from './main/App'
import { BrowserRouter } from 'react-router-dom'
import Routes from './main/Routes'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import '@fortawesome/fontawesome-free/css/all.css'

if (localStorage.getItem("@disc-app/signin") === null) {
    ReactDOM.render(
        <BrowserRouter>
            <Routes />
        </BrowserRouter>
        , document.getElementById('root'))
} else {
    ReactDOM.render(<App />, document.getElementById('root'));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister(); 
