import React, { Component } from 'react'
import Content from '../template/jsx/Content'
import PostCard from '../postCard/PostCard'
import API from '../../utils/API'
import LoadingIconList from '../tools/LoadingIconList'

const initialState = {
    posts: {
        postFilter: [],
        postCards: [],
    },
    load: false,
    loadingIconList: false,
}

export default class PostWall extends Component {

    state = { ...initialState }

    async componentDidMount() {
        await this.getPosts()
        this.setState({ load: true })
    }

    async getPosts() {
        const posts = this.state.posts

        await API.get('/post')
            .then(resp => {
                if (resp.data.data.length) {
                    this.setState({
                        posts: {
                            ...posts,
                            postCards: resp.data.data,
                            postFilter: resp.data.data
                        }
                    })
                } else {
                    this.setState({ loadingIconList: true })
                }
            })
            .catch(err => {
                console.log(err.response)
                //this.showAlert(true, 'Ocorreu algo inesperado ao tentar listar os testes, contacte o administrador.', 'alert-warning')
                this.setState({ loadingIconList: true })
            })
    }

    renderCards() {
        if (this.state.posts.postFilter.length && this.state.load) {
            return this.state.posts.postFilter.map(posts => {
                return (
                    <PostCard key={posts.id}
                        {...posts}
                        urlImage={posts.imageUrl ? `${process.env.REACT_APP_API_URL}/postCover/${posts.imageUrl}` : null}
                    />
                )
            })
        } else {
            return (
                <div className="float-none mt-4">
                    <LoadingIconList type='div' loadingIconList={this.state.loadingIconList} />
                </div>
            )
        }
    }

    render() {
        return (
            <Content>
                <div id="Home">
                    <div colSpan="3" className="card-columns">
                        {this.renderCards()}
                    </div>
                </div>
            </Content>
        )
    }
}