import React, { Component } from 'react'
import Content from '../template/jsx/Content'
import Pagination from "react-js-pagination"
import API from '../../utils/API'

const initialState = {
    questions: {
        id: '',
        name: '',
        ordem: '',
        optionA: '',
        optionB: '',
        optionC: '',
        optionD: '',
    },
    pagination: {
        activePage: 1,
        limit: 10,
        count: '',
        list: [],
        questionsPage: [],
    }
}

export default class QuestionsList extends Component {

    state = { ...initialState }

    async componentDidMount() {

        const pagination = { ...this.state.pagination }

        await API.get('/questions')
            .then(resp => this.setState({
                pagination: {
                    ...pagination,
                    list: resp.data.data,
                    count: resp.data.count,
                }
            }))
        //console.log('Atualizou o List')
        this.pagination(this.state.pagination.activePage)
    }

    pagination(activePage) {

        const pagination = { ...this.state.pagination }

        const minListTest = activePage * this.state.pagination.limit - this.state.pagination.limit
        const questions = this.state.pagination.list.slice(minListTest, minListTest + this.state.pagination.limit)

        pagination.activePage = activePage
        pagination.questionsPage = questions

        this.setState({ pagination })

    }

    renderQuestions() {

        return this.state.pagination.questionsPage.map(question => {

            return (
                <div className="col-sm-6 mt-3" key={question.id}>
                    <div className="card">
                        <div className="card-body">
                            <h6 className="card-title m-1 "><b>{`${question.ordem} - ${question.name}`}</b></h6>
                            <p className="card-text text-muted m-1"> <b>A) </b>{question.optionA}</p>
                            <p className="card-text text-muted m-1"> <b>B) </b>{question.optionB}</p>
                            <p className="card-text text-muted m-1"> <b>C) </b>{question.optionC}</p>
                            <p className="card-text text-muted m-1"> <b>D) </b>{question.optionD}</p>
                        </div>
                    </div>
                </div>
            )
        })
    }

    renderCards() {
        return (
            <div className="container-fluid">
                <div className="card-deck">
                    {this.renderQuestions()}
                </div>
                <div>
                    <Pagination
                        activePage={this.state.pagination.activePage}
                        itemsCountPerPage={this.state.pagination.limit}
                        totalItemsCount={parseInt(this.state.pagination.count)}
                        innerClass={"pagination mr-5 mt-3 d-flex justify-content-end"}
                        prevPageText={<i className="fa fa-chevron-left" />}
                        firstPageText={<i className="fa fa-angle-double-left" />}
                        nextPageText={<i className="fa fa-chevron-right" />}
                        lastPageText={<i className="fa fa-angle-double-right" />}
                        itemClass={"page-item"}
                        linkClass={"page-link"}
                        disabledClass={""}
                        pageRangeDisplayed={4}
                        onChange={e => this.pagination(e)}
                    />
                </div>
            </div>
        )
    }

    render() {
        return (
            <Content>
                {this.renderCards()}
            </Content>
        )
    }
}