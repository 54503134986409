import React from 'react'
import './Loading.css'
import cubo from '../../assets/imgs/cuboColors.gif'

//<i className="fas fa-cog fa-spin color-header fa-5x"></i>

export default props =>

    <div className={`divLoading ${props.class}`}>
        <div className="iconLoading">
            <img src={cubo || ''} className='imagecuboh' alt='Cubo Gif' />
            <p className="text-loading">{props.msg}</p>
        </div>
    </div>
