import React, { PureComponent } from 'react';
import API from '../../utils/API'
import {
    ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

const InitialState = {
    data: [],
}

export default class DiscAcumulate extends PureComponent {
    static jsfiddleUrl = 'https://jsfiddle.net/alidingling/xqjtetw0/';

    state = { ...InitialState }

    async componentDidMount() {
        await API.get(`/reportDiscAcumulate/${this.props.id}`)
            .then(resp => {
                this.setState({ data: resp.data })
            })
            .catch(err => console.log(err))
    }

    render() {
        return (
            <div style={{ width: '100%', height: '400px' }}>
                <ResponsiveContainer>
                    <LineChart
                        width={800}
                        height={300}
                        data={this.state.data}
                        margin={{ top: 5, right: 50, left: 10, bottom: 5, }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="D" stroke="#DC3545" />
                        <Line type="monotone" dataKey="I" stroke="#007BFF" />
                        <Line type="monotone" dataKey="S" stroke="#FFC107" />
                        <Line type="monotone" dataKey="C" stroke="#28A745" />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        );
    }
}
