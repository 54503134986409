import React, { Component } from 'react'
import './PostViewer.css'
import Content from '../template/jsx/Content'
import { PDFReader } from 'reactjs-pdf-reader'

const initialState = {
    loadingIcon: false,
    LandscapeMode: false,
}

export default class PostViewer extends Component {

    state = { ...initialState }

    renderLandscapeMode() {
        this.setState({ loadingIcon: true })
        if (document.getElementById('pdfViewer').offsetHeight > 2500) {
            this.setState({ LandscapeMode: true })
        }
    }

    render() {
        return (
            <Content>
                <div id="pdfViewer" style={{ overflow: 'scroll', height: '100%', width: 'auto' }}>
                    <div className='d-block d-sm-none'>
                        <div className={`${this.state.LandscapeMode ? '' : 'd-none'} alert alert-primary alert-dismissible" role="alert" `}>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-2 ">
                                        <i className="fas fa-mobile-alt fa-rotate-270 fa-3x" />
                                    </div>
                                    <div className="col-10">
                                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        Conteúdo no <strong>modo paisagem</strong>. Rotacione o celular.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <PDFReader
                        url={`${process.env.REACT_APP_API_URL}/articles/${this.props.match.params.name}`}
                        showAllPage={true}
                        width={window.innerWidth < 750 ? window.innerWidth - 50 : null}
                        onDocumentComplete={e => this.renderLandscapeMode(e)}
                    />
                    <div className="d-flex justify-content-center">
                        <i className={`fas fa-circle-notch fa-pulse color-header fa-5x m-2 ${this.state.loadingIcon ? 'd-none' : ''}`} />
                    </div>

                </div>
            </Content >
        )
    }
}