import React, { Component } from 'react'
import './Welcome.css'
import API from '../../utils/API'
import Alert from '../tools/Alert'
import { testByToken, ifError } from '../tools/utils'

//https://bootsnipp.com/snippets/0ekoq

const initialState = {
    test: {
        name: '',
        idTest: '',
        token: '',
        email: '',
        status: '',
        evaluator_name: '',
        evaluator_company: '',
        evaluator_avatar: '',

    },
    steps: 0,
    alert: {
        class: 'alert-warning',
        msg: null,
        onClick: null,
        visible: alert.msg ? '' : 'd-none',
    }
}

export default class Welcome extends Component {

    state = { ...initialState }
    componentDidMount() {
        const test = JSON.parse(sessionStorage.getItem('@disc-app/test'))
        this.setState({ test })
    }

    async getTest(token) {
        let tests = { ...this.state.test }
        tests.token = token

        await testByToken(tests)
            .then(suc => {
                if (suc.process) {
                    const test = suc.test
                    this.setState({ test })
                } else {
                    this.showAlert(true, suc.msg || 'Erro inesperado', suc.alert)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    showAlert(show, msg, color) {
        const alert = this.state.alert
        if (show === true) {
            alert.msg = msg
            alert.class = color
            this.setState({ alert })
        } else {
            alert.msg = null
            this.setState({ alert })
        }
    }

    rollCarrousel(roll) {
        this.setState({ steps: this.state.steps + roll })
    }

    renderPage() {
        //console.log(this.props.location)
        return (
            <React.Fragment>
                <section id="what-we-do">
                    <div className="container my-auto mx-auto">
                        <Alert
                            msg={this.state.alert.msg}
                            class={this.state.alert.class}
                            onClick={e => this.showAlert(false)}
                            visible={this.state.alert.msg ? '' : 'd-none'}
                        />

                        <div id="stepsToStart" className="carousel py-5" data-ride="false" data-interval="999995000">

                            <div className="carousel-inner">
                                <div className="carousel-item active">

                                    <div className="row mt-md-3 my-auto">
                                        <div className="col-sm-12 col-md-5 col-lg-5">
                                            <div className="d-flex justify-content-center">
                                                <img id="crudAvatarPefil" className=" img-fluid icon-circle" alt="logo" src={this.state.test.evaluator_avatar ? `${process.env.REACT_APP_API_URL}/files/${this.state.test.evaluator_avatar}` : `${process.env.REACT_APP_API_URL}/files/default.png`} />
                                            </div>
                                            <div className="d-flex justify-content-center my-2">
                                                <p className="h6 font-weight-500">{this.state.test.evaluator_name || ''} {this.state.test.evaluator_company ? `- ${this.state.test.evaluator_company}` : ''}</p>
                                            </div>
                                        </div>

                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2 text-center ">
                                            <h3 className="section-title font-weight-bold mb-3">{`Olá ${this.state.test.name || 'Meu amigo'},`}<br />Bem vindo(a)!</h3>
                                            <p className="text-muted h5 px-2">Primeiramente muito obrigado por estar aqui! Irei te passar algumas instruções super importantes para que tudo ocorra bem, vamos lá!</p>
                                        </div>
                                    </div>

                                </div>
                                <div className="carousel-item">

                                    <div className="row mt-md-3 my-auto">
                                        <div className="col-sm-12 col-md-5 col-lg-5">
                                            <div className="d-flex justify-content-center">
                                                <img className="iconStep" alt="logo" src={require('../../assets/imgs/stepOne.png')} />
                                            </div>
                                        </div>

                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2 text-center ">
                                            <h3 className="section-title font-weight-bold mb-3">30 Minutos</h3>
                                            <p className="text-muted h5 px-2">Reserve pelo menos 30 minutos para responder o seu Teste DISC, é importante que reserve este tempo <b>apenas</b> para responder sua análise de perfil.</p>
                                        </div>
                                    </div>

                                </div>
                                <div className="carousel-item">

                                    <div className="row mt-md-3 my-auto">
                                        <div className="col-sm-12 col-md-5 col-lg-5">
                                            <div className="d-flex justify-content-center">
                                                <img className="iconStep" alt="logo" src={require('../../assets/imgs/stepTwo.png')} />
                                            </div>
                                        </div>

                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2 text-center ">
                                            <h3 className="section-title font-weight-bold mb-3">Local calmo e silencioso</h3>
                                            <p className="text-muted h5 px-3">É imprescindível que você responda seu Teste DISC longe de interrupções, pois parar no meio do questionário ou ser interrompido, pode alterar o resultado.</p>
                                        </div>
                                    </div>

                                </div>

                                <div className="carousel-item">

                                    <div className="row mt-md-3 my-auto">
                                        <div className="col-sm-12 col-md-5 col-lg-5">
                                            <div className="d-flex justify-content-center">
                                                <img className="iconStep" alt="logo" src={require('../../assets/imgs/stepThree.png')} />
                                            </div>
                                        </div>

                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2 text-center ">
                                            <h3 className="section-title font-weight-bold mb-3">Transparência</h3>
                                            <p className="text-muted h5 px-3">Responda de forma espontânea, individual e com transparência, leia atentamente as perguntas e responda de forma clara e verdadeira.</p>
                                        </div>
                                    </div>

                                </div>

                                <div className="carousel-item">

                                    <div className="row mt-md-3 my-auto">
                                        <div className="col-sm-12 col-md-5 col-lg-5">
                                            <div className="d-flex justify-content-center">
                                                <img className="iconStep" alt="logo" src={require('../../assets/imgs/stepFour.png')} />
                                            </div>
                                        </div>

                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2 text-center ">
                                            <h3 className="section-title font-weight-bold mb-3">Importante</h3>
                                            <p className="text-muted h5 px-3">Não existem perfis bons ou ruins, ou respostas certas ou erradas, por isso, pedimos para que reserve um tempo e responda de forma contínua e  verdadeira, sem se preocupar com o resultado.</p>
                                        </div>
                                    </div>

                                </div>

                                <div className="carousel-item">
                                    <div className="row mt-md-3 my-auto">
                                        <div className="col-sm-12 col-md-5 col-lg-5">
                                            <div className="d-flex justify-content-center">
                                                <img id="crudAvatarPefil" className=" img-fluid icon-circle" alt="logo" src={this.state.test.evaluator_avatar ? `${process.env.REACT_APP_API_URL}/files/${this.state.test.evaluator_avatar}` : `${process.env.REACT_APP_API_URL}/files/default.png`} />
                                            </div>
                                            <div className="d-flex justify-content-center my-2">
                                                <p className="h6 font-weight-500">{this.state.test.evaluator_name || ''}, {this.state.test.evaluator_company || ''}.</p>
                                            </div>
                                        </div>

                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2 text-center ">
                                            <h3 className="section-title font-weight-bold mb-3">Pronto para começar?!</h3>
                                            <p className="text-muted h5 px-3">Está em um local  calmo, reservado e com pelo menos 30 minutos de tempo disponível? Então, clique no botão abaixo e inicie seu Teste, agora se está um pouco ocupado no momento é melhor voltar daqui a pouco para responder com calma.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="jumbotron jumbotron-fluid p-0 m-0">
                            <div className="progress bg-light" style={{ height: '3px' }}>
                                <div className={`progress-bar progress-bar-striped progress-bar-animated" role="progressbar ${this.state.alert.msg ? 'd-none' : ''}`} aria-valuenow="35" aria-valuemin="0" aria-valuemax="100" style={{ width: `${this.state.steps * 100 / 5}%` }} />
                            </div>

                            <div className="row py-2">
                                <div className={`col text-left ml-2 ${this.state.alert.msg || this.state.steps <= 0 ? 'd-none' : ''}`}>
                                    <button type="button" className="btn btn-link btn-md ml-2 text-right"
                                        href="#stepsToStart" data-slide="prev"
                                        onClick={e => this.rollCarrousel(-1)}><i className="fas fa-arrow-circle-left p-0 m-0 fa-2x section-title" />
                                    </button>
                                </div>
                                <div className={`col text-right mr-2 ${this.state.alert.msg || this.state.steps !== 5 ? 'd-none' : ''}`}>
                                    <button type="button" className="btn btn-success font-weight-bold btn-md"
                                        onClick={e => this.startedTest(e)}>Começar!
                                        </button>
                                </div>
                                <div className={`col text-right mr-2 ${this.state.alert.msg || this.state.steps === 5 ? 'd-none' : ''}`}>
                                    <button type="button" className="btn btn-link btn-md ml-2 text-right"
                                        href="#stepsToStart" data-slide="next"
                                        onClick={e => this.rollCarrousel(+1)}><i className="fas fa-arrow-circle-right p-0 m-0 fa-2x section-title" />
                                    </button>
                                </div>
                            </div>
                        </div>


                    </div>
                </section>
            </React.Fragment>
        )
    }

    async startedTest(e) {
        e.preventDefault()
        const test = this.state.test

        await API.put(`/testStarted/${test.idTest}`, test)
            .then(suc => {
                //window.location.replace('/Answer')
                this.props.history.push({ pathname: "/Answer" })
            })
            .catch(err => {
                const msg = ifError(err)
                this.showAlert(true, msg || 'Não é possivel prosseguir, contacte o suporte', 'alert-warning')
                console.log(err)
            })
    }

    render() {
        return (
            this.renderPage()
        )
    }
}