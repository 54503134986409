import API from '../../utils/API'

export const getDateNowNow = () => {

    const ActualDate = new Date()
    const newDate = `${ActualDate.getDate()}/${ActualDate.getMonth() + 1}/${ActualDate.getFullYear()}`

    return newDate
}

export const getStatus = (status) => {

    /* DEFINIÇÃO DE STATUS : 
    01 - Criado:  Criado pelo aplicante mas não enviado.
    02 - Cancelado - Criado e após cancelado.
    03 - Enviado - Enviado para o e-mail do cliente.
    04 - Iniciado - Cliente iniciou a resposta do formulário.
    05 - Finalizado - Cliente terminou de responder o formulário.
    */

    switch (status) {
        case '01': return 'Criado'
        case '02': return 'Cancelado'
        case '03': return 'Enviado'
        case '04': return 'Iniciado'
        case '05': return 'Finalizado'

        default: return 'Não definido'
    }
}

export const formatDate = (date) => {

    //caso a não seja informado == NULL ou vazio
    if (!date) return ""
    const newDate = new Date(date)
    const dateFormat = new Intl.DateTimeFormat('en-GB', {
        year: '2-digit', month: '2-digit', day: '2-digit',
        hour: 'numeric', minute: 'numeric',
        hour12: false, timeZone: 'America/Fortaleza'
    }).format(newDate)

    return dateFormat
}

export const ifError = (err) => {
    let backWs
    if (err && err.response && err.response.data && err.response.data.msg != null) {
        backWs = err.response.data.msg
        console.log('Erro!!', backWs)
    } else {
        backWs = 'Ocorreu algo inesperado, contacte o administrador.'
        console.log('Erro!!', err)
    }
    return backWs
}

export const FirtAndLasttName = (name) => {

    const firtsName = name.split(' ').slice(0, 1).join(' ')
    const lastName = name.split(' ').slice(-1).join(' ')

    return firtsName + ' ' + lastName
}

export const testByToken = async (test) => {

    let msg = 'Não definido'
    let alert = 'Não definido'
    let process = false

    await API.get(`/tests_byToken/${test.token}`)
        .then(resp => {
            if (resp.data !== null) {
                if (resp.data.status !== "02" && resp.data.status !== "05") {
                    test = {
                        name: resp.data.name,
                        idTest: resp.data.id,
                        token: test.token,
                        email: resp.data.email,
                        status: resp.data.status,
                        evaluator_id: resp.data.evaluator_id,
                        evaluator_name: resp.data.evaluator_name,
                        evaluator_company: resp.data.evaluator_company,
                        evaluator_avatar: resp.data.evaluator_avatar,

                    }
                    msg = 'Logado com sucesso.'
                    alert = 'alert-success'
                    process = true
                } else {
                    msg = 'Teste cancelado ou finalizado, impossível prosseguir.'
                    alert = 'alert-warning'
                    process = false
                }
            } else {
                msg = 'Token Inválido'
                alert = 'alert-danger'
                process = false
            }
            return { process, test, msg, alert }
        })
        .catch(err => {
            console.log(err)
            msg = 'Erro inesperado'
            alert = 'alert-danger'
            process = false
        })

    return { process, test, msg, alert }

}

export const emailIsValid = async (email) => {
    let re = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!re.test(email)) { //email is valid
        return true
    } else {
        return false
    }
}