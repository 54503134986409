import React, { Component } from "react";
import { Link } from "react-router-dom";
import Content from "../template/jsx/Content";
import ModalOne from "../tools/ModalOne";
import Alert from "../tools/Alert";
import Pagination from "react-js-pagination";
import API from "../../utils/API";
import { ifError } from "../tools/utils";
import "./UserList.css";
import LoadingIconList from "../tools/LoadingIconList";

const InitialUsers = {
  id: "",
  name: "",
  company: "",
  email: "",
  pagination: {
    activePage: 1,
    limit: 10,
    search: "",
    count: "",
    filter: [],
    list: [],
    userPage: [],
  },
  alertBox: {
    title: "",
    subtitle: "",
    titleButton1: "",
    titleButton2: "",
    onClick: null,
    value: "",
  },
  alert: {
    class: "alert-warning",
    msg: null,
    onClick: null,
    visible: alert.msg ? "" : "d-none",
  },
  loadingIconList: false,
};

//export default class UserList extends Component {
export default class UserList extends Component {
  state = { ...InitialUsers };

  async componentDidMount() {
    if (this.props.location.msg)
      this.showAlert(
        true,
        this.props.location.msg || "Salvo com Sucesso",
        this.props.location.alert || "alert-warning"
      );
    const pagination = { ...this.state.pagination };

    await API.get("/users")
      .then((resp) => {
        if (resp.data.data.length) {
          this.setState({
            pagination: {
              ...pagination,
              list: resp.data.data,
              filter: resp.data.data,
              count: resp.data.count,
            },
          });
        } else {
          this.setState({ loadingIconList: true });
        }
      })
      .catch((err) => {
        console.log(err);
        this.showAlert(
          true,
          "Ocorreu algo inesperado ao tentar listar os usuários, contacte o administrador.",
          "alert-warning"
        );
        this.setState({ loadingIconList: true });
      });

    //console.log('Atualizou o List')

    this.pagination(this.state.pagination.activePage);
  }

  async pagination(activePage) {
    const pagination = { ...this.state.pagination };
    const minListUser =
      activePage * this.state.pagination.limit - this.state.pagination.limit;
    const users = this.state.pagination.filter.slice(
      minListUser,
      minListUser + this.state.pagination.limit
    );
    pagination.activePage = activePage;
    pagination.userPage = users;
    this.setState({ pagination });
  }

  renderHeaderTable() {
    return (
      <React.Fragment>
        <div className="container-fluid mb-2">
          <div className="row mb-2 mt-2">
            <div className="col-10 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  id="search"
                  placeholder="Pesquisar..."
                  value={this.state.pagination.search || ""}
                  onChange={(e) => this.updateFilter(e)}
                />
                <div className="input-group-append">
                  <button className="btn btn-login" type="button">
                    <i className="fas fa-search" />
                  </button>
                </div>
              </div>
            </div>
            <div className="col-2 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <div className="d-flex justify-content-end">
                <Link to="/usersCreate">
                  <button className="btn btn-login ">
                    <i className="fas fa-plus fa-1x" />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  formatNotNull(string) {
    if (!string || string === "") return "-";
    return string;
  }

  async updateFilter(event) {
    const pagination = { ...this.state.pagination };
    pagination.search = event.target.value;
    if (event.target.value !== "") {
      pagination.filter = pagination.list.filter(
        (total) =>
          !total.name.toUpperCase().indexOf(pagination.search.toUpperCase()) ||
          !this.formatNotNull(total.company)
            .toUpperCase()
            .indexOf(pagination.search.toUpperCase()) ||
          !total.id
            .toString()
            .toUpperCase()
            .indexOf(pagination.search.toUpperCase()) ||
          !this.formatNotNull(total.email)
            .toUpperCase()
            .indexOf(pagination.search.toUpperCase())
      );
    } else {
      pagination.filter = pagination.list;
    }
    await this.setState({ pagination });
    this.pagination(this.state.pagination.activePage);
  }

  renderTable() {
    return (
      <div className="content">
        <div className="table-responsive">
          <table className="table table-hover table-sm">
            <caption>{`${this.state.pagination.userPage.length} de ${this.state.pagination.filter.length}`}</caption>
            <thead>
              <tr>
                <th scope="col">Id</th>
                <th scope="col">Nome</th>
                <th scope="col">Empresa</th>
                <th scope="col">Email</th>
                <th scope="col">Ações</th>
              </tr>
            </thead>
            <tbody>{this.renderRows()}</tbody>
          </table>
          <div>
            <Pagination
              activePage={this.state.pagination.activePage}
              itemsCountPerPage={this.state.pagination.limit}
              totalItemsCount={parseInt(this.state.pagination.count)}
              innerClass={"pagination mr-5 d-flex justify-content-end"}
              prevPageText={<i className="fa fa-chevron-left" />}
              firstPageText={<i className="fa fa-angle-double-left" />}
              nextPageText={<i className="fa fa-chevron-right" />}
              lastPageText={<i className="fa fa-angle-double-right" />}
              itemClass={"page-item"}
              linkClass={"page-link"}
              disabledClass={""}
              pageRangeDisplayed={4}
              onChange={(e) => this.pagination(e)}
            />
          </div>
        </div>
      </div>
    );
  }

  renderRows() {
    if (this.state.pagination.list.length) {
      return this.state.pagination.userPage.map((user) => {
        return (
          <tr key={user.id}>
            <td>{user.id}</td>
            <td>{user.name}</td>
            <td>{user.company}</td>
            <td>{user.email}</td>
            {/* <td>

                            <button className="btn btn-danger fas fa-trash ml-2 " value={user.id} data-toggle="modal" data-target="#exampleModal" onClick={e => this.deleteUserBox(e)} />
                            <button className="btn btn-success fas fa-user-lock ml-2 " value={user.id} data-toggle="modal" data-target="#exampleModal" onClick={e => this.loginAsUser(e)} />
                        </td> */}
            <td>
              <div
                className="btn-group dropleft ml-2 float-center"
                role="group"
              >
                <button
                  id="btnGroupDrop1"
                  type="button"
                  className="btn btn-login dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true"
                >
                  <i className="fas fa-bars" />
                </button>
                <div
                  className="dropdown-menu dropdown-menu-down float-left"
                  aria-labelledby="btnGroupDrop1"
                >
                  <Link to={`/usersCreate/${user.id}`}>
                    <button
                      className="dropdown-item"
                      value={user.id}
                      onClick={(e) => this.loginAsUser(e)}
                    >
                      <i className="fas fa-edit mr-2 " />| Editar
                    </button>
                  </Link>

                  <div role="separator" className="dropdown-divider" />

                  <button
                    className="dropdown-item"
                    value={user.id}
                    data-toggle="modal"
                    data-target="#exampleModal"
                    onClick={(e) => this.loginAsUser(e)}
                  >
                    <i className="fas fa-user-lock mr-2 " />| Logar como
                  </button>

                  <div role="separator" className="dropdown-divider" />

                  <button
                    className="dropdown-item text-danger"
                    value={user.id}
                    data-toggle="modal"
                    data-target="#exampleModal"
                    onClick={(e) => this.deleteUserBox(e)}
                  >
                    <i className="fas fa-trash mr-2 text-danger" />| Excluir
                    usuário
                  </button>
                </div>
              </div>
            </td>
          </tr>
        );
      });
    } else {
      return <LoadingIconList loadingIconList={this.state.loadingIconList} />;
    }
  }

  async deleteUser(idUser) {
    await API.delete(`users/${idUser}`)
      .then((user) => {
        const users = this.state.pagination.list.filter(
          (user) => user.id !== parseInt(idUser)
        );
        const statePagination = { ...this.state.pagination };

        this.setState({
          pagination: {
            ...statePagination,
            list: users,
            filter: users,
          },
        });
        this.pagination(1);
        console.log("state", this.state);
        this.showAlert(
          true,
          user.data.msg || "Usuário excluido.",
          "alert-success"
        );
      })
      .catch((err) => {
        console.log("deu errado, excluir");
        this.showAlert(
          true,
          err.response.data.msg || "Erro Inesperado",
          "alert-danger"
        );
      });
  }

  deleteUserBox(e) {
    const idUser = parseInt(e.target.value);
    const users = this.state.pagination.userPage;
    const alertBox = this.state.alertBox;
    const userSelect = users.filter((user) => user.id === idUser);

    alertBox.title = "Exclusão de usuário";
    alertBox.subtitle = `Deseja mesmo excluir o usuário ${userSelect[0]?.name} ${e.target.value} ?`;
    alertBox.titleButton1 = "Excluir";
    alertBox.titleButton2 = "Cancelar";
    alertBox.onClick = (e) => this.deleteUser(idUser);
    alertBox.value = `${userSelect[0]?.id}`;

    this.setState({ alert });
  }

  async loginAs(id) {
    await API.get(`loginAsUser/${id}`)
      .then((suc) => {
        const signin = JSON.stringify(suc.data);
        localStorage.setItem("@disc-app/signin", signin);
        window.location.replace("/");
      })
      .catch((err) => {
        console.log(err);
        const msg = ifError(err);
        this.showAlert(true, msg || "Erro inesperado", "alert-danger");
      });
  }

  loginAsUser(e) {
    const users = this.state.pagination.userPage;
    const alertBox = this.state.alertBox;
    const userSelect = users.filter(
      (user) => user.id === parseInt(e.target.value)
    );

    alertBox.title = "Logar como usuário";
    alertBox.subtitle = `Deseja mesmo logar como ${userSelect[0].name} ?`;
    alertBox.titleButton1 = "Logar";
    alertBox.titleButton2 = "Cancelar";
    alertBox.onClick = (e) => this.loginAs(userSelect[0].id);
    alertBox.value = `${userSelect[0].id}`;

    this.setState({ alert });
  }

  showAlert(show, msg, color) {
    const alert = this.state.alert;
    if (show === true) {
      alert.msg = msg;
      alert.class = color;
      this.setState({ alert });
    } else {
      alert.msg = null;
      this.setState({ alert });
    }
  }

  render() {
    return (
      //<Content {...headerProsps}>
      <Content>
        <Alert
          msg={this.state.alert.msg}
          class={this.state.alert.class}
          onClick={(e) => this.showAlert(false)}
          visible={this.state.alert.msg ? "" : "d-none"}
        />
        {this.renderHeaderTable()}
        {this.renderTable()}
        <ModalOne
          titleButton1={this.state.alertBox.titleButton1}
          titleButton2={this.state.alertBox.titleButton2}
          subtitle={this.state.alertBox.subtitle}
          onClick={this.state.alertBox.onClick}
          title={this.state.alertBox.title}
          value={this.state.alertBox.value}
        />
      </Content>
    );
  }
}
