import React, { Component } from 'react'
//import './PostViewer.css'
import Content from '../template/jsx/Content'
import Pagination from "react-js-pagination"
import { formatDate } from '../tools/utils'
import { Link } from 'react-router-dom'
import Alert from '../tools/Alert'
import LoadingIconList from '../tools/LoadingIconList'
import API from '../../utils/API'

const initialState = {
    post: {
        id: null,
        type: null,
        title: null,
        category: null,
        subTitle: null,
        order: null,
        active: null,
        imageUrl: null,
        pdfUrl: null,
        audience:null,
    },
    loadingIconList: false,
    pagination: {
        activePage: 1,
        limit: 10,
        search: '',
        count: '',
        filter: [],
        list: [],
        postPage: [],
    },
    alert: {
        class: 'alert-warning',
        msg: null,
        onClick: null,
        visible: alert.msg ? '' : 'd-none',
    }
}

export default class PostViewer extends Component {

    state = { ...initialState }

    async componentDidMount() {

        if (this.props.location.msg) this.showAlert(true, this.props.location.msg || 'Salvo com Sucesso', this.props.location.alert || 'alert-warning')
        const pagination = { ...this.state.pagination }

        await API.get('/post')
            .then(resp => {
                if (resp.data.data.length) {
                    this.setState({
                        pagination: {
                            ...pagination,
                            list: resp.data.data,
                            filter: resp.data.data,
                            count: resp.data.count,
                        }
                    })
                } else {
                    this.setState({ loadingIconList: true })
                }
            })
            .catch(err => {
                console.log(err.response)
                this.showAlert(true, 'Ocorreu algo inesperado ao tentar listar os testes, contacte o administrador.', 'alert-warning')
                this.setState({ loadingIconList: true })
            })
        //console.log('Atualizou o List')
        this.pagination(this.state.pagination.activePage)
    }

    renderHeaderTable() {
        return (
            <React.Fragment>
                <div className="container-fluid mb-2">
                    <div className="row mb-2 mt-2">
                        <div className="col-10 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                            <div className="input-group">
                                <input type="text" className="form-control" id="search"
                                    placeholder="Pesquisar por titulo"
                                    value={this.state.pagination.search || ''}
                                    onChange={(e) => this.updateFilter(e)} />
                                <div className="input-group-append">
                                    <button className="btn btn-login" type="button"><i className="fas fa-search" /></button>
                                </div>
                            </div>
                        </div>
                        <div className="col-2 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div className="d-flex justify-content-end">
                                <Link to='/postCreate'>
                                    <button className="btn btn-login "><i className="fas fa-plus fa-1x" /></button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    showAlert(show, msg, color) {
        const alert = this.state.alert
        if (show === true) {
            alert.msg = msg
            alert.class = color
            this.setState({ alert })
        } else {
            alert.msg = null
            this.setState({ alert })
        }
    }

    pagination(activePage) {
        const pagination = { ...this.state.pagination }
        const minListTest = activePage * this.state.pagination.limit - this.state.pagination.limit
        const posts = this.state.pagination.filter.slice(minListTest, minListTest + this.state.pagination.limit)
        pagination.activePage = activePage
        pagination.postPage = posts
        this.setState({ pagination })
    }

    async updateFilter(event) {
        const pagination = { ...this.state.pagination }
        pagination.search = event.target.value
        if (event.target.value !== '') {
            pagination.filter = pagination.list.filter(total => !total.title.toUpperCase().indexOf(event.target.value.toUpperCase()))
        } else {
            pagination.filter = pagination.list
        }
        await this.setState({ pagination })
        this.pagination(this.state.pagination.activePage)
    }

    renderTable() {
        return (
            <div className="content">
                <div className="table-responsive">
                    <table className="table table-hover table-sm table-striped">
                        <caption>{`${this.state.pagination.postPage.length} de ${this.state.pagination.filter.length}`}</caption>
                        <thead>
                            <tr>
                                <th scope="col">º</th>
                                <th scope="col">Titulo</th>
                                <th scope="col">Audiência</th>
                                <th scope="col">Ordem</th>
                                <th scope="col">Status</th>
                                <th scope="col">Criado</th>
                                <th scope="col">Opção</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderRows()}
                        </tbody>
                    </table>
                    <div>
                        <Pagination
                            activePage={this.state.pagination.activePage}
                            itemsCountPerPage={this.state.pagination.limit}
                            totalItemsCount={this.state.pagination.filter.length}
                            innerClass={"pagination mr-5 d-flex justify-content-end"}
                            prevPageText={<i className="fa fa-chevron-left" />}
                            firstPageText={<i className="fa fa-angle-double-left" />}
                            nextPageText={<i className="fa fa-chevron-right" />}
                            lastPageText={<i className="fa fa-angle-double-right" />}
                            itemClass={"page-item"}
                            linkClass={"page-link"}
                            disabledClass={""}
                            pageRangeDisplayed={4}
                            onChange={e => this.pagination(e)}
                        />
                    </div>
                </div>
            </div>
        )
    }

    renderRows() {

        if (this.state.pagination.list.length) {

            return this.state.pagination.postPage.map(posts => {
                return (
                    <tr key={posts.id}>
                        <td>{posts.id}</td>
                        <td>{posts.title}</td>
                        <td>{posts.audience === "1" ? "Sistema" : posts.audience === "2" ? "Site" : posts.audience === "3" ? "Ambos" : ''}</td>
                        <td>{posts.order}</td>
                        <td>{posts.status === "01" ? 'Ativo' : 'Inativo'}</td>
                        <td>{formatDate(posts.createdAt)}
                        </td>
                        <td>
                            <Link to={`/postEdit/${posts.id}`}>
                                <div className="btn-login d-flex justify-content-center">
                                    <i className="mt-1 mb-1 btn-login fas fa-edit fa-fw mr-2" />
                                </div>
                            </Link>
                        </td>
                    </tr>
                )
            })

        } else {
            return (
                <LoadingIconList loadingIconList={this.state.loadingIconList} />
            )
        }
    }

    render() {
        return (
            <Content>
                <Alert
                    msg={this.state.alert.msg}
                    class={this.state.alert.class}
                    onClick={e => this.showAlert(false)}
                    visible={this.state.alert.msg ? '' : 'd-none'}
                />
                {this.renderHeaderTable()}
                {this.renderTable()}
            </Content>
        )
    }
}