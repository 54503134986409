import React, { Component } from 'react'
import Content from '../template/jsx/Content'
import Pagination from "react-js-pagination"
import { Link } from 'react-router-dom'
import LoadingIconList from '../tools/LoadingIconList'
import API from '../../utils/API'
import { FirtAndLasttName, ifError } from '../tools/utils'
import Alert from '../tools/Alert'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './TestsVsGroups.css'
import ModalForms from '../tools/ModalForms'
import $ from 'jquery'

const initialState = {
    pagination: {
        activePage: 1,
        limit: 10,
        count: '',
        search: '',
        data: [],
        page: [],
    },
    alert: {
        class: 'alert-warning',
        msg: null,
        onClick: null,
        visible: alert.msg ? '' : 'd-none',
    },
    userLogged: {
        admin: false
    },
    toggleFilter: true,
    modalDelete: {
        id: 'modalDelete',
        title: '',
        footer: true,
        funccb1: null
    },
    modalCreate: {
        id: 'modalCreate',
        title: 'Vincular teste ao grupo',
        footer: false,
        funccb1: null
    },
    testsVsGroups: {
        id: '',
        idTest: '',
        name: '',
        idByUser: '',
        idGroup: '',
        grupo: ''
    },
    groupLists: {
        data: [],
        search: [],
    },
    insertTestsVsGroups: {
        idTest: '',
        idGroup: '',
    },
    testsList: {
        data: [],
        filter: [],
        search: '',
    }
}

export default class TestsVsGroups extends Component {

    state = { ...initialState }

    notify = (type, msg) => toast[type](msg);
    toggleFilter = () => this.setState({ toggleFilter: !this.state.toggleFilter })
    removeTestsVsGroupsCB = (e) => this.removeTestsVsGroups(e)
    totalOfItems = () => this.state.pagination.search ? this.state.pagination.filter.length : this.state.pagination.data.length
    //togglePopover = (e) => $(`#${e.target.id}`).popover('toggle')
    togglePopover = (e) => $(`#${e.target.id}`).popover('toggle')

    async componentDidMount() {

        const userLogged = JSON.parse(localStorage.getItem('@disc-app/signin'))
        this.setState({ userLogged })
        await this.getTestVsGroups()

        if (this.props.location.paramIdTest) this.setSearchprops(this.props.location.paramIdTest)

        if (this.props.location.paramIdGroup) this.setAddGroupsVsTests(this.props.location.paramIdGroup)

    }

    async getTestVsGroups() {
        const pagination = this.state.pagination
        await API.get('/testsVsGroups')
            .then(resp => {
                if (resp.data.data.length) {
                    this.setState({
                        pagination: {
                            ...pagination,
                            data: resp.data.data,
                            count: resp.data.count
                        }
                    })
                } else {
                    this.setState({ loadingIconList: true })
                }
            })
            .catch(err => {
                console.log(err)
                this.notify('warning', 'Ocorreu algo inesperado ao tentar listar os créditos, contacte o administrador.')
                this.setState({ loadingIconList: true })
            })

        this.pagination(this.state.pagination.activePage)
    }

    async getGroupsList() {
        const groupLists = this.state.groupLists

        await API.get('/getGroupsLists')
            .then(resp => {
                if (resp.data.length) {
                    this.setState({
                        groupLists: {
                            ...groupLists,
                            data: resp.data
                        }
                    })
                } else {
                    this.setState({ loadingIconList: true })
                }
            })
            .catch(err => {
                console.log(err)
                this.notify('warning', 'Ocorreu algo inesperado ao tentar listar os créditos, contacte o administrador.')
                this.setState({ loadingIconList: true })
            })
    }

    async getTestsList() {
        const testsList = this.state.testsList

        await API.get('/getListTest')
            .then(resp => {
                if (resp.data.length) {
                    this.setState({
                        testsList: {
                            ...testsList,
                            data: resp.data
                        }
                    })
                } else {
                    this.setState({ loadingIconList: true })
                }
            })
            .catch(err => {
                console.log(err)
                this.notify('warning', 'Ocorreu algo inesperado ao tentar listar os créditos, contacte o administrador.')
                this.setState({ loadingIconList: true })
            })
    }

    async setSearchprops(idTest) {

        const pagination = this.state.pagination
        pagination.search = idTest

        pagination.filter = pagination.data.filter(total =>
            total.idTest.toString().toLowerCase().indexOf(idTest.toString().toLowerCase()) > -1
        )

        await this.setState({
            pagination, toggleFilter: false
        })

        this.pagination(pagination.activePage)
    }

    async setAddGroupsVsTests(idGroup) {

        this.setState({ insertTestsVsGroups: { ...this.state.insertTestsVsGroups, idGroup: idGroup } })
        document.getElementById("buttonAddTestsVsGroups").click()
    }

    pagination(activePage) {
        const stateData = this.state.pagination.search ? 'filter' : 'data'
        const pagination = { ...this.state.pagination }

        const minListTest = activePage * this.state.pagination.limit - this.state.pagination.limit

        const rowsData = this.state.pagination[stateData].slice(minListTest, minListTest + this.state.pagination.limit)

        pagination.activePage = activePage
        pagination.page = rowsData

        this.setState({ pagination })
    }

    showAlert(show, msg, color) {
        const alert = this.state.alert
        if (show === true) {
            alert.msg = msg
            alert.class = color
            this.setState({ alert })
        } else {
            alert.msg = null
            this.setState({ alert })
        }
    }

    renderTable() {
        return (

            <div className="table-responsive-sm">
                <table className="table table-hover table-sm table-striped border">
                    <thead>
                        <tr className="">
                            <th scope="col">Disc</th>
                            <th scope="col">Nome</th>
                            <th scope="col">Grupo</th>
                            <th scope="col">Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderRows()}
                    </tbody>
                    <tfoot className="">
                        <tr className="">
                            <th className="align-middle text-center" colSpan="2">{`${this.state.pagination.page.length} de ${parseInt(this.totalOfItems())}`}</th>
                            <th className="align-middle text-center" colSpan="2">
                                <Pagination
                                    activePage={this.state.pagination.activePage}
                                    itemsCountPerPage={this.state.pagination.limit}
                                    totalItemsCount={parseInt(this.totalOfItems())}
                                    innerClass={"pagination pagination-sm justify-content-center"}
                                    prevPageText={<i className="fa fa-chevron-left" />}
                                    firstPageText={<i className="fa fa-angle-double-left" />}
                                    nextPageText={<i className="fa fa-chevron-right" />}
                                    lastPageText={<i className="fa fa-angle-double-right" />}
                                    itemClass={"page-item"}
                                    linkClass={"page-link"}
                                    disabledClass={""}
                                    pageRangeDisplayed={5}
                                    onChange={e => this.pagination(e)}
                                />
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        )
    }

    renderRows() {

        if (this.state.pagination.page.length) {
            return this.state.pagination.page.map(rows => {
                return (
                    <tr className="align-middle " key={rows.id}>
                        <td className="align-middle">{rows.idTest}</td>
                        <td className="align-middle text-lowercase text-truncate text-name-long">{rows.name}</td>
                        <td className="align-middle text-truncate">{`${rows.idByUser}- ${rows.grupo}`}</td>
                        <td className="align-middle">
                            <button value={rows.id} className="btn btn-sm badge badge-pill btn-outline-danger buttonRemoveTestsVsGroups"
                                data-id="modalDelete" data-toggle="modal" data-target="#modalDelete"
                                onClick={e => this.setModal(e)}> Remover
                            </button>
                        </td>
                    </tr>
                )
            })
        } else {
            return (
                this.state.pagination.search && !this.state.pagination.page.length ?
                    <tr className="align-middle">
                        <td className="align-middle text-CubohColor2" colSpan="4" >Sem dados para exibir.</td>
                    </tr>
                    :
                    <LoadingIconList loadingIconList={this.state.loadingIconList} />
            )
        }
    }

    renderHeaderTable() {
        return (
            <div className="container-fluid">
                <div className="row my-2">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 px-1">
                        <div className="d-flex flex-nowrap">
                            <h4 className="text-CubohColor1"><b>Vincular teste ao grupo</b></h4>
                            <i id="TitleQuestionTestsVsGroups" onMouseOver={e => this.togglePopover(e)} onMouseLeave={e => this.togglePopover(e)} onClick={e => this.togglePopover(e)} type="button" className="fas fa-question-circle text-muted fa-sm align-self-center ml-1"
                                container='principal' data-container="main" data-toggle="popover" data-html="true" data-placement="bottom"
                                data-content="Aqui você pode vincular testes aos grupos para ter uma análise mais detalhada e específica. <br/>  Divrita-se 😉" />
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 px-1">

                        <div className="d-flex justify-content-end mt-2 mt-md-0 ">

                            <button id="buttonAddTestsVsGroups" className="btn btn-login btn-sm fas fa-plus mr-2"
                                data-toggle="modal" data-target="#modalCreate"
                                data-id="modalCreate" onClick={e => this.setModal(e)}>
                            </button>

                            <button className={`btn ${this.state.toggleFilter ? 'btn-login' : 'btn-outline-primary'} mr-2 btn-sm`} onClick={e => this.toggleFilter(e)}>
                                <i className="fas fa-filter fa-1x" />
                            </button>

                            <Link to='/GroupsList'>
                                <button className="btn btn-login mr-2 btn-sm"><i className="fas fa-list-ul fa-1x" /></button>
                            </Link>

                            <Link to='/groupsHierarchy'>
                                <button className="btn btn-login btn-sm">
                                    <i className="fas fa-sitemap fa-1x" />
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className={` ${this.state.toggleFilter ? 'd-none' : ''} row mb-2 mt-2 `}>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 px-1">
                        <div className="input-group input-group-sm">
                            <input type="text" className="form-control" id="search" autoComplete="off"
                                placeholder="Pesquisar..."
                                value={this.state.pagination.search || ''}
                                onChange={(e) => this.updateFilter(e, 'pagination')} />
                            <div className="input-group-append">
                                <button className="btn btn-login" type="button"><i className="fas fa-search" /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    setTestsVsGroups(id) {
        const testsVsGroups = this.state.pagination.data.find(testsVsGroups => testsVsGroups.id === id)
        this.setState({ testsVsGroups: { ...testsVsGroups } })
    }

    async setModal(e) {
        e.preventDefault()
        const create = e.target.dataset.id === 'modalCreate' ? true : false
        const state = create ? 'modalCreate' : 'modalDelete'

        if (create) {
            await this.getGroupsList()
            await this.getTestsList()
        }

        if (!create) await this.setTestsVsGroups(parseInt(e.target.value))

        this.setState({
            [state]: {
                ...this.state[state],
                id: state,
                title: create ? 'Vincular testes ao grupo' : 'Remover teste do grupo',
                funccb1: create ? this.addTestsVsGroupsCB : this.removeTestsVsGroupsCB
            }
        })
    }

    modalAddTestsVsGroups(e) {
        return (
            <div id="modalAddTestsVsGroups">
                <div className="row">

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="input-group input-group-sm flex-nowrap mb-3">
                            <div className="input-group-prepend">
                                <span htmlFor="idGroup" className="input-group-text" id="inputGroup-sizing-sm">Grupo</span>
                            </div>
                            <select id="idGroup" value={this.state.insertTestsVsGroups.idGroup || ''} className="form-control list-group list-group-flush"
                                placeholder="selecione um grupo" onChange={e => this.updateField(e)}
                                disabled={this.props.location.paramIdGroup ? true : false}>
                                <option className="list-group-item" value="0">Selecione um grupo</option>
                                {this.renderOptionsListGroups()}
                            </select>
                        </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="input-group input-group-sm flex-nowrap mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroup-sizing-sm">
                                    <i className="fas fa-search fa-md" />
                                </span>
                            </div>
                            <input type="text" className="form-control" aria-label="Sizing example input" value={this.state.testsList.search || ''} onChange={e => this.updateFilter(e, 'testsList')} aria-describedby="inputGroup-sizing-sm" placeholder="Pesquise o teste" autoComplete="off" />
                        </div>
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div id="modalCardTestsVsGroups" className="card-sm">
                            <div className="card-header p-1 text-center" id="GroupsOfTests">
                                Testes -  Clique em + para adicionar.
                            </div>
                            <div className="card-body">
                                <ul className="list-group list-group-flush">
                                    {this.renderRowsTests()}
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div >

        )
    }

    async addTestsVsGroups(idTest, name) {

        if (!parseInt(this.state.insertTestsVsGroups.idGroup)) return this.notify('warning', 'Antes de inserir, selecione um grupo.')
        const { insertTestsVsGroups, groupLists } = this.state
        const groups = await groupLists.data.find(group => group.id === parseInt(insertTestsVsGroups.idGroup))

        API.post('testsVsGroups', {
            idGroup: insertTestsVsGroups.idGroup,
            idTest: idTest
        }).then(suc => {

            this.state.pagination.data.splice(0, 0, {
                id: suc.data.id,
                idTest: idTest,
                idByUser: groups.idByUser,
                idGroup: parseInt(insertTestsVsGroups.idGroup),
                grupo: groups.name,
                name: name
            })
            this.pagination(this.state.pagination.activePage)
            this.notify(suc.data.alert, `${FirtAndLasttName(name)} adicionado(a) ao grupo ${groups.name}`)

        }).catch(err => {
            const msg = ifError(err)
            console.log(msg)
            this.notify(`${err.response.data.alert}`, msg)
        })
    }

    renderOptionsListGroups() {
        if (this.state.groupLists.data.length) {
            return this.state.groupLists.data.map(rows => {
                return <option key={rows.id} className="list-group-item" value={rows.id}>{`${rows.name}`}</option>
            })
        }
    }

    renderRowsTests(e) {
        const stateData = this.state.testsList.search ? 'filter' : 'data'

        if (this.state.testsList[stateData].length && this.state.insertTestsVsGroups.idGroup) {
            return this.state.testsList[stateData].map(rows => {
                return (
                    <li key={rows.idTest} value={rows.idTest} className="list-group-item d-flex justify-content-between align-items-center">
                        <span className="text-capitalize text-truncate">{`${rows.idTest} - ${rows.name}`}</span>
                        <i className="fas fa-plus-square" value={rows.idTest} onClick={e => this.addTestsVsGroups(rows.idTest, rows.name)} />
                    </li>
                )
            })
        } else {
            return (
                <span className="text-center text-CubohColor2">Antes de inserir, primeiro selecione um grupo</span>
            )
        }
    }

    async removeTestsVsGroups() {
        const testsVsGroups = this.state.testsVsGroups
        const pagination = this.state.pagination

        await API.delete(`testsVsGroups/${testsVsGroups.id}`)
            .then(async suc => {
                pagination.data.splice(pagination.data.findIndex(test => test.id === testsVsGroups.id), 1)
                if (pagination.search) pagination.filter.splice(pagination.filter.findIndex(test => test.id === testsVsGroups.id), 1)
                this.notify('success', `${FirtAndLasttName(testsVsGroups.name)} removido(a) do grupo ${testsVsGroups.grupo}`)
                this.setState({ pagination, testsVsGroups: {} })
                this.pagination(pagination.activePage)
            })
            .catch(err => {
                console.log('Erro no removeTestsVsGroups = ', err)
                this.notify('error', `não foi possivel remover ${testsVsGroups.name}, contacte o adm.`)
            })
    }

    modalRemoveTestsVsGroups(e) {
        return (
            <div>
                <label>Remover:
                    <span className="text-CubohColor2 text-bold">{` ${this.state.testsVsGroups.name || 'este teste'}`}</span>
                </label>
                <br />
                <label>Do grupo:
                    <span className="text-CubohColor2 text-bold">{` ${this.state.testsVsGroups.grupo || 'selecionado'}`}</span>
                </label>
            </div>
        )
    }

    async updateField(e) {
        const insertTestsVsGroups = { ...this.state.insertTestsVsGroups }
        insertTestsVsGroups[e.target.id] = e.target.value
        await this.setState({ insertTestsVsGroups })
    }

    async updateFilter(e, nameList) {
        const list = { ...this.state[nameList] }
        list.search = e.target.value
        if (e.target.value !== '') {
            list.filter = list.data.filter(total =>
                total.name.toUpperCase().indexOf(list.search.toUpperCase()) > -1
                || total.idTest.toString().toUpperCase().indexOf(list.search.toUpperCase()) > -1
                || (total.grupo && total.grupo.toUpperCase().indexOf(list.search.toUpperCase()) > -1)
            )
        } else {
            list.filter = []
        }
        await this.setState({ [nameList]: { ...list } })
        this.pagination(this.state.pagination.activePage)
    }

    render() {
        return (
            <Content>
                <ToastContainer position="top-right"
                    autoClose={4000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss={false}
                    draggable
                    pauseOnHover />
                <Alert
                    msg={this.state.alert.msg}
                    class={this.state.alert.class}
                    onClick={e => this.showAlert(false)}
                    visible={this.state.alert.msg ? '' : 'd-none'}
                />
                {this.renderHeaderTable()}
                {this.renderTable()}
                <ModalForms {...this.props} modal={this.state.modalCreate}>
                    {this.modalAddTestsVsGroups()}
                </ModalForms>
                <ModalForms {...this.props} modal={this.state.modalDelete}>
                    {this.modalRemoveTestsVsGroups()}
                </ModalForms>
            </Content>
        )
    }
}