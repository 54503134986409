import React, { Component } from 'react'
import './TestsMode.css'
import TestsSingle from './TestsSingle'
import VariousTests from './VariousTests'
import { notify } from '../tools/Toastify'
import { emailIsValid } from '../tools/utils'
import API from '../../utils/API'
import Loading from '../tools/Loading'
import { ifError } from '../tools/utils'

const initialState = {
    modeButton: true, // true = left
    loading: {
        class: 'd-none',
        msg: 'loading...'
    }
}

export default class TestsMode extends Component {

    state = initialState

    toggleModeButton = () => this.setState({ modeButton: !this.state.modeButton })

    componentDidMount(){
        console.log('Montou')
    }

    saveCb = async (test, mode) => {
        const ret = await this.save(test, mode)
        return ret
    }

    loading(show, msg, className) {
        const loading = this.state.loading
        if (show === true) {
            loading.msg = msg
            loading.class = !className ? '' : 'divLoading-none'
            this.setState({ loading })
        } else {
            loading.class = 'd-none'
            this.setState({ loading })
        }
    }

    renderButtonsMode() {
        return (
            <div className="d-flex justify-content-center">
                <div className="btn-group btn-group-sm w-75" role="group" aria-label="Basic example">
                    <button id="option1" type="button" className={`btn btn-rounded-left ${this.state.modeButton ? 'buttonActive' : 'buttonInative'} `} onClick={e => this.toggleModeButton(e)} disabled={this.props.test.id ? true : false}>Único</button>
                    <button id="option2" type="button" className={`btn btn-rounded-right ${!this.state.modeButton ? 'buttonActive' : 'buttonInative'} `} onClick={e => this.toggleModeButton(e)} disabled={this.props.test.id ? true : false} >Vários</button>
                </div>
            </div>
        )
    }

    async save(test, mode) {
        let ret = false

        if (mode === 'single') {
            ret = await this.validFields(test)
            if (ret) {
                const url = test.id ? `/tests/${test.id}` : '/tests/'
                const method = test.id ? 'put' : 'post'
                const sendEmail = test.id ? false : true
                if (!test.positionId) test.positionId = null
                if (!test.groupId) test.groupId = null

                this.loading(true, 'Salvando o Disc...')
                console.log('Salvando test', test)

                await API[method](url, test)
                    .then(async suc => {
                        const retTest = suc.data.test
                        if (sendEmail) {
                            /* this.loading(true, 'Enviando E-mail...') */
                            if (await this.props.sendEmail(retTest)) {
                                retTest.status = '03'
                                this.props.insert(test.id ? false : true, retTest)
                            } else {
                                this.props.insert(test.id ? false : true, retTest)
                            }
                        } else {
                            this.props.insert(test.id ? false : true, retTest)
                        }
                        ret = true
                    })
                    .catch(err => {
                        this.loading(false)
                        const backWs = ifError(err)
                        ret = false
                        notify('error', backWs)
                    })
            }
        } else if (mode === 'various') {

            this.loading(true, 'Salvando o Disc...', false)
            await API.post('/saveMany/', test)
                .then(async suc => {
                    const retTest = await suc.data.test
                    for (let i = 0; i < retTest.length; i++) {
                        await this.props.sendEmail(retTest[i])
                        this.loading(true, `Enviando E-mail ${i + 1}/${retTest.length}`, false)
                    }
                    this.props.refreshListTests()
                    ret = true
                })
                .catch(err => {
                    this.loading(false)
                    const backWs = ifError(err)
                    ret = false
                    notify('error', backWs)
                })


        } else {
            console.log('[testsMode - save], mode não identificado.')
            notify('warning', 'Ocorreu algo inesperado, contacte o adm.')
        }

        this.loading(false)
        return ret
    }

    async validFields(test) {
        let ret = true

        if (!test.email) { //Email campo Obrigatório
            notify('warning', 'E-mail não informado.')
            ret = false
        } else if (!test.name) { //Nome Obrigatório
            notify('warning', 'Nome não informado.')
            ret = false
        } else if (await emailIsValid(test.email)) {
            notify('warning', 'Email inválido, favor avaliar.')
            ret = false
        } else if (!test.genre) {
            notify('warning', 'Informe o gênero que você se identifica.')
            ret = false
        }

        return ret
    }

    renderBoxTests() {
        return (
            <div id="testsMode" className="container-fluid  px-0 pt-3">
                {
                    this.state.modeButton ?
                        <TestsSingle {...this.props} save={this.saveCb} notify={notify} />
                        :
                        <VariousTests {...this.props} save={this.saveCb} notify={notify} valid={this.validFields} />
                }
            </div>
        )
    }

    render() {
        return (
            <div id="testsMode">
                <Loading {...this.state.loading} />
                { !this.props.test.id ? this.renderButtonsMode() : ''}
                {this.renderBoxTests()}
            </div>
        )
    }
}