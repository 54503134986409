import React, { Component } from 'react'
import './Login.css'
import { Link } from 'react-router-dom'
import Index from '../template/jsx/Index'
import API from '../../utils/API'
import Alert from '../tools/Alert'

const InitialState = {
    user: {
        email: '',
        password: '',
    },
    alert: {
        class: 'alert-warning',
        msg: null,
        onClick: null,
        visible: alert.msg ? '' : 'd-none',
    },
    loading: ''
}

export default class Login extends Component {

    state = { ...InitialState }

    updateField(event) {
        const user = { ...this.state.user }
        user[event.target.id] = event.target.value
        this.setState({ user })
    }

    componentDidMount() {
        const token = JSON.parse(localStorage.getItem('@disc-app/signin'))
        if (token) {
            localStorage.removeItem('@disc-app/signin')
            window.location.reload()
        }
    }

    renderForms() {
        return (
            <React.Fragment>
                <form className="login-form mt-4">
                    <div className="form-group">
                        {/*<label htmlFor="exampleInputEmail1" className="">E-mail</label>*/}
                        <input name="Cubohuser" type="text" className="form-control" id="email"
                            placeholder="E-mail"
                            onChange={e => this.updateField(e)} />
                    </div>
                    <div className="form-group">
                        {/*<label name="Cubohpassword" htmlFor="exampleInputPassword1" className="">Senha</label>*/}
                        <input type="password" className="form-control" id="password"
                            placeholder="Senha"
                            onChange={e => this.updateField(e)} />
                    </div>
                    {
                        this.state.loading ?
                            <div className="text-center">
                                <i className="fas fa-circle-notch fa-spin fa-3x text-CubohColor1" />
                            </div>
                            :
                            <button type="submit" className="col-12 btn btn-login"
                                onClick={e => this.login(e)}>Acessar Plataforma</button>
                    }
                </form>
                <div id="btnGroupLogin" className="col-12 btn-group px-0">
                    <a href={`${process.env.REACT_APP_SITE_URL}/cadastre-se`} className="btn-block btn btn-outline-info mt-5 ButtonsOfLogin">
                        Cadastrar-se
                    </a>
                    <Link to={`/Token`} className="btn-block btn btn-outline-info mt-5 ButtonsOfLogin">
                        Responder Disc
                    </Link>
                </div>

            </React.Fragment >
        )
    }

    async login(e) {
        e.preventDefault()
        this.setState({ loading: true })
        const user = { ...this.state.user }
        await API.post('/signin', user)
            .then(suc => {
                this.setState({ loading: false })
                this.showAlert(true, `Olá ${suc.data.name || ''} 😁 `, 'alert-success')
                const signin = JSON.stringify(suc.data)
                localStorage.setItem('@disc-app/signin', signin)
                window.location.replace('/')
            })
            .catch(err => {
                this.setState({ loading: false })
                this.showAlert(true, err.response.data.msg || 'Erro Inesperado', 'alert-danger')
                console.log(err)
            })
    }

    showAlert(show, msg, color) {
        const alert = this.state.alert
        if (show === true) {
            alert.msg = msg
            alert.class = color
            this.setState({ alert })
        } else {
            alert.msg = null
            this.setState({ alert })
        }
    }

    render() {
        return (
            <Index>
                <Alert
                    msg={this.state.alert.msg}
                    class={this.state.alert.class}
                    onClick={e => this.showAlert(false)}
                    visible={this.state.alert.msg ? '' : 'd-none'}
                />
                {this.renderForms()}
            </Index>
        )
    }
}