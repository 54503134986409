import React from 'react'
import '../css/Index.css'
import Carousel from '../../carousel/Carousel'
import logoHorizontal from '../../../assets/imgs/logo/logoHorizontal.png'

/*https://bootsnipp.com/snippets/GQ3Pl*/

export default props =>
    <section id="login-block" className="login-block">
        <div className="container containerCarroussel">
            <div className="row">
                <div className="d-block col-sm-12 col-md-4 col-lg-4 login-sec">
                    <img className=" img-fluid img-logo" src={logoHorizontal} alt="logo" />
                    {props.children}
                    <div className="card-footer mt-2 text-center bg-white textCreatedCuboh">Created <i className="fa fa-heart text-primary"></i> CUBOH</div>
                </div>
                <div className="d-block col-sm-12 col-md-8 col-lg-8 banner-sec">
                    <Carousel />
                </div>
            </div>
        </div>
    </section>
