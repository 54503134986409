import React, { Component } from 'react'
//import './ResultList.css'
import API from '../../utils/API'
import Pagination from "react-js-pagination"
//import { Link } from 'react-router-dom'
import { formatDate } from '../tools/utils'
import Content from '../template/jsx/Content'
import Alert from '../tools/Alert'
import Loading from '../tools/Loading'
import LoadingIconList from '../tools/LoadingIconList'

const InitialState = {
    pagination: {
        activePage: 1,
        limit: 10,
        count: '',
        search: '',
        filter: [],
        list: [],
        ratingPage: [],
    },
    alert: {
        class: 'alert-warning',
        msg: null,
        onClick: null,
        visible: alert.msg ? '' : 'd-none',
    },
    cardRating: {
        ratedName: '',
        createdAt: '',
        stars: '',
        message: ''
    },
    loading: {
        class: 'd-none',
        msg: '...'
    },
    loadingIconList: false,
}

export default class RatingsList extends Component {

    state = { ...InitialState }

    componentDidMount() {
        this.getTest()
    }

    async getTest() {
        const pagination = this.state.pagination
        await API.get('/rating')
            .then(resp => {
                if (resp.data.data.length) {
                    this.setState({
                        pagination: {
                            ...pagination,
                            list: resp.data.data,
                            filter: resp.data.data,
                            count: resp.data.count,
                            avgAll: resp.data.avgAll,
                            avgTop10: resp.data.avgtop10
                        }
                    })
                } else {
                    this.setState({ loadingIconList: true })
                }
            })
            .catch(err => {
                console.log(err)
                this.showAlert(true, 'Ocorreu algo inesperado ao tentar listar os créditos, contacte o administrador.', 'alert-warning')
                this.setState({ loadingIconList: true })
            })

        this.pagination(this.state.pagination.activePage)
    }

    renderCardRating() {
        return (
            <div className="modal fade" id="modalViewRating" tabIndex="-1" role="dialog" aria-labelledby="ModalFormSendEmailToPdf" >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header btn-login">
                            <h5 className="modal-title text-white" id="ModalFormSendEmailToPdf">Resumo da avaliação</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row  px-2">

                                        <div className="col-6">
                                            <div className="px-auto" style={{ borderRadius: '0px 5px 5px 0px' }}>
                                                <i className={`${this.state.cardRating.stars >= 1 ? 'fas text-warning' : 'far text-muted'} fa-star px-md-1 fa-lg`} />
                                                <i className={`${this.state.cardRating.stars >= 2 ? 'fas text-warning' : 'far text-muted'} fa-star px-md-1 fa-lg`} />
                                                <i className={`${this.state.cardRating.stars >= 3 ? 'fas text-warning' : 'far text-muted'} fa-star px-md-1 fa-lg`} />
                                                <i className={`${this.state.cardRating.stars >= 4 ? 'fas text-warning' : 'far text-muted'} fa-star px-md-1 fa-lg`} />
                                                <i className={`${this.state.cardRating.stars >= 5 ? 'fas text-warning' : 'far text-muted'} fa-star px-md-1 fa-lg`} />
                                            </div>
                                        </div>
                                        <div className="col-6 text-right">
                                            <small className="px-auto text-muted">{formatDate(this.state.cardRating.createdAt) || ''}</small>
                                        </div>
                                        <div className="col-12">
                                            <h5 className="px-2 card-title text-CubohColor1">{this.state.cardRating.ratedName}</h5>
                                        </div>
                                        <div className="col-12 pt-3">
                                            <textarea
                                                className="form-control" rows={this.state.cardRating.message ? "6" : "1"} maxLength="253"
                                                id="message"
                                                placeholder=""
                                                value={this.state.cardRating.message || 'Mensagem não informada.'}
                                                readOnly />
                                        </div>
                                        <div className="col-12 d-flex justify-content-between">
                                            <small className="px-auto text-muted">{this.state.cardRating.evaluatorName}</small>
                                            <small className="px-auto text-muted">{this.state.cardRating.company}</small>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    showAlert(show, msg, color) {
        const alert = this.state.alert
        if (show === true) {
            alert.msg = msg
            alert.class = color
            this.setState({ alert })
        } else {
            alert.msg = null
            this.setState({ alert })
        }
    }

    loading(show, msg) {
        const loading = this.state.loading
        if (show === true) {
            loading.msg = msg
            loading.class = ''
            this.setState({ loading })
        } else {
            loading.class = 'd-none'
            this.setState({ loading })
        }
    }

    renderHeaderTable() {
        return (
            <React.Fragment>
                <div className="container-fluid mb-2">
                    <div className="row mb-2 mt-2">

                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div className="input-group input-group-sm my-1">
                                <input type="text" className="form-control" id="search"
                                    placeholder="Pesquisar"
                                    value={this.state.pagination.search || ''}
                                    onChange={(e) => this.updateFilter(e)} />
                                <div className="input-group-append input-group-sm">
                                    <button className="btn btn-login btn-sm" type="button"><i className="fas fa-search" /></button>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div className="input-group input-group-sm d-flex justify-content-center flex-nowrap my-1">
                                <div className="input-group-append">
                                    <span className="btn btn-login btn-sm text-right" style={{ borderRadius: '5px 0px 0px 5px' }}>Todos</span>
                                </div>
                                <div className="bg-white py-2 px-2" style={{ borderRadius: '0px 5px 5px 0px' }} >
                                    <i className={`${this.state.pagination.avgAll >= 1 ? 'fas fa-star  text-warning' : this.state.pagination.avgAll >= 0.5 ? 'fas fa-star-half-alt  text-warning' : 'far fa-star  text-muted'} px-md-1`} />
                                    <i className={`${this.state.pagination.avgAll >= 2 ? 'fas fa-star  text-warning' : this.state.pagination.avgAll >= 1.5 ? 'fas fa-star-half-alt  text-warning' : 'far fa-star  text-muted'} px-md-1`} />
                                    <i className={`${this.state.pagination.avgAll >= 3 ? 'fas fa-star  text-warning' : this.state.pagination.avgAll >= 2.5 ? 'fas fa-star-half-alt  text-warning' : 'far fa-star  text-muted'} px-md-1`} />
                                    <i className={`${this.state.pagination.avgAll >= 4 ? 'fas fa-star  text-warning' : this.state.pagination.avgAll >= 3.5 ? 'fas fa-star-half-alt  text-warning' : 'far fa-star  text-muted'} px-md-1`} />
                                    <i className={`${this.state.pagination.avgAll >= 5 ? 'fas fa-star  text-warning' : this.state.pagination.avgAll >= 4.5 ? 'fas fa-star-half-alt  text-warning' : 'far fa-star  text-muted'} px-md-1`} />
                                </div>
                                <div className="input-group-append">
                                    <span className="btn btn-login btn-sm text-right" style={{ borderRadius: '0px 5px 5px 0px' }}>{this.state.pagination.avgAll || "0"}</span>
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mx-auto my-auto">
                            <div className="input-group input-group-sm d-flex justify-content-center flex-nowrap ">
                                <div className="input-group-append ">
                                    <span className="btn btn-login text-right" style={{ borderRadius: '5px 0px 0px 5px' }}>Top10</span>
                                </div>
                                <div className="bg-white py-2 px-2" style={{ borderRadius: '0px 5px 5px 0px' }}>
                                    <i className={`${this.state.pagination.avgTop10 >= 1 ? 'fas fa-star text-warning' : this.state.pagination.avgTop10 >= 0.5 ? 'fas fa-star-half-alt text-warning' : 'far fa-star text-muted'} px-md-1`} />
                                    <i className={`${this.state.pagination.avgTop10 >= 2 ? 'fas fa-star text-warning' : this.state.pagination.avgTop10 >= 1.5 ? 'fas fa-star-half-alt text-warning' : 'far fa-star text-muted'} px-md-1`} />
                                    <i className={`${this.state.pagination.avgTop10 >= 3 ? 'fas fa-star text-warning' : this.state.pagination.avgTop10 >= 2.5 ? 'fas fa-star-half-alt text-warning' : 'far fa-star text-muted'} px-md-1`} />
                                    <i className={`${this.state.pagination.avgTop10 >= 4 ? 'fas fa-star text-warning' : this.state.pagination.avgTop10 >= 3.5 ? 'fas fa-star-half-alt text-warning' : 'far fa-star text-muted'} px-md-1`} />
                                    <i className={`${this.state.pagination.avgTop10 >= 5 ? 'fas fa-star text-warning' : this.state.pagination.avgTop10 >= 4.5 ? 'fas fa-star-half-alt text-warning' : 'far fa-star text-muted'} px-md-1`} />
                                </div>
                                <div className="input-group-append">
                                    <span className="btn btn-login text-right" style={{ borderRadius: '0px 5px 5px 0px' }}>{this.state.pagination.avgTop10 || "0"}</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </React.Fragment>
        )
    }

    pagination(activePage) {

        const pagination = { ...this.state.pagination }
        const minListTest = activePage * this.state.pagination.limit - this.state.pagination.limit
        const rating = this.state.pagination.filter.slice(minListTest, minListTest + this.state.pagination.limit)
        pagination.activePage = activePage
        pagination.ratingPage = rating
        this.setState({ pagination })
    }

    async updateFilter(event) {
        const pagination = { ...this.state.pagination }
        pagination.search = event.target.value
        if (event.target.value !== '') {
            pagination.filter = pagination.list.filter(total => 

                !total.idTest.toString().toUpperCase().indexOf(pagination.search.toUpperCase())
                || !total.evaluatorName.toUpperCase().indexOf(pagination.search.toUpperCase())
                || !total.ratedName.toUpperCase().indexOf(pagination.search.toUpperCase())
                || !total.stars.toString().indexOf(pagination.search.toUpperCase())
                || !formatDate(total.createdAt).toUpperCase().indexOf(pagination.search.toUpperCase())
            
            )
        } else {
            pagination.filter = pagination.list
        }
        await this.setState({ pagination })
        this.pagination(this.state.pagination.activePage)
    }

    async setRatingCard(e) {
        e.preventDefault()
        const ratingPage = this.state.pagination.ratingPage

        if (!e.target.value) { //validation
            this.showAlert(true, 'Não foi possivel identificar o teste, atualiza página e tente novamente ou contacte o adm.', 'alert-warning')
            this.setState({ cardRating: InitialState.cardRating })
            return
        } else {
            const rating = ratingPage.find(rating => rating.id === parseInt(e.target.value))
            if (!rating || localStorage.getItem("@disc-app/signin") === null) {
                this.showAlert(true, 'Ocorreu algo inesperado ao selecionar o teste, atualiza página e tente novamente ou contacte o adm.', 'alert-warning')
                return
            } else {
                //const cardRating = { ...rating }
                this.setState({ cardRating: { ...rating } })
            }

        }
    }

    renderTable() {
        return (
            <div className="content p-2">
                <div className="table-responsive">
                    <table className="table table-hover table-sm">
                        <caption>{`${this.state.pagination.ratingPage.length} de ${this.state.pagination.filter.length}`}</caption>
                        <thead>
                            <tr>
                                <th scope="col">Disc</th>
                                <th scope="col">Avaliador</th>
                                <th scope="col">Avaliado</th>
                                <th scope="col">Estrelas</th>
                                <th scope="col">Comentário</th>
                                <th scope="col">Criado</th>
                                <th scope="col">Ver</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderRows()}
                        </tbody>
                    </table>
                    <div>
                        <Pagination
                            activePage={this.state.pagination.activePage}
                            itemsCountPerPage={this.state.pagination.limit}
                            totalItemsCount={parseInt(this.state.pagination.count)}
                            innerClass={"pagination mr-5 d-flex justify-content-end"}
                            prevPageText={<i className="fa fa-chevron-left" />}
                            firstPageText={<i className="fa fa-angle-double-left" />}
                            nextPageText={<i className="fa fa-chevron-right" />}
                            lastPageText={<i className="fa fa-angle-double-right" />}
                            itemClass={"page-item"}
                            linkClass={"page-link"}
                            disabledClass={""}
                            pageRangeDisplayed={4}
                            onChange={e => this.pagination(e)}
                        />
                    </div>
                </div>
            </div>
        )
    }

    renderRows() {

        if (this.state.pagination.list.length) {

            return this.state.pagination.ratingPage.map(rating => {

                return (
                    <tr key={rating.id}>
                        <td>{rating.idTest}</td>
                        <td>{rating.evaluatorName}</td>
                        <td>{rating.ratedName.split(" ", 1)[0]}</td>
                        <td>{rating.stars}</td>
                        <td>{rating.message ? `${rating.message.substr(0, 20)}...` : '-'}</td>
                        <td>{formatDate(rating.createdAt)}</td>
                        <td>
                            <button id="btnGroupDrop1" type="button" className="btn btn-login dropdown-toggle fas fa-eye"
                                aria-haspopup="true" aria-expanded="false"
                                data-toggle="modal" data-target="#modalViewRating"
                                value={rating.id}
                                onClick={e => this.setRatingCard(e)}>
                            </button>
                        </td>
                    </tr>
                )
            })
        } else {
            return (
                <LoadingIconList loadingIconList={this.state.loadingIconList} />
            )
        }
    }

    render() {
        return (
            <Content>
                <Loading {...this.state.loading} />
                <Alert
                    msg={this.state.alert.msg}
                    class={this.state.alert.class}
                    onClick={e => this.showAlert(false)}
                    visible={this.state.alert.msg ? '' : 'd-none'}
                />
                {this.renderHeaderTable()}
                {this.renderTable()}
                <div id="ModalFormsEmail">
                    {this.renderCardRating()}
                </div>
            </Content>
        )
    }

}