import '../css/Logo.css'
import React from 'react'
import logo from '../../../assets/imgs/logo/cuboHeader.png'
import logoFace from '../../../assets/imgs/logo/cubohFaceHeader.png'
import { Link } from 'react-router-dom'
import $ from 'jquery'

const menuTogle = e => $(document).ready(function (e) {
    $('#sidebar').toggleClass('active');
});

export default props =>
    <aside className="logo">
        <div className="container-fluid">
            <div className="row ">
                <div className="d-flex align-items-center">

                    <div className="d-block d-sm-block d-lg-none">
                        <div id="menu-Bars" className="p-0 col-4 col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
                            <button onClick={e => menuTogle(e)} id="sidebarCollapse" className="btn fas fa-bars fa-2x text-white btn-link" />
                        </div>
                    </div>

                    <div className="d-none d-sm-block">
                        <div className="pl-2 col">
                            <Link to="/" className="img-fluid">
                                <img id="cubohName" src={logo || ''} alt="Logo" className="img-fluid" />
                            </Link>
                        </div>
                    </div>

                    <div className="d-block d-sm-none">
                        <div className="p-0 col">
                            <Link to="/" className="img-fluid">
                                <img id="cubohFace" src={logoFace || ''} alt="LogoFace" className="img-fluid" />
                            </Link>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </aside>