import React from 'react'
import '../css/Nav.css'
import { Link } from 'react-router-dom'
import $ from 'jquery'
import avatarDefault from '../../../assets/imgs/avatar/default.png'

$(document).ready(function (e) {

    $(document).mouseup(function (e) {
        if (!$('#sidebar').is(e.target) && $('#sidebar').has(e.target).length === 0 && !$('#sidebarCollapse').is(e.target)) {
            $('#sidebar').removeClass('active')
        }
    })

    $('#sidebar a').on('click', function () {
        $('#sidebar').toggleClass('active');
    });
});

const token = !!JSON.parse(localStorage.getItem('@disc-app/signin')) ? JSON.parse(localStorage.getItem('@disc-app/signin')) : '0'

export default props => {

    if (token.admin) {
        return (
            <div className="wrapper">
                <nav id="sidebar" className="">
                    <div className="sidebar-header">
                        <div className="row">
                            <div className="col-6 pr-0">
                                <img src={token.avatar ? `${process.env.REACT_APP_API_URL}/files/${token.avatar}` : avatarDefault} alt="Foto do Perfil" />
                            </div>
                            <div className="col-6 profile-info">
                                <p className="text-truncate">{token.name}</p>
                                <p className="text-truncate">{token.company}</p>
                            </div>
                        </div>
                    </div>

                    <ul id="listComponents" className="list-unstyled p-0 components my-auto">

                        <li>
                            <Link to="/">
                                <i className="fas fa-home fa-fw" /> Início
                            </Link>
                        </li>
                        <li>
                            <span href="#pageSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle"><i className="fas fa-user-shield fa-fw" /> Admin</span>
                            <ul className="collapse list-unstyled" id="pageSubmenu">
                                <li>
                                    <Link to="/creditsList">
                                        <i className="fas fa-credit-card fa-fw" /> Crédito
                                </Link>
                                </li>
                                <li>
                                    <Link to="/usersList">
                                        <i className="fas fa-user fa-fw" /> Usuário
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/postList">
                                        <i className="fas fa-rss fa-fw" />  Post
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/ratingsList">
                                        <i className="fas fa-star-half-alt fa-fw" />  Avaliações
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/testsList">
                                <i className="fas fa-play fa-fw" />Teste
                            </Link>
                        </li>
                        <li>
                            <Link to={`/groupsList`}>
                                <i className="fas fa-users fa-fw" /> Grupos <small className="badge bg-success ">Novo</small>
                            </Link>
                        </li>
                        <li>
                            <Link to={`/positionsList`}>
                                <i className="fas fa-id-badge fa-fw" /> Cargos <small className="badge bg-success">Novo</small>
                            </Link>
                        </li>
                        <li>
                            <Link to="/resultList">
                                <i className="fas fa-file-alt fa-fw" />  Resultados
                            </Link>
                        </li>
                        <li>
                            <span href="#pageSubmenuConfig" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle"><i className="fas fa-cog fa-fw" /> Configurar</span>
                            <ul className="collapse list-unstyled" id="pageSubmenuConfig">
                                <li>
                                    <Link to={`/usersCreate/${parseInt(token.id)}`}>
                                        <i className="fas fa-user fa-fw" /> Usuário
                                    </Link>
                                </li>

                                <li>
                                    <Link to={`/setReport/${parseInt(token.id)}`}>
                                        <i className="fas fa-print fa-fw" /> Relatório
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <Link to="/CreditsUser">
                                <i className="fab fa fa-dollar-sign fa-fw" />  Saldo <small className="badge bg-success">Novo</small>
                            </Link>
                        </li>
                        <li>
                            <Link to="/postWall">
                                <i className="fas fa-rss fa-fw" /> Blog
                            </Link>
                        </li>
                        <li>
                            <Link to="/Contact">
                                <i className="fab fa-whatsapp fa-fw" /> Contato
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div >
        )
    } else {
        return (
            <div className="wrapper">
                <nav id="sidebar" className="">
                    <div className="sidebar-header">
                        <div className="row">
                            <div className="col-6 pr-0">
                                <img src={token.avatar ? `${process.env.REACT_APP_API_URL}/files/${token.avatar}` : `${process.env.REACT_APP_API_URL}/files/default.png`} alt="Foto do Perfil" />
                            </div>
                            <div className="col-6 profile-info">
                                <p>{token.name}</p>
                                <p>{token.company}</p>
                            </div>
                        </div>
                    </div>
                    <ul id="listComponents" className="list-unstyled p-0 components">
                        <p>MENU</p>
                        <li>
                            <Link to="/">
                                <i className="fas fa-home fa-fw" /> Início
                            </Link>
                        </li>
                        <li>
                            <Link to="/testsList">
                                <i className="fas fa-play fa-fw" /> Teste
                            </Link>
                        </li>
                        <li>
                            <Link to={`/positionsList`}>
                                <i className="fas fa-id-badge fa-fw" /> Cargos <small className="badge bg-success">Novo</small>
                            </Link>
                        </li>
                        <li>
                            <Link to={`/groupsList`}>
                                <i className="fas fa-users fa-fw" /> Grupos <small className="badge bg-success">Novo</small>
                            </Link>
                        </li>
                        <li>
                            <Link to="/resultList">
                                <i className="fas fa-file-alt fa-fw" /> Resultados
                            </Link>
                        </li>

                        <li>
                            <span href="#pageSubmenu" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle"><i className="fas fa-cog fa-fw" /> Configurar</span>
                            <ul className="collapse list-unstyled" id="pageSubmenu">
                                <li>
                                    <Link to={`/usersCreate/${parseInt(token.id)}`}>
                                        <i className="fas fa-user fa-fw" /> Usuário
                                    </Link>
                                </li>

                                <li>
                                    <Link to={`/setReport/${parseInt(token.id)}`}>
                                        <i className="fas fa-print fa-fw" /> Relatório
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <Link to="/CreditsUser">
                                <i className="fab fa fa-dollar-sign fa-fw" /> Saldo <small className="badge bg-success">Novo</small>
                            </Link>
                        </li>
                        <li>
                            <Link to="/postWall">
                                <i className="fas fa-rss fa-fw" /> Blog
                            </Link>
                        </li>
                        <li>
                            <Link to="/Contact">
                                <i className="fab fa-whatsapp fa-fw" />   Contato
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        )
    }
}