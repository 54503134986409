import React, { Component } from "react";
import './VariousTests.css'
import API from '../../utils/API'
import readXlsxFile from 'read-excel-file'
import LoadingIconList from '../tools/LoadingIconList'
import excelCuboh from '../../assets/files/Cuboh.xlsx'

const schema = {
    'nome': {
        prop: 'name',
        type: String,
        required: true
    },
    'email': {
        prop: 'email',
        type: String,
        required: true
    },
    'genero': {
        prop: 'genre',
        type: String,
        required: true,
        oneOf: [
            'masculino',
            'feminino',
            'outros'
        ]
    },
    'cargo-Id': {
        prop: 'positionId',
        type: String
    },
    'grupo-Id': {
        prop: 'groupId',
        type: String,
    },
    'Anotações': {
        prop: 'note',
        type: String,
    }
}

const InitialState = {
    test: {
        name: '',
        email: '',
        note: '',
        genre: '',
        positionId: '',
        groupId: '',
    },
    showMoreInfo: false,
    groupLists: [],
    positionsLists: [],
    testsList: [],
    loadingIconList: false,
}

export default class VariousTests extends Component {

    state = InitialState

    toogleShowMoreInfo = () => {
        if (!this.state.groupLists.length && !this.state.positionsLists.length) {
            this.getGroupsList()
            this.getPositionsList()
        }
        this.setState({ showMoreInfo: !this.state.showMoreInfo })
    }

    clearTestsList = (e) => {
        e.preventDefault()
        this.setState({ testsList: InitialState.testsList, test: InitialState.test })
    }

    async getGroupsList() {
        await API.get('/getGroupsLists')
            .then(resp => {
                if (resp.data.length) {
                    this.setState({
                        groupLists: [
                            ...resp.data
                        ]
                    })
                } else {
                    this.setState({ loadingIconList: true })
                }
            })
            .catch(err => {
                console.log(err)
                this.props.notify('warning', 'Ocorreu algo inesperado ao tentar listar os grupos, contacte o administrador.')
                this.setState({ loadingIconList: true })
            })
    }

    async getPositionsList() {
        await API.get('/getPositionsLists')
            .then(resp => {
                if (resp.data.length) {
                    this.setState({
                        positionsLists: [
                            ...resp.data
                        ]
                    })
                } else {
                    this.setState({ loadingIconList: true })
                }
            })
            .catch(err => {
                console.log(err)
                this.props.notify('warning', 'Ocorreu algo inesperado ao tentar listar os créditos, contacte o administrador.')
                this.setState({ loadingIconList: true })
            })
    }

    async updateField(event) {
        const test = { ...this.state.test }
        test[event.target.id] = event.target.value
        await this.setState({ test })
    }

    async save(e) {
        e.preventDefault()
        const save = await this.props.save(this.state.testsList, 'various')

        if (save) {
            this.props.notify('success', 'Testes incluídos.')
            this.setState({ ...InitialState })
        }
    }

    async insertTestList(test) {
        const testsList = this.state.testsList
        const insert = await this.props.valid(test)

        if (!insert) return

        if (test.groupId) {
            if (isNaN(test.groupId)) return this.props.notify('warning', `O grupo (${test.groupId}) não é um número.`)
            test.groupName = await (this.state.groupLists.find(group => group.id === parseInt(test.groupId)) || '').name || "Não identificado"
        } else {
            delete test.groupId
        }

        if (test.positionId) {
            if (isNaN(test.positionId)) return this.props.notify('warning', `O Cargo (${test.positionId}) não é um número.`)
            test.positionName = await (this.state.positionsLists.find(position => position.id === parseInt(test.positionId)) || '').name || "Não identificado"
        } else {
            delete test.positionId
        }

        if ('testIndex' in test) { //Caso já tenho sido adicionado e esteja editando
            const index = await test.testIndex
            delete test.testIndex
            testsList.splice(index, 1, test)
        } else { //Caso seja sómente inclusão 
            testsList.push(test)
        }

        console.log('lista =>', testsList)
        this.setState({ testsList, test: InitialState.test })
        this.props.notify('info', `${test.name || ''} teste incluido.`)
    }

    async getNamePosition(idPosition) {

        if (isNaN(idPosition)) return false
        const positionName = await (this.state.positionsLists.find(position => position.idByUser === parseInt(idPosition)) || '').name || ""

        return positionName
    }

    editTestList(testIndex) {
        const selectTest = this.state.testsList[testIndex]
        selectTest.testIndex = testIndex
        this.setState({ test: selectTest })
    }

    async removeTestList(testIndex) {
        const { testsList } = this.state
        testsList.splice(testIndex, 1)
        this.setState({ testsList, test: InitialState.test })
    }

    async importExcel(e) {
        const file = e.target.files[0]

        e.preventDefault()

        this.setState({ loadingIconList: true })
        await this.getGroupsList()
        await this.getPositionsList()

        const typeFile = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
        const maxByte = 1024 * 1024 * 2 //Máximo de 2mb

        //Check pra saber se o arquivo é valido
        if (!typeFile.includes(file.type)) {
            this.props.notify('warning', 'Suportado apenas arquivos Excel.xlsx , baixe o modelo.')
            return
        } else if (file.size > maxByte) {
            this.props.notify('warning', 'Tamanho da imagem não permitido.(máximo: 2mb)')
            return
        }

        readXlsxFile(file, { schema }).then(async ({ rows, errors }) => {

            if (errors.length) {
                console.log('erros => ', errors)
                this.props.notify('warning', `Linha ${errors[0].row + 1}, coluna '${errors[0].column}' não aceita o valor '${errors[0].value}', veja a aba instruções na planilha.`)

            } else {
                for (let i = 0; i < rows.length; i++) {

                    if (! await this.props.valid(rows[i])) return this.props.notify('warning', `Linha ${i + 2}, Email(${rows[i].email}) inválido, veja a aba instruções na planilha.`)

                    if ('positionId' in rows[i]) {
                        const position = await this.state.positionsLists.find(position => position.idByUser === parseInt(rows[i].positionId)) || false
                        if (!position) return this.props.notify('warning', `Cargo(${rows[i].positionId}) da linha ${i + 2} não identificado, veja a aba instruções na planilha.`)
                        rows[i].positionName = position.name
                        rows[i].positionId = position.id
                    }

                    if ('groupId' in rows[i]) {
                        const group = await this.state.groupLists.find(group => group.idByUser === parseInt(rows[i].groupId)) || false
                        if (!group) return this.props.notify('warning', `Grupo(${rows[i].groupId}) da linha ${i + 2} não identificado, veja a aba instruções na planilha.`)
                        rows[i].groupName = group.name
                        rows[i].groupId = group.id
                    }
                }

                this.setState({ testsList: rows })
                this.props.notify('success', `${rows.length} testes importados.`)
            }
        })

        this.setState({ loadingIconList: false })
    }

    renderForm() {
        return (
            <div className='container-fluid'>
                <div className="row py-0">

                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 px-0 pr-md-2 ">
                        <div className="form-group">
                            <small className="d-flex form-text text-CubohColor2 m-0">Nome: <span className="badge text-danger">*</span></small>
                            <div className="input-group input-group-sm">
                                <input type="text" id="name" autoComplete="new-password" value={this.state.test.name || ''} onChange={e => this.updateField(e)} placeholder="Maria Silva" className="form-control" />
                            </div>

                            <div className="ml-1">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" id="genre" value="masculino" checked={this.state.test.genre === 'masculino' ? true : false} onChange={e => this.updateField(e)} />
                                    <small className="form-check-label" htmlFor="inlineCheckbox1">Masc.</small>
                                </div>

                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" id="genre" value="feminino" checked={this.state.test.genre === 'feminino' ? true : false} onChange={e => this.updateField(e)} />
                                    <small className="form-check-label" htmlFor="inlineCheckbox2">Femin.</small>
                                </div>

                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" id="genre" value="outros" checked={this.state.test.genre === 'outros' ? true : false} onChange={e => this.updateField(e)} />
                                    <small className="form-check-label" htmlFor="inlineCheckbox3">Outros</small>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 px-0 ">
                        <div className="form-group">
                            <small className="d-flex form-text text-CubohColor2 m-0">E-mail: <span className="badge text-danger">*</span></small>
                            <div className="input-group input-group-sm">
                                <input className="form-control" type="23email32" id="email"
                                    autoComplete="new-password" value={this.state.test.email || ''}
                                    onChange={e => this.updateField(e)} placeholder="maria.silva@exemplo.com.br" aria-describedby="emailHelp" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row pb-1">

                    <div className="col-12 px-0">
                        <div className="separator">
                            <div className="btn-group btn-group-sm" role="group" aria-label="Basic example" data-toggle="collapse" href="#showMoreInfo" aria-expanded="false" aria-controls="showMoreInfo" onClick={e => this.toogleShowMoreInfo(e)}>
                                <button type="button" className="btn btn-rounded-left buttonInative font-weight-normal">Mais informações</button>
                                <button type="button" className="btn btn-rounded-right buttonActive"><i className={`fas fa-arrow-${this.state.showMoreInfo ? 'up' : 'down'} down fa-md`} /></button>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="collapse" id="showMoreInfo">
                    <div className="row py-1 px-0">

                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 px-0 mt-2 pr-md-2">
                            <small className="d-flex form-text text-CubohColor2 m-0">Cargo: <span className="badge text-muted">(opcional)</span></small>
                            <div className="input-group input-group-sm">
                                <select className="custom-select custom-select-sm" aria-label="Select the positions"
                                    value={this.state.test.positionId || '0'} id="positionId"
                                    onChange={e => this.updateField(e)}>
                                    <option defaultValue>Escolha o cargo...</option>
                                    {this.renderListOfPositions()}
                                </select>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 px-0 mt-2">
                            <small className="d-flex form-text text-CubohColor2 m-0">Grupo: <span className="badge text-muted">(opcional)</span></small>
                            <div className="input-group input-group-sm">
                                <select className="custom-select custom-select-sm" aria-label="Select the group"
                                    value={this.state.test.groupId || '0'} id="groupId"
                                    onChange={e => this.updateField(e)}>
                                    <option defaultValue>Escolha o grupo...</option>
                                    {this.renderListOfGroups()}
                                </select>
                            </div>
                        </div>

                        <div className="col-12 px-0 mt-1 mb-0">
                            <div className="form-group">
                                <small className=" d-flex form-text text-CubohColor2 m-0">Anotações: <span className="badge text-muted">(opcional)</span></small>
                                <div className="input-group input-group-sm">
                                    <textarea
                                        autoComplete="new-password"
                                        className="form-control" rows="2" maxLength="250"
                                        id="note"
                                        placeholder="Área para anotações."
                                        value={this.state.test.note || ''}
                                        onChange={e => this.updateField(e)} />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="row py-1">
                    <div className="col-12 d-flex justify-content-between px-0">
                        <div className="btn-group btn-group-sm" role="group">
                            <button id="btnGroupVerticalDrop2" type="button" className="btn btn-sm btn-outline-secondary rounded-right px-2 w-25" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Opções
                                <i className="px-2 fas fa-chevron-down" />
                            </button>
                            <div className="dropdown-menu" aria-labelledby="btnGroupVerticalDrop2" x-placement="bottom-start" style={{ position: "absolute", transform: "translate3d(0px, 38px, 0px)", top: "0px", left: "0px", willChange: "transform" }}>
                                <a className="dropdown-item px-1" href="/" onClick={e => this.clearTestsList(e)} > <i className="px-1 fas fa-eraser fa-md text-CubohColor1 fw-normal" /> Limpar lista</a>
                                <a href={excelCuboh} className='dropdown-item px-1' target="_self" rel="noopener noreferrer"> <i className=" px-1 fas fa-arrow-alt-circle-down fa-md text-CubohColor1 fw-normal" /> Baixar Modelo</a>
                                <label className="dropdown-item px-1" htmlFor="excelFile"> <i className="px-1 fas fa-upload fa-md text-CubohColor1 fw-normal" /> Importar Lista</label>
                                <input name="excelImport" value="" onChange={e => this.importExcel(e)} id="excelFile" type="file"
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                            </div>
                        </div>

                        <button className="btn btn-sm btn-insertTests no-wrap" type="submit"
                            onClick={e => this.insertTestList({ ...this.state.test })}>
                            {'testIndex' in this.state.test ? 'Atualizar' : 'Incluir'}
                            <i className="px-2 fas fa-plus" />
                        </button>
                    </div>
                </div>

                <div className="row py-1">

                    <div className="col-12 px-0">
                        <div className="content">
                            <div className="table-responsive table-responsive-sm ">
                                <table className="table table-hover table-sm table-striped border">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Nome/Email</th>
                                            <th className="HiddenColumnOnMobile" scope="col">Cargo/Grupo</th>
                                            <th scope="col">Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.renderRowsTests()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 d-flex justify-content-end px-0">
                        <button className="btn btn-sm btn-success w-50" type="submit"
                            onClick={e => this.save(e)}>
                            Enviar para todos
                        </button>

                    </div>
                </div>
            </div >
        )
    }

    renderListOfPositions() {
        if (this.state.positionsLists.length) {
            return this.state.positionsLists.map(position => {
                return <option key={position.id} value={position.id}>{position.name}</option>
            })
        } else {
            return <option value="0">Nenhum Cargo cadastrado</option>
        }
    }

    renderListOfGroups() {
        if (this.state.groupLists.length) {
            return this.state.groupLists.map(group => {
                return <option key={group.id} value={group.id}>{group.name}</option>
            })
        } else {
            return <option value="0">Nenhum Grupo cadastrado</option>
        }
    }

    renderRowsTests() {

        if (this.state.testsList.length) {
            return this.state.testsList.map((test, testIndex) => {
                return (
                    <tr className="align-middle" key={testIndex + 1}>
                        <td className="">{testIndex + 1}</td>
                        <td className=" ColumnTextShort">{test.name}<br />{test.email}</td>
                        <td className="HiddenColumnOnMobile">{test.positionName}<br />{test.groupName}</td>
                        <td><i className="far fa-edit" onClick={e => this.editTestList(testIndex)} /> | <i className="fas fa-trash" onClick={e => this.removeTestList(testIndex)} /></td>
                    </tr>
                )
            })
        } else {

            if (this.state.loadingIconList) {
                return <LoadingIconList loadingIconList={this.state.loadingIconList} />
            } else {
                return (
                    <React.Fragment>
                        <tr className="align-middle">
                            <td colSpan="4">
                                Crie o teste e depois clique no botão incluir
                            </td>
                        </tr>
                        <tr className="align-middle">
                            <td colSpan="4">
                                Para importar um arquivo excel utilize o botão opções
                            </td>
                        </tr>
                    </React.Fragment>
                )
            }

        }

    }

    render() {
        return (
            <div id="TestsVarious">
                {this.renderForm()}
            </div>
        )
    }
}