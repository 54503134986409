import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './PostCard.css'

export default class PostCard extends Component {

    buildCards(props) {
        switch (props.type ? props.type.toString() : props.type) {
            case '1': return (
                <Link to={`/postViewer/${props.pdfUrl ? props.pdfUrl : '/'}`} className="btn-link" >
                    <div className='card ' id={props.id}>
                        <div className="overlay">
                            <img className={`card-img-top ${props.urlImage ? '' : 'd-none'}`} src={props.urlImage || ''} alt="Card cap" />
                        </div>
                        <div className="card-body">
                            <h5 className="card-title">{props.title || ''}</h5>
                            <p className="card-text">{props.subTitle || ''}</p>
                        </div>
                    </div>
                </Link>
            )

            case '2': return (
                <Link to={`/postViewer/${props.pdfUrl ? props.pdfUrl : '/'}`} className="btn-link" >
                    <div className='card  text-center' id={props.id}>
                        <div className="overlay">
                            <img className={`card-img-bottom ${props.urlImage ? '' : 'd-none'}`} src={props.urlImage || ''} alt="Card cap" />
                        </div>
                        <div className="card-body">
                            <h5 className="card-title">{props.title || ''}</h5>
                            <p className="card-text text-justify">{props.subTitle || ''}</p>
                        </div>
                    </div>
                </Link>
            )

            case '3': return (
                <Link to={`/postViewer/${props.pdfUrl ? props.pdfUrl : '/'}`} className="btn-link" >
                    <div className='card ' id={props.id}>
                        <div className="card-body">
                            <h5 className="card-title">{props.title || ''}</h5>
                            <p className="card-text">{props.subTitle || ''}</p>
                        </div>
                        <div className="overlay">
                            <img className={`card-img-bottom ${props.urlImage ? '' : 'd-none'}`} src={props.urlImage || ''} alt="Card cap" />
                        </div>
                    </div>
                </Link>
            )
            case '4': return (
                <Link to={`/postViewer/${props.pdfUrl ? props.pdfUrl : '/'}`} className="btn-link" >
                    <div className='card  text-center' id={props.id}>
                        <div className="card-body">
                            <h5 className="card-title">{props.title || ''}</h5>
                            <p className="card-text text-justify">{props.subTitle || ''}</p>
                        </div>
                        <div className="overlay">
                            <img className={`card-img-bottom ${props.urlImage ? '' : 'd-none'}`} src={props.urlImage || ''} alt="Card cap" />
                        </div>
                    </div>
                </Link>
            )
            case '5': return (

                <Link to={`/postViewer/${props.pdfUrl ? props.pdfUrl : '/'}`} className="btn-link" >
                    <div className='card  text-center p-3' id={props.id}>
                        <blockquote className="blockquote mb-0 card-body">
                            <p className="card-text">{props.subTitle || ''}</p>
                        </blockquote>
                    </div>
                </Link>
            )
            case '6': return (
                <Link to={`/postViewer/${props.pdfUrl ? props.pdfUrl : '/'}`} className="btn-link" >
                    <div className="card bg-primary text-center p-3" id={props.id}>
                        <blockquote className="blockquote">
                            <p className="card-text text-white font-weight-bold">{props.subTitle || ''}</p>
                        </blockquote>
                    </div>
                </Link>
            )

            case '7': return (
                <Link to={`/postViewer/${props.pdfUrl ? props.pdfUrl : '/'}`} className="btn-link" >
                    <div className="card bg-warning text-center p-3" id={props.id}>
                        <blockquote className="blockquote">
                            <p className="card-text text-white font-weight-bold">{props.subTitle || ''}</p>
                        </blockquote>
                    </div>
                </Link>
            )

            case '8': return (
                <Link to={`/postViewer/${props.pdfUrl ? props.pdfUrl : '/'}`} className="btn-link" >
                    <div className="card bg-danger text-center p-3" id={props.id}>
                        <blockquote className="blockquote">
                            <p className="card-text text-white font-weight-bold">{props.subTitle || ''}</p>
                        </blockquote>
                    </div>
                </Link>
            )

            case '9': return (
                <Link to={`/postViewer/${props.pdfUrl ? props.pdfUrl : '/'}`} className="btn-link" >
                    <div className="card bg-success text-center p-3" id={props.id}>
                        <blockquote className="blockquote">
                            <p className="card-text text-white font-weight-bold">{props.subTitle || ''}</p>
                        </blockquote>
                    </div>
                </Link>
            )

            case '10': return (
                <Link to={`/postViewer/${props.pdfUrl ? props.pdfUrl : '/'}`} className="btn-link" >
                    <div className="card" id={props.id}>
                        <img className={`card-img-bottom ${props.urlImage ? '' : 'd-none'}`} src={props.urlImage || ''} alt="Card cap" />
                    </div>
                </Link>
            )

            default: return (<p className="text-center m-5">Visualizador de Post...</p>)

        }

    }

    render() {
        return (
            this.buildCards(this.props)
        )
    }
}