import React from 'react'

import './App.css'
import 'jquery'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import '@fortawesome/fontawesome-free/css/all.css'

//import { HashRouter } from 'react-router-dom'
import { BrowserRouter } from 'react-router-dom'

//importando componentes da aplicação
import Nav from '../components/template/jsx/Nav'
import Routes from './Routes'
import Footer from '../components/template/jsx/Footer'
import Header from '../components/template/jsx/Header'
import Logo from '../components/template/jsx/Logo'

export default props =>

    <BrowserRouter>
        <div id="app" className="app">
            <Logo />
            <Header />
            <Nav />
            <Routes />
            <Footer />
        </div>
    </BrowserRouter>