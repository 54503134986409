import React, { Component } from 'react'

export default class setApresentation extends Component {


    render() {
        return (
            
                <h1> set apresentation config</h1>
            
                
        )

    }
}