import React, { useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router'
import RequireAuth from '../utils/Auth'
import Admin from '../utils/Admin'

//Google Analytics
import ReactGa from 'react-ga'

import Home from '../components/home/Home'
import UserCrud from '../components/user/UserCrud'
import UserList from '../components/user/UserList'
import TestsCrud from '../components/tests/TestsCrud'
import TestsList from '../components/tests/TestsList'
import QuestionsList from '../components/questions/QuestionsList'
import Token from '../components/token/Token'
import Login from '../components/login/Login'
import Welcome from '../components/welcome/Welcome'
import Answer from '../components/answer/Answer'
import Congratulation from '../components/congratulation/Congratulation'
import ResultList from '../components/result/ResultList'
import ResultAnswers from '../components/result/ResultAnswers'
import FinalReport from '../components/result/FinalReport'
import CreditsList from '../components/credits/CreditsList'
import CreditsCrud from '../components/credits/CreditsCrud'
import Contact from '../components/contact/Contact'
import Dashboard from '../components/dashboard/Dashboard'
import PostViewer from '../components/post/PostViewer'
import PostCrud from '../components/post/PostCrud'
import PostList from '../components/post/PostList'
import RatingsList from '../components/rating/RatingsList'
import SetReport from '../components/reportConfig/SetReport'
import CreditsUser from '../components/credits/CreditsUser'
import GroupsCrud from '../components/groups/GroupsCrud'
import GroupsList from '../components/groups/GroupsList'
import GroupsHierarchy from '../components/groups/GroupsHierarchy'
import TestsVsGroups from '../components/groups/TestsVsGroups'
import PositionsList from '../components/positions/PositionsList'
import PostWall from '../components/post/PostWall'

export default function Routes() {

    useEffect(() => {
        ReactGa.initialize('UA-160602629-1')
        //to report the page view
        ReactGa.pageview(window.location.pathname + window.location.search)
    }, [])

    return (

        < Switch >
            <Route exact path='/' component={RequireAuth(Home)} />
            <Route path='/home' component={RequireAuth(Home)} />
            <Route path='/usersList' component={Admin(UserList)} />
            <Route path='/usersCreate/:id' component={RequireAuth(UserCrud)} />
            <Route path='/usersCreate' component={Admin(UserCrud)} />
            <Route path='/testsCreate' component={RequireAuth(TestsCrud)} />
            <Route path='/testsList' component={RequireAuth(TestsList)} />
            <Route path='/testsEdit/:id' component={RequireAuth(TestsCrud)} />
            <Route path='/questionsList' component={RequireAuth(QuestionsList)} />
            <Route path='/resultList' component={RequireAuth(ResultList)} />
            <Route path='/setReport/:id' component={RequireAuth(SetReport)} />
            <Route path='/resultAnswers/:id' component={RequireAuth(ResultAnswers)} />
            <Route path='/finalReport/:id' component={RequireAuth(FinalReport)} />
            <Route path='/creditsList' component={Admin(CreditsList)} />
            <Route path='/creditsUser' component={RequireAuth(CreditsUser)} />
            <Route path='/creditsCrud' component={Admin(CreditsCrud)} />
            <Route path='/creditsEdit/:id' component={Admin(CreditsCrud)} />
            <Route path='/postViewer/:name' component={RequireAuth(PostViewer)} />
            <Route path='/postCreate' component={Admin(PostCrud)} />
            <Route path='/PostList' component={Admin(PostList)} />
            <Route path='/PostEdit/:id' component={Admin(PostCrud)} />
            <Route path='/groupsCreate/:id' component={RequireAuth(GroupsCrud)} />
            <Route path='/groupsCreate' component={RequireAuth(GroupsCrud)} />
            <Route path='/groupsList' component={RequireAuth(GroupsList)} />
            <Route path='/groupsHierarchy' component={RequireAuth(GroupsHierarchy)} />
            <Route path='/groupsVsTests' component={RequireAuth(TestsVsGroups)} />
            <Route path='/positionsList' component={RequireAuth(PositionsList)} />
            <Route path='/contact' component={RequireAuth(Contact)} />
            <Route path='/dashboard' component={RequireAuth(Dashboard)} />
            <Route path='/ratingsList' component={Admin(RatingsList)} />

            <Route path='/postWall' component={RequireAuth(PostWall)} />

            <Route path='/Token' component={Token} />
            <Route path='/Login' component={Login} />
            <Route path='/Welcome' component={Welcome} />
            <Route path='/Answer' component={Answer} />
            <Route path='/Congratulation' component={Congratulation} />
            <Redirect from='*' to='/' />
        </Switch >
    )
}