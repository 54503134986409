import React, { Component } from 'react'
import avatarDefault from '../../assets/imgs/logoHeader.png'
import examplaCover from '../../assets/imgs/exampleCover.jpg'
import './SetCover.css'
import Alert from '../tools/Alert'
import API from '../../utils/API'
import { ifError } from '../tools/utils'
import Loading from '../tools/Loading'

const initialState = {

    customCover: {
        id: null,
        idUser: '',
        companyTitle: '',
        companyName: '',
        ratedTitle: '',
        imageUrl: '',
    },
    imageFile: null,
    imageUpdate: null,
    imagePreview: null,
    loading: {
        class: 'd-none',
        msg: 'loading...'
    },
    alert: {
        class: 'alert-warning',
        msg: null,
        onClick: null,
        visible: alert.msg ? '' : 'd-none',
    },
}

export default class setCover extends Component {

    state = { ...initialState }

    async componentDidMount() {
        this.showAlert(false)
        await this.getCustomCover(this.props.match.params.id)
    }

    async getCustomCover(id) {
        let customCover = this.state.customCover

        await API.get(`/customCover/${id}`)
            .then(resp => {
                customCover = {
                    id: resp.data.id || null,
                    idUser: resp.data.idUser || null,

                    companyTitle: resp.data.companyTitle,
                    companyName: resp.data.companyName,
                    ratedTitle: resp.data.ratedTitle,
                    imageUrl: resp.data.imageUrl,

                }
                this.setState({ customCover })
            })
            .catch(err => console.log('Err =>', err.response))

    }

    fileSelected(e) {
        const file = e.target.files[0]
        if (!file) return

        const customCover = this.state.customCover
        const format = file.type.substr(0, 5) === 'image' ? 'img' : null
        const fileType = ['image/jpeg', 'image/png', 'image/jpg']
        const maxByte = 1024 * 1024 * 2 //Máximo de 2mb 

        //Check pra saber se a imagem é valida      
        if (!format) {
            this.showAlert(true, 'Tipo de extensão não permitada.', 'alert-danger')
            return
        } else if (!fileType.includes(file.type)) {
            this.showAlert(true, `Tipo não permitido. (permitido: ${fileType})`, 'alert-danger')
            return
        } else if (file.size > maxByte) {
            this.showAlert(true, `Tamanho do arquivo não permitido. (máximo: ${maxByte / 1048576}Mb)`, 'alert-danger')
            return
        }
        else {
            const imageUpdate = URL.createObjectURL(file)
            customCover.imageUrl = file
            this.setState({ customCover, imageUpdate, imagePreview: true })
        }
    }

    showAlert(show, msg, color) {
        const alert = this.state.alert
        if (show === true) {
            alert.msg = msg
            alert.class = color
            this.setState({ alert })
        } else {
            alert.msg = null
            this.setState({ alert })
        }
    }

    removeImage(e) {
        e.preventDefault()
        const customCover = this.state.customCover
        customCover.imageUrl = ''
        this.setState({ customCover, imageUpdate: null, imagePreview: null })
    }

    async updateField(event) {
        const customCover = { ...this.state.customCover }
        customCover[event.target.id] = event.target.value
        this.setState({ customCover })
    }

    loading(show, msg) {
        const loading = this.state.loading
        if (show === true) {
            loading.msg = msg
            loading.class = ''
            this.setState({ loading })
        } else {
            loading.class = 'd-none'
            this.setState({ loading })
        }
    }

    validForm(e) {
        const { customReport } = this.state
        let ret = false

        console.log(customReport)

        if (!customReport.imageUrl && !customReport.name && !customReport.company) {
            this.showAlert(true, 'Informar ao menos um campo.', 'alert-warning')
        } else {
            ret = true
        }
        return ret
    }

    save(e) {
        e.preventDefault()
        this.showAlert(false)
        const customCover = this.state.customCover

        if (!customCover.idUser) customCover.idUser = this.props.match.params.id

        const fd = new FormData()
        const method = customCover.id ? 'put' : 'post'
        const url = `/customCover/${this.props.match.params.id}`

        //if (this.validForm()) {

        Object.keys(customCover).forEach(function (item) {
            if (typeof customCover[item] === 'object' && customCover[item] !== null) {
                fd.append(item, customCover[item], customCover[item].name)
            } else if (customCover[item] !== null) {
                fd.append(item, customCover[item] || "")
            } else {

            }
        })

        API[method](url, fd, {
            onUploadProgress: ProgressEvent => {
                const percent = Math.round(ProgressEvent.loaded / ProgressEvent.total * 100)
                if (percent < 100) {
                    this.loading(true, `Enviando arquivo... ${percent}%`)
                } else {
                    this.loading(true, `Processando dados...`)
                }
            }
        })
            .then(resp => {
                this.loading(false)
                this.getCustomCover(customCover.idUser)
                this.showAlert(true, 'Sua capa foi customizada com sucesso.', 'alert-success')
            })
            .catch(err => {
                this.loading(false)
                console.log('err =', err)
                const backWs = ifError(err)
                this.showAlert(true, backWs, 'alert-danger')
            })
        //}

    }

    renderForms() {
        return (
            <div id="SetCover">

                <div className="container-fluid">


                    <div className="row mt-3">

                        <div className="col-xs-12 col-sm-12 col-md-5">
                            <div className="card" >
                                <div className="card-body">
                                    <h5 className="card-title text-CubohColor2">Customize a capa do seu relatório</h5>
                                    <p className="card-text">Customize seu relatório! Queremos você junto conosco, sua logo aparecerá no cabeçalho de todas as páginas. Preencha os campos a seguir e veja como vai ficar.</p>
                                </div>
                                <div id="accordion">
                                    <div className="card">
                                        <div className="card-header" id="headingOne">
                                            <h5 className="mb-0">

                                                <button className="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    Preencha e veja como vai ficar
                                                <i className="fas fa-arrow-circle-down ml-2" />
                                                </button>
                                            </h5>
                                        </div>

                                        <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">

                                            <div className="card-body ">

                                                <div className={`row ${this.state.imagePreview || this.state.customCover.imageUrl ? '' : 'd-none'}`}>
                                                    <img className="img-header" id="crudLogoHeader" src={this.state.imageUpdate ? this.state.imageUpdate : this.state.customCover.imageUrl ? `${process.env.REACT_APP_API_URL}/logoCover/${this.state.customCover.imageUrl}` : avatarDefault} alt="avatar Perfil" />
                                                </div>


                                                <p className="m-0"> {this.state.customCover.companyTitle ? this.state.customCover.companyTitle : 'Titulo'}: {this.state.customCover.companyName ? this.state.customCover.companyName : 'Cuboh Tecnologia LTDA'}</p>
                                                <p className="m-0"> {this.state.customCover.ratedTitle ? this.state.customCover.ratedTitle : 'Avaliado(a)'}: Maria Luna Santos</p>
                                                <p className="m-0"> Perfil: Empreendedor</p>
                                                <p className="m-0"> Data: 18/09/2020</p>

                                            </div>

                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingTwo">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    Exibir exemplo de capa
                                                <i className="fas fa-arrow-circle-down ml-2" />
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                            <div className="card-body">
                                                <img className="card-img-bottom" src={examplaCover} alt="Logo preview" />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-7 mt-3">

                            <div className="col-xs-12 col-sm-12 col-md-10">
                                <div className="form">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                            <div className="form-group">
                                                <small className=" d-flex form-text text-CubohColor2 m-0">1- Como você quer ser chamado(a) na capa?</small>
                                                <div className="input-group">
                                                    {/*
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text btn-login">Titulo</span>
                                                    </div>
                                                */}
                                                    <input type="text" id="companyTitle" value={this.state.customCover.companyTitle || ''} onChange={e => this.updateField(e)} placeholder="Empresa, Consultoria, Clínica, Coach, Analista Disc, ..." className="form-control" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                            <div className="form-group">
                                                <small className=" d-flex form-text text-CubohColor2 m-0">2- Você quer customizar seu nome na capa?</small>
                                                <div className="input-group">

                                                    <input type="text" id="companyName" value={this.state.customCover.companyName || ''} onChange={e => this.updateField(e)} placeholder="Cuboh Tecnologia LTDA" className="form-control" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                            <div className="form-group">
                                                <small className=" d-flex form-text text-CubohColor2 m-0">3- Como você quer chamar seu cliente?</small>
                                                <div className="input-group">

                                                    <input type="text" id="ratedTitle" value={this.state.customCover.ratedTitle || ''} onChange={e => this.updateField(e)} placeholder="Colaborador, Cliente, Paciente, Coachee, ..." className="form-control" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                                            <small className=" d-flex form-text text-CubohColor2 m-0">4- Gostaria de ter sua logo na capa?</small>
                                            <div className="input-group mb-2">

                                                <div className="input-group-prepend">
                                                    <label htmlFor="inputPictureCover" className="input-group-text inputFileImageUrl" type="button">
                                                        {
                                                            this.state.imagePreview || this.state.customCover.imageUrl ?
                                                                <i>{this.state.imageUpdate ? this.state.customCover.imageUrl.name : this.state.customCover.imageUrl ? this.state.customCover.imageUrl.split('_').slice(3, 4).join(' ') : 'Click e insira sua logo na capa'}</i>
                                                                :
                                                                <small className='text-muted'>
                                                                    Clique aqui para inserir sua logo
                                                            </small>
                                                        }

                                                    </label>
                                                    <input type="file" name="coverImageUrl" onChange={e => this.fileSelected(e)} id="inputPictureCover" className="form-control" />
                                                </div>

                                                <div className="input-group-prepend">
                                                    <label htmlFor="clearImg" className="input-group-text btn-danger" type="button">
                                                        <i className="fas fa-trash fa-lg" />
                                                    </label>
                                                    <input name="imageUrl" onChange={e => this.removeImage(e)} id="clearImg" className="form-control btn btn-danger" type="checkbox" />
                                                </div>
                                            </div>
                                            <small id="emailHelp" className=" d-flex form-text text-muted">*Ideal .png(tamanho 250x130) ou imagem com fundo branco.</small>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr />
                            <div className="row">
                                <div className="col-12 d-flex justify-content-end">
                                    <button className="btn btn-success ml-2" type="submit"
                                        onClick={e => this.save(e)}>
                                        Salvar
                                </button>
                                </div>
                            </div>
                        </div>
                    </div >
                </div>
            </div >

        )
    }

    render() {
        return (
            <React.Fragment>
                <Loading {...this.state.loading} />
                <Alert
                    msg={this.state.alert.msg}
                    class={this.state.alert.class}
                    onClick={e => this.showAlert(false)}
                    visible={this.state.alert.msg ? '' : 'd-none'}
                />
                {this.renderForms()}
            </React.Fragment>
        )
    }

}