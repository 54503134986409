import React, { Component } from 'react'
import './Congratulation.css'
import API from '../../utils/API'
//import $ from 'jquery'

const InitialState = {
    test: {
        name: '', idTest: '', token: '', email: '', status: '',
    },
    starHover: '',
    selectedStar: '',
    message: '',
}

export default class Congratulation extends Component {

    state = { ...InitialState }

    async componentDidMount() {
        await this.isToken()
    }

    isToken() {
        if (sessionStorage.getItem('@disc-app/test') === null) {
            window.location.replace('/Token')
        } else {
            const test = JSON.parse(sessionStorage.getItem('@disc-app/test'))
            this.setState({ test })
        }
    }

    async exit(e) {
        e.preventDefault()

        if (this.state.selectedStar || this.state.message) await this.sendRating()

        sessionStorage.removeItem('@disc-app/test')
        window.location.replace('/Token')

    }

    hoverStars(e) {
        e.preventDefault()
        this.setState({ starHover: e.target.id })
    }

    outStars(e) {
        e.preventDefault()
        this.setState({ starHover: '' })
    }

    setStars(e) {
        e.preventDefault()
        this.setState({ selectedStar: e.target.id })
    }

    updateField(e) {
        e.preventDefault()
        this.setState({ message: e.target.value })
    }

    async sendRating() {

        const rating = {
            idTest: this.state.test.idTest,
            idUser: this.state.test.evaluator_id,
            stars: this.state.selectedStar,
            message: this.state.message,
        }

        await API.post('rating', rating)
            .then(suc => {
                console.log('Enviado com sucesso')
            })
            .catch(err => {
                console.log('Erro! ', err.response.data.msg)
            })

    }

    renderPage() {
        return (
            <section id="Congratulation" className="">
                <div className="container py-3">
                    <div className="row mt-md-3">
                        <div className="col-sm-12 col-md-8 col-lg-8">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <div className="d-flex justify-content-center">
                                            <img id="crudAvatarPefil" className="img-fluid icon-circle" alt="logo" src={this.state.test.evaluator_avatar ? `${process.env.REACT_APP_API_URL}/files/${this.state.test.evaluator_avatar}` : `${process.env.REACT_APP_API_URL}/files/default.png`} />
                                        </div>
                                        <div className="d-flex justify-content-center py-2">
                                            <p className="h6 font-weight-500">{this.state.test.evaluator_name || ''}, {this.state.test.evaluator_company || ''}.</p>
                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
                                        <h5 className="section-title font-weight-bold mb-3">Parabéns {`${this.state.test.name || 'Meu amigo'}!`}</h5>
                                        <p className="text-muted h5 px-2">Seu processo foi concluído com sucesso!!!</p>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mt-xs-4 pr-md-4">
                            <div className="d-sm-none border-top border-primary w-50 mx-auto my-2" />
                            <p className="text-muted px-2 text-center">Avalie sua experiência</p>
                            <div className="row d-flex justify-content-center">
                                <i className={`${this.state.selectedStar >= '1' || this.state.starHover >= '1' ? 'fas text-warning' : 'far text-muted'} fa-star fa-2x px-1 `} id="1" onMouseOver={e => this.hoverStars(e)} onMouseOut={e => this.outStars(e)} onClick={e => this.setStars(e)} />
                                <i className={`${this.state.selectedStar >= '2' || this.state.starHover >= '2' ? 'fas text-warning' : 'far text-muted'} fa-star fa-2x px-1 `} id="2" onMouseOver={e => this.hoverStars(e)} onMouseOut={e => this.outStars(e)} onClick={e => this.setStars(e)} />
                                <i className={`${this.state.selectedStar >= '3' || this.state.starHover >= '3' ? 'fas text-warning' : 'far text-muted'} fa-star fa-2x px-1 `} id="3" onMouseOver={e => this.hoverStars(e)} onMouseOut={e => this.outStars(e)} onClick={e => this.setStars(e)} />
                                <i className={`${this.state.selectedStar >= '4' || this.state.starHover >= '4' ? 'fas text-warning' : 'far text-muted'} fa-star fa-2x px-1 `} id="4" onMouseOver={e => this.hoverStars(e)} onMouseOut={e => this.outStars(e)} onClick={e => this.setStars(e)} />
                                <i className={`${this.state.selectedStar >= '5' || this.state.starHover >= '5' ? 'fas text-warning' : 'far text-muted'} fa-star fa-2x px-1 `} id="5" onMouseOver={e => this.hoverStars(e)} onMouseOut={e => this.outStars(e)} onClick={e => this.setStars(e)} />
                            </div>


                            <div className="form-group my-3 mx-2">
                                <textarea
                                    className="form-control" rows="6" maxLength="253"
                                    id="message"
                                    placeholder="Deixe seus elogios ou sugestões, sua opnião vale muito!!!"
                                    value={this.state.message || ''}
                                    onChange={e => this.updateField(e)} />
                            </div>

                            <div className="row mt-sm-4">
                                <div className="col-sm-12 col-md-12 col-lg-12 text-center" >
                                    <button onClick={e => this.exit(e)} type="button" className="btn btn-login text-light btn-lg">
                                        Finalizar
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </section >
        )
    }

    render() {
        return (
            <React.Fragment>
                {this.renderPage()}
            </React.Fragment>
        )
    }
}