import React, { Component } from 'react'
//<i className="fas fa-circle-notch fa-spin color-header fa-5x"></i>

export default class loadingIconList extends Component {

    render() {
        if (this.props.type === "div") {
            return (
                <div className="text-center">
                    {this.props.loadingIconList ? <p className="color-header font-weight-bold fa-lg m-2">Sem dados até o momento.</p> : <i className="fas fa-circle-notch fa-pulse color-header fa-5x m-2" />}
                </div>
            )
        } else {
            return (
                <tr>
                    <td colSpan="15">
                        {this.props.loadingIconList ? <p className="color-header font-weight-bold fa-lg m-2">Sem dados até o momento.</p> : <i className="fas fa-circle-notch fa-pulse color-header fa-5x m-2" />}
                    </td>
                </tr>
            )
        }

    }
}



