import React, { Component } from "react";
import $ from "jquery";
import "./Answer.css";
import API from "../../utils/APInoToken";
import Alert from "../tools/Alert";
import { ifError } from "../tools/utils";
//import Pagination from "react-js-pagination"

const InitialState = {
  test: {
    name: "",
    idTest: "",
    token: "",
    email: "",
    status: "",
  },
  pagination: {
    activePage: 0,
    limit: 20,
    count: "",
    numberQuestionsFinished: 0,
    list: [],
    questionsPage: [],
  },
  alert: {
    class: "alert-warning",
    msg: null,
    onClick: null,
    visible: alert.msg ? "" : "d-none",
  },
  answersExample: {
    idTest: "",
    idQuestion: "",
    optionA: null,
    optionB: null,
    optionC: null,
    optionD: null,
  },
  buttonSend: false,
  answers: [],
};

export default class Answer extends Component {
  state = { ...InitialState };

  async componentDidMount() {
    await this.isToken();

    //get questões para paginar
    await this.getQuestions();
  }

  isToken() {
    if (sessionStorage.getItem("@disc-app/test") === null) {
      window.location.replace("/Token");
    } else {
      const test = JSON.parse(sessionStorage.getItem("@disc-app/test"));
      this.setState({ test });

      const answersExample = this.state.answersExample;
      answersExample.idTest = test.idTest;
      this.setState({ answersExample });
    }
  }

  async getQuestions() {
    //paginando as perguntas
    const pagination = { ...this.state.pagination };

    await API.get("/questions").then((resp) =>
      this.setState({
        pagination: {
          ...pagination,
          list: resp.data.data,
          count: resp.data.count,
        },
      })
    );
    this.pagination(this.state.pagination.activePage);
  }

  pagination(e) {
    const pagination = { ...this.state.pagination };
    const activePage = pagination.activePage;

    //verifico se pode virar a pagina, somente se todas as questões da pagina forem respondidas
    if (!this.checkAnswersPage(activePage)) return;

    //não existe pagina 3 chama função de envio e encerra
    if (activePage === 2) {
      this.sendAnswer();
      return;
    }

    const minListTest = (activePage + 1) * pagination.limit - pagination.limit;
    const questions = pagination.list.slice(
      minListTest,
      minListTest + pagination.limit
    );

    pagination.activePage = activePage + 1;
    pagination.questionsPage = questions;

    this.setState({ pagination });

    //window.scrollTo(500, 0);
    $("html, body").animate({ scrollTop: 0 }, "swing");

    //console.log(this.state.answers)
  }

  checkAnswersPage(activePage) {
    var ret = false;
    const answers = this.state.answers;
    const pagination = this.state.pagination;

    //se for a pagina 0 nem precisa checar
    if (activePage === 0) return true;

    //se o array answers for vazio não precisa checar
    if (answers.length === 0) {
      this.showAlert(
        true,
        "O questionário não foi respondido",
        "alert-warning"
      );
    } else if (answers.length < activePage * pagination.limit) {
      //checar se todos as questões estão no array de resposta
      this.showAlert(
        true,
        " É necessário que todas as questões da pagina sejam respondidas.",
        "alert-danger"
      );
    } else {
      //check para saber se todos as alternativas foram 100% selecionadas
      const indexArray = answers.findIndex(
        (e) => !e.optionA || !e.optionB | !e.optionC | !e.optionD
      );

      if (indexArray !== -1) {
        const idAnswer = answers[indexArray].idQuestion;
        const idQuestion = pagination.questionsPage.findIndex(
          (e) => e.id === idAnswer
        );
        this.showAlert(
          true,
          `A questão numero ${pagination.questionsPage[idQuestion].ordem} está incompleta.`,
          "alert-danger"
        );
      } else {
        //nenhum problema encontrado
        this.showAlert(false);
        ret = true;
      }
    }
    return ret;
  }

  showAlert(show, msg, color) {
    const alert = this.state.alert;
    if (show === true) {
      alert.msg = msg;
      alert.class = color;
      this.setState({ alert });
    } else {
      alert.msg = null;
      this.setState({ alert });
    }
  }

  async sendAnswer() {
    const answers = this.state.answers;
    const test = this.state.test;
    this.setState({ buttonSend: true });

    await API.post(`/answers/${test.idTest}`, answers)
      .then((suc) => {
        this.setState({ buttonSend: false });
        this.showAlert(
          true,
          suc.data.msg || "Salvo com sucesso",
          "alert-success"
        );
        this.props.history.push({ pathname: "/Congratulation" });
      })
      .catch((err) => {
        this.setState({ buttonSend: false });
        const msg = ifError(err);
        console.log(err);
        this.showAlert(
          true,
          msg || "Erro, contatar adimnistrador.",
          "alert-warning"
        );
      });
  }

  renderHeader() {
    return (
      <div className="row">
        <nav
          id="headerAnswer"
          className="navbar navbar-expand-md fixed-top d-flex justify-content-center"
        >
          <div className="col-2 d-flex justify-content-end p-1 m-0">
            <h5 className="text-progress pt-2 pr-2">
              {this.state.pagination.numberQuestionsFinished}
            </h5>
          </div>
          <div className="col-8 p-0 m-0">
            <div className="progress">
              <div
                className="progress-bar barProgress"
                role="progressbar"
                aria-valuenow="40"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                <b>
                  {" "}
                  {Math.round(
                    (this.state.pagination.numberQuestionsFinished /
                      this.state.pagination.count) *
                      100
                  )}{" "}
                  %
                </b>
              </div>
            </div>
          </div>
          <div className="col-2 d-flex justify-content-start p-1 m-0">
            <h5 className="text-progress pt-2 pl-2">
              {this.state.pagination.count}
            </h5>
          </div>
        </nav>
      </div>
    );
  }

  renderAnswer() {
    return (
      <section id="answer">
        <div className="container mt-5">
          <div className="jumbotron jumbotron-fluid m-0 p-4">
            <h2 className="section-title mb-1 h1">
              Vamos lá {this.state.test.name}!
            </h2>
            <p className="text-center text-muted h5">
              Para responder o Teste você deve selecionar todas as opções,
              porém, precisará ordenar as respostas, onde
              <br />
              <span className="text-danger">
                a 1º opção deverá ser a que você MAIS se identifica e a 4º opção
                a que MENOS se identifica.
              </span>
              <br />
              <br />
              Para ordenar as opções basta clicar, se você selecionar alguma
              opção sem querer, não se preocupe, clique no ícone da borracha que
              as opções serão reiniciadas e você poderá responder novamente a
              questão!
            </p>
          </div>

          <div className="row mt-2">{this.renderAnswerItem()}</div>

          <div className="col-12 mt-1 pb-3">
            <Alert
              msg={this.state.alert.msg}
              class={this.state.alert.class}
              onClick={(e) => this.showAlert(false)}
              visible={this.state.alert.msg ? "" : "d-none"}
            />
            <button
              type="button"
              disabled={this.state.buttonSend}
              className={`btn btn-lg btn-block btn-login btn-outline-success`}
              onClick={(e) => this.pagination(e)}
            >
              <b>
                {this.state.pagination.activePage === 2
                  ? "FINALIZAR "
                  : "AVANÇAR "}
              </b>
              <i className="fa fa-chevron-right" />
              <i className="fa fa-chevron-right" />
            </button>
          </div>
        </div>
      </section>
    );
  }

  renderButtonsOptions(question) {
    const buttonA = (
      <button
        id={`${question.id}optionA`}
        type="button"
        onClick={(e) => this.setValueAnswers(e)}
        idquestion={`${question.id}`}
        option="optionA"
        className="btn btn-outline-primary m-1"
      >
        {question.optionA}
      </button>
    );

    const buttonB = (
      <button
        id={`${question.id}optionB`}
        type="button"
        onClick={(e) => this.setValueAnswers(e)}
        idquestion={`${question.id}`}
        option="optionB"
        className="btn btn-outline-primary m-1"
      >
        {question.optionB}
      </button>
    );

    const buttonC = (
      <button
        id={`${question.id}optionC`}
        type="button"
        onClick={(e) => this.setValueAnswers(e)}
        idquestion={`${question.id}`}
        option="optionC"
        className="btn btn-outline-primary m-1"
      >
        {question.optionC}
      </button>
    );

    const buttonD = (
      <button
        id={`${question.id}optionD`}
        type="button"
        onClick={(e) => this.setValueAnswers(e)}
        idquestion={`${question.id}`}
        option="optionD"
        className="btn btn-outline-primary m-1"
      >
        {question.optionD}
      </button>
    );

    switch (parseInt(("0" + question.id).slice(-1))) {
      case 1:
      case 6:
      case 9:
        return (
          <>
            {buttonD}
            {buttonC}
            {buttonB}
            {buttonA}
          </>
        );
      case 8:
      case 2:
      case 5:
        return (
          <>
            {buttonA}
            {buttonB}
            {buttonC}
            {buttonD}
          </>
        );
      case 7:
      case 4:
      case 3:
        return (
          <>
            {buttonC}
            {buttonA}
            {buttonD}
            {buttonB}
          </>
        );
      default:
        return (
          <>
            {buttonD}
            {buttonA}
            {buttonC}
            {buttonB}
          </>
        );
    }
  }

  renderAnswerItem() {
    return this.state.pagination.questionsPage.map((question) => {
      return (
        <div
          className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
          key={question.id}
        >
          <div className="card ml-3 mr-3">
            <div className="card-block block-1">
              <div className="row">
                <div className="col-10">
                  <h4 className="card-title">
                    <b>{`${question.ordem} - ${question.name}`}</b>
                  </h4>
                </div>
                <div className="col-2 text-right">
                  <button
                    className="btn btn-warning fa fa-eraser"
                    id={`${question.id}`}
                    onClick={(e) => this.clearQuestion(e)}
                  />
                </div>
                <div className="col-12">
                  <div
                    id={`${question.id}box-sort`}
                    className="box-sort m-1"
                  ></div>
                </div>
                <div className="col-12">
                  {this.renderButtonsOptions(question)}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  setValueAnswers(e) {
    var answer = { ...this.state.answersExample };
    var { idquestion, option } = e.currentTarget.attributes;

    answer.idQuestion = idquestion = parseInt(idquestion.value);
    option = option.value;
    answer[option] = 4;

    //verifico se o array é vazio se sim insiro o 1º elemento
    if (this.state.answers.length === 0) {
      this.state.answers.push({ ...answer });
    } else {
      //verifico se a resposta já existe, se sim atualizo
      const indexArray = this.state.answers.findIndex(
        (e) => e.idQuestion === idquestion
      );
      if (indexArray !== -1) {
        answer = this.state.answers[indexArray];
        answer[option] = this.getMinValue(indexArray);
      } else {
        this.state.answers.push({ ...answer });
      }
    }

    const cardNumber =
      answer[option] === 4
        ? "1º) "
        : answer[option] === 3
        ? "2º) "
        : answer[option] === 2
        ? "3º) "
        : answer[option] === 1
        ? "4º) "
        : "º)";
    //adicionando o elemento como posição
    $(`#${idquestion}box-sort`).append(
      `<p><b>${cardNumber}</b>${$(`#${idquestion}${option}`).text()}</p>`
    );
    //escondendo o butão para não ser selecionado novamente
    $(`#${idquestion}${option}`).hide();

    if (cardNumber === "4º) ") this.setQuestionFinished(true);

    //console.log(this.state.answers)
  }

  setQuestionFinished(action) {
    const pagination = { ...this.state.pagination };

    if (action) {
      pagination.numberQuestionsFinished++;
    } else {
      if (pagination.numberQuestionsFinished > 0)
        pagination.numberQuestionsFinished--;
    }

    $(".barProgress").css(
      "width",
      `${Math.round(
        (pagination.numberQuestionsFinished / pagination.count) * 100
      )}%`
    );
    this.setState({ pagination });
  }

  getMinValue(id) {
    const answer = this.state.answers[id];
    const arrayValue = [
      answer.optionA || 10,
      answer.optionB || 10,
      answer.optionC || 10,
      answer.optionD || 10,
    ];

    return Math.min(...arrayValue) - 1 <= 4 ? Math.min(...arrayValue) - 1 : 4;
  }

  clearQuestion(e) {
    //apagando o conteudo do array caso exista
    const idquestion = parseInt(e.target.id);
    const indexArray = this.state.answers.findIndex(
      (e) => e.idQuestion === idquestion
    );
    if (indexArray !== -1) {
      const answer = this.state.answers[indexArray];
      answer.optionA = null;
      answer.optionB = null;
      answer.optionC = null;
      answer.optionD = null;
    }

    this.setQuestionFinished(false);

    //apagando os elementos enumerados
    $(`#${e.target.id}box-sort`).empty();

    //retornar os botoes
    $(`#${e.target.id}optionA`).show();
    $(`#${e.target.id}optionB`).show();
    $(`#${e.target.id}optionC`).show();
    $(`#${e.target.id}optionD`).show();
  }

  render() {
    return (
      <React.Fragment>
        {this.renderHeader()}
        {this.renderAnswer()}
      </React.Fragment>
    );
  }
}
