import React from 'react'
import './Carousel.css'

export default props =>

    <div id="carouselExampleIndicators" className="carousel slide d-none d-sm-block" data-ride="carousel">
        <ol className="carousel-indicators">
            <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        </ol>
        <div className="carousel-inner" role="listbox">
            <div className="carousel-item active">
                <img className="img-fluid" src={require('../../assets/imgs/banner/banner4.png')} alt="Second slide" />
            </div>
            <div className="carousel-item">
                <img className="img-fluid" src={require('../../assets/imgs/banner/banner1.png')} alt="First slide" />
            </div>
        </div>
    </div>