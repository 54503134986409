import React, { Component } from 'react'
import './CreditsList.css'
import Pagination from "react-js-pagination"
import { formatDate } from '../tools/utils'
import { Link } from 'react-router-dom'
import Content from '../template/jsx/Content'
import API from '../../utils/API'
import Alert from '../tools/Alert'
import LoadingIconList from '../tools/LoadingIconList'


const InitialState = {
    pagination: {
        activePage: 1,
        limit: 10,
        count: '',
        filter: [],
        list: [],
        creditsPage: [],
        search: '',
    },
    loadingIconList: false,
    alert: {
        class: 'alert-warning',
        msg: null,
        onClick: null,
        visible: alert.msg ? '' : 'd-none',
    },
}

export default class Credits extends Component {

    state = { ...InitialState }

    async componentDidMount() {

        if (this.props.location.msg) this.showAlert(true, this.props.location.msg || 'Salvo com Sucesso', this.props.location.alert || 'alert-warning')
        const pagination = { ...this.state.pagination }

        await API.get('/credits')
            .then(resp => {

                if (resp.data.data.length) {
                    this.setState({
                        pagination: {
                            ...pagination, list: resp.data.data, filter: resp.data.data, count: resp.data.count,
                        }
                    })
                } else {
                    this.setState({ loadingIconList: true })
                }
            })
            .catch(err => {
                console.log(err)
                this.showAlert(true, 'Ocorreu algo inesperado ao tentar listar os créditos, contacte o administrador.', 'alert-warning')
                this.setState({ loadingIconList: true })
            })

        this.pagination(this.state.pagination.activePage)

    }

    pagination(activePage) {

        const pagination = { ...this.state.pagination }
        const minListCredits = activePage * pagination.limit - pagination.limit
        const credits = pagination.filter.slice(minListCredits, minListCredits + pagination.limit)

        pagination.activePage = activePage
        pagination.creditsPage = credits

        this.setState({ pagination })
    }

    showAlert(show, msg, color) {
        const alert = this.state.alert
        if (show === true) {
            alert.msg = msg
            alert.class = color ? color : 'alert-warning'
            this.setState({ alert })
        } else {
            alert.msg = null
            this.setState({ alert })
        }
    }

    async updateFilter(event) {
        const pagination = { ...this.state.pagination }
        pagination.search = event.target.value
        if (event.target.value !== '') {
            pagination.filter = pagination.list.filter(total => !total.name.toUpperCase().indexOf(event.target.value.toUpperCase()))
        } else {
            pagination.filter = pagination.list
        }
        await this.setState({ pagination })
        this.pagination(this.state.pagination.activePage)
    }

    renderHeaderTable() {
        return (
            <React.Fragment>
                <div className="container-fluid mb-2">
                    <div className="row mb-2 mt-2">
                        <div className="col-10 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                            <div className="input-group">
                                <input type="text" className="form-control" id="search"
                                    placeholder="Pesquisar por nome"
                                    value={this.state.pagination.search || ''}
                                    onChange={(e) => this.updateFilter(e)} />
                                <div className="input-group-append">
                                    <button className="btn btn-login" type="button"><i className="fas fa-search" /></button>
                                </div>
                            </div>
                        </div>
                        <div className="col-2 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div className="d-flex justify-content-end">
                                <Link to='/creditsCrud'>
                                    <button className="btn btn-login "><i className="fas fa-plus fa-1x" /></button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderTable() {
        return (

            <div className="table-responsive-sm">
                <table className="table table-hover table-sm table-striped">
                    <caption>{`${this.state.pagination.creditsPage.length} de ${this.state.pagination.filter.length}`}</caption>
                    <thead>
                        <tr>
                            <th scope="col">Usuário</th>
                            <th scope="col">R$</th>
                            <th scope="col">Adicionado</th>
                            <th scope="col">Saldo</th>
                            <th scope="col">Utilizado</th>
                            <th scope="col">Disponível</th>
                            <th scope="col">Criado em</th>
                            <th scope="col">Editar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderRows()}
                    </tbody>
                </table>
                <div>
                    <Pagination
                        activePage={this.state.pagination.activePage}
                        itemsCountPerPage={this.state.pagination.limit}
                        totalItemsCount={this.state.pagination.filter.length}
                        innerClass={"pagination m-0 p-0 d-flex justify-content-end"}
                        prevPageText={<i className="fa fa-chevron-left" />}
                        firstPageText={<i className="fa fa-angle-double-left" />}
                        nextPageText={<i className="fa fa-chevron-right" />}
                        lastPageText={<i className="fa fa-angle-double-right" />}
                        itemClass={"page-item"}
                        linkClass={"page-link"}
                        disabledClass={""}
                        pageRangeDisplayed={4}
                        onChange={e => this.pagination(e)}
                    />
                </div>
            </div>
        )
    }

    renderRows() {

        if (this.state.pagination.list.length) {

            return this.state.pagination.creditsPage.map(credits => {

                return (
                    <tr className={credits.status === true ? '' : "text-danger"} key={credits.id}>
                        <td>{credits.name}</td>
                        <td>R$ {credits.cashValue}</td>
                        <td>{credits.quantity}</td>
                        <td>{credits.balanceCurrent}</td>
                        <td>{credits.balanceUsed}</td>
                        <td>{credits.balanceCurrent - credits.balanceUsed}</td>
                        <td>{formatDate(credits.createdAt)}</td>
                        <td>
                            <Link to={`/creditsEdit/${credits.id}`}>
                                <div className="btn-login d-flex justify-content-center">
                                    <i className="mt-1 mb-1 btn-login fas fa-edit mr-2" />
                                </div>
                            </Link>
                        </td>
                    </tr>
                )
            })
        } else {
            return (
                <LoadingIconList loadingIconList={this.state.loadingIconList} />
            )
        }
    }


    render() {
        return (
            <Content>
                <Alert
                    msg={this.state.alert.msg}
                    class={this.state.alert.class}
                    onClick={e => this.showAlert(false)}
                    visible={this.state.alert.msg ? '' : 'd-none'}
                />
                {this.renderHeaderTable()}
                {this.renderTable()}
            </Content>
        )
    }
}