import React, { Component } from 'react'
import API from '../../utils/API'
import { ifError } from '../tools/utils'
import Loading from '../tools/Loading'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const initialState = {

    positions: {
        id: '',
        name: '',
        status: true
    },
    loading: {
        class: 'd-none',
        msg: 'loading...'
    },
    modal: {
        id: 'modalViewPositions',
        title: 'Visualizar cargo',
        footer: false,
        funccb1: null,
    },
    checkLockModal: true,
}

export default class PositionsCrud extends Component {

    state = { ...initialState }

    notify = (type, msg) => toast[type](msg);

    updateCheckLockModal = () => this.setState({ checkLockModal: !this.state.checkLockModal })

    componentDidUpdate() {

        if (this.props.positions.id !== this.state.positions.id) {

            if (this.props.positions.id) {
                this.setState({ positions: { ...this.props.positions } })
            } else {
                if (this.state.positions.id) this.setState({ positions: initialState.positions })
            }
        }
    }

    loading(show, msg) {
        const loading = this.state.loading
        if (show === true) {
            loading.msg = msg
            loading.class = ''
            this.setState({ loading })
        } else {
            loading.class = 'd-none'
            this.setState({ loading })
        }
    }

    validForm(e) {
        const { positions } = this.state
        let ret = false

        if (!positions.name) {
            this.notify('warning', 'Informar ao menos o nome do cargo.')
        } else {
            ret = true
        }
        return ret
    }

    save(e) {
        e.preventDefault()

        const positions = this.state.positions

        if (this.validForm()) {

            if (!positions.status) positions.status = false

            const method = positions.id ? 'put' : 'post'
            const url = positions.id ? `/positions/${positions.id}` : '/positions'

            API[method](url, positions)
                .then(resp => {
                    if (positions.id) { //edit -> insert = false
                        this.props.insertPositionCb({ ...positions, ...resp.data.ret }, false)
                    } else {
                        this.props.insertPositionCb({ ...positions, ...resp.data.ret }, true)
                    }
                    this.setState({ positions: { ...positions, id: resp.data.ret.id } })
                    this.notify('success', `${positions.name} salvo.`)
                    if (this.state.checkLockModal) document.getElementById('buttonCloseModal').click()
                })
                .catch(err => {
                    console.log('err =', err)
                    const backWs = ifError(err)
                    this.notify('error', backWs)
                })
        }
    }

    renderForms() {
        return (
            <div id="SetPositions">
                <div className="row mt-3">
                    <div className="col-xs-12 col-sm-12 col-md-12">
                        <div className="col-xs-12 col-sm-12 col-md-12">
                            <div className="form">
                                <div className="row">

                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <div className="form-group">
                                            <small className=" d-flex form-text text-CubohColor2 m-0">1 - Qual nome do cargo? <span className="badge text-danger">*</span></small>
                                            <div className="input-group">
                                                <input type="text" id="name" autoComplete="off" value={this.state.positions.name || ''} onChange={e => this.updateField(e)} placeholder="Assistente de mkt, gerente de vendas, analista de compras...." className="form-control" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                                        <div className="custom-control custom-switch">
                                            <input type="checkbox" className="custom-control-input fa-lg" value={!!!this.state.positions.status || ''} id="status" onChange={e => this.updateField(e)} checked={this.state.positions.status ? true : false} />
                                            <label className="custom-control-label " htmlFor="status">Cargo ativo</label>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <hr />
                        <div className="row">
                            <div className="col-12 d-flex justify-content-between">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value={this.state.checkLockModal || ''} id="flexCheckChecked" onChange={e => this.updateCheckLockModal()} defaultChecked />
                                    <label className="form-check-label" htmlFor="flexCheckChecked">
                                        <small>Fechar ao salvar</small>
                                    </label>
                                </div>
                                <button className="btn btn-sm btn-success ml-2" type="submit"
                                    onClick={e => this.save(e)}>
                                    Salvar
                                </button>

                            </div>
                        </div>
                    </div>
                </div >
            </div >
        )
    }

    async updateField(event) {
        const positions = { ...this.state.positions }
        positions[event.target.id] = event.target.value
        await this.setState({ positions })
    }

    async updateFilter(event) {
        const positionsList = this.state.positionsList
        positionsList.search = event.target.value
        if (event.target.value !== '') {
            positionsList.filter = positionsList.list.filter(total => !total.name.toUpperCase().indexOf(event.target.value.toUpperCase()))
        } else {
            positionsList.filter = ''
        }
        await this.setState({ positionsList })
    }

    render() {
        return (
            /* <ModalForms modal={this.state.modal} test={this.state.positions}>
                <Loading {...this.state.loading} />
                {this.renderForms()}
            </ModalForms> */

            <React.Fragment>
                <Loading {...this.state.loading} />
                {this.renderForms()}
            </React.Fragment>

            /* <React.Fragment>
                <div className="modal show" id="modalCreatePositions" tabIndex="-1" role="dialog" aria-labelledby="ModalFormCreateGroups" >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-Cuboh-color-purple1 py-2">
                                <h5 className="modal-title text-white" id="ModalFormCreatePositions">{`${this.state.positions.id ? 'Editar cargo' : 'Criar cargo'}`}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <i className="fa-md text-white fas fa-times" aria-hidden="true" />
                                </button>
                            </div>
                            <div className="modal-body">
                                <Loading {...this.state.loading} />
                                {this.renderForms()}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment> */
        )
    }
}